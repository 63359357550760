import React, { useState, useEffect } from 'react';
import {
  IonSegment,
  IonContent,
  IonSegmentButton,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import './index.css';
import { isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import redeemIcon from '../../assets/images/reedem-point-icon.svg';
import { StrongText, Subtitle } from '../common';
import Slider, { Slide } from '../../components/slider';

const SwipableTabs = ({
  defaultTab,
  defaultSlide,
  history,
  onTabChange,
  available_balance,
  __,
  displayFooter,
  displayFooterTab,
  tabs
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab || 0);
  const [selectedSlide, setSelectedSlide] = useState(defaultSlide || 0);
  const [swiper, setSwiper] = useState();
  const [onLoadHistory, setOnLoadHistory] = useState(history);

  useEffect(() => {
    if (
      history && history.state && history.state.tab === 'order' ||
      history && history.state && history.state.tab === 'history'
    ) {
      segmentChanged(1);
    }
  }, [swiper]);

  useEffect(() => {
    if (history && (onLoadHistory?.key !== history?.key)) {
      // fix: redirect to the scan tab if user is located in 'loyalty' page but on the first tab
      // and that he clicks on scan icon in header
      if (history?.state?.tab === 'history') {
        segmentChanged(1);
        setOnLoadHistory(history);
      } else if (history?.state?.tab === 'rewards') {
        segmentChanged(0);
        setOnLoadHistory(history);
      }
    }
  });

  const segmentChanged = (value) => {
    setSelectedTab(value);
    setSelectedSlide(value);
    if (swiper) {
      swiper.slideTo(value);
    }
  };
  return (
    <div className="tabs">
      <div className="segment-holder">
        <IonSegment
          onIonChange={(e) => {
            if (onTabChange) {
              onTabChange(e.detail.value);
            }
            segmentChanged(e.detail.value);
          }}
          value={selectedTab}
          mode="md"
        >
          {tabs.map((tab, index) => {
            const { label, icon } = tab;
            return (
              <IonSegmentButton key={index + '_segment'} value={index}>
                {isDefined(icon) ? <IonIcon name={icon}></IonIcon> : null}
                <IonLabel>{label || ''}</IonLabel>
              </IonSegmentButton>
            );
          })}
        </IonSegment>
      </div>
      <Slider
        settings={{
          loop: false,
          initialSlide: selectedSlide,
          onSlideChange: (swiper) => segmentChanged(swiper.activeIndex),
          onSwiper: (swiper) => setSwiper(swiper)

        }
        }>
        {tabs.map((tab, index) => {
          return (
            <Slide key={index + 'slide'} className={'slide-' + (index + 1)}>
              <IonContent>{tab.tabContent || null}</IonContent>
            </Slide>
          );
        })}
      </Slider>
      {displayFooter &&
        displayFooter === 'balance' &&
        isDefined(displayFooterTab) &&
        displayFooterTab + '' === selectedTab + '' ? (
        <div className="loyalty-bar">
          <div>
            <div className="flex-col-wrapper">
              <img src={redeemIcon} alt=" " />
            </div>
          </div>
          <div>
            <Subtitle className="roboto-slab">{__('Loyalty Balance')}</Subtitle>
          </div>
          <div>
            <StrongText color="primary" className=" loyalty-balance thiner-text roboto-slab">
              {available_balance} {__('Points')}
            </StrongText>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withTranslation(SwipableTabs);
