import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonCheckbox,
  IonTextarea,
  IonItem,
  IonList,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonIcon,
  IonAlert,
} from '@ionic/react';
import { arrowBack, chevronDown, chevronUp } from 'ionicons/icons';
import Layout from '../../components/layout';
import Incrementer from '../../components/incrementer';
import Pullup from '../../components/pullup';
import { PillGroup } from '../../components/pill';
import {
  SmallText,
  Sectiontitle,
  Spacer,
  NormalText,
  StrongText,
  Title,
  Subtitle,
} from '../../components/common';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import {
  makeKey,
  isDefined,
  deepCopy,
  goBack,
  isWebConfig,
  isEmptyObject,
  forwardTo,
  parseAllergenData,
  getLocale,
} from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { setScrollTop, storeItemWeb } from '../../store/actions';
import Modal from '../../components/modal';

import './index.css';

const {
  formatPrice,
  _calculateItemPrice,
  validateItem,
  addToBasket,
  getProductName,
  getProductDescription,
  getModifierGroupName,
  getModifierGroupDescription,
  isProductEnabled,
  isProductJustEnabled,
  isChoicesGroupValid,
  setAllergen,
} = Basket;

const noImage = () => <div className="item-details-background" id="item-details-image"></div>;

class itemDetailsContent extends Component {
  constructor(props) {
    super(props);
    this.specialInstructionsEl = React.createRef();

    this.state = {
      quantity: 1,
      price: 0,
      selectedChoices: [],
      validationErrors: [],
      instructions: '',
      showValidationAlert: false,
      allergensCodes: [],
      instructions: '',
      modalInstructions: '',
      specialInstructionsModalOpen: false,
      selectedSubChoices: [],
      nutritionalInfoVisible: false,
      ingredientsVisible: false,
      productHasUpsell: false,
      upsellModalOpen: false
    };
  }
  drawNutritionalInfo = (__, nutritionalInfo) => {
    return (
      <>
        <Spacer size={1} />
        <div className="nutrition-accordion-wrapper">
          {this.drawAccortianButton(
            () =>
              this.setState({
                nutritionalInfoVisible: !this.state.nutritionalInfoVisible,
                ingredientsVisible: false,
              }),
            __('Nutritional Info'),
            this.state.nutritionalInfoVisible,
          )}
          {this.state.nutritionalInfoVisible && (
            <div>
              {this.drawNutritionalTable(__, nutritionalInfo.items)}
              {nutritionalInfo.ingredients != '' && (
                <>
                  {this.drawAccortianButton(
                    () => this.setState({ ingredientsVisible: !this.state.ingredientsVisible }),
                    __('Ingredients'),
                    this.state.ingredientsVisible,
                  )}
                </>
              )}

              {this.state.ingredientsVisible && this.drawIngredients(nutritionalInfo.ingredients)}
            </div>
          )}
        </div>
      </>
    );
  };
  drawNutritionalTable = (__, items) => {
    const { nutritionData } = this.props;
    let nutritions = items;
    if (this.state.selectedChoices.length > 0 && !isEmptyObject(this.state.selectedChoices[0])) {
      let nutritionDataItems = this.state.selectedChoices[0].map((i) => {
        return nutritionData.find((value) => value.sku === i.sku)?.items;
      });
      nutritionDataItems = !nutritionDataItems[0] ? [] : nutritionDataItems;
      const totalamount = (totalGroup, indexOfElement, sort) => {
        let sum = '';
        totalGroup.map((item) => {
          let stringToNumOfPastValue = sum.match(/[a-z]+|[^a-z]+/gi)
            ? +sum.match(/[a-z]+|[^a-z]+/gi)?.[0]
            : '';
          let additionalNut =
            item[indexOfElement][sort] === 'n/a'
              ? ''
              : +item[indexOfElement][sort].match(/[a-z]+|[^a-z]+/gi)?.[0] || 0;
          sum =
            (parseFloat(stringToNumOfPastValue + additionalNut).toFixed(2) * 100) / 100 +
            item[indexOfElement][sort].match(/[a-z]+|[^a-z]+/gi)?.[1] || '';
        });
        return sum;
      };

      nutritions = items.map((item, index) => {
        let newItem = {
          item: '',
          amountPer100: '',
          amountPerServing: '',
        };
        newItem.item = item.item;
        newItem.amountPer100 = totalamount([items, ...nutritionDataItems], index, 'amountPer100');
        newItem.amountPerServing = totalamount(
          [items, ...nutritionDataItems],
          index,
          'amountPerServing',
        );
        return newItem;
      });
    }
    return (
      <table className="nutrition-table">
        <thead>
          <tr>
            <th>{__('Table of Nutritional Information')}</th>
            <th>{__('Per 100g')}</th>
            <th>{__('Per serving')}</th>
          </tr>
        </thead>
        <tbody>
          {nutritions.map((el, i) => {
            return (
              <tr key={i}>
                <td>{el.item}</td>
                <td>{el.amountPer100}</td>
                <td>{el.amountPerServing}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  drawAccortianButton = (onClick, label, visible) => {
    return (
      <>
        <div className="accordian-button" onClick={onClick}>
          <Subtitle className="bold">{label}</Subtitle>
          <IonIcon icon={visible ? chevronUp : chevronDown} />
        </div>
      </>
    );
  };
  drawIngredients = (text) => {
    const { nutritionData } = this.props;
    if (this.state.selectedChoices.length > 0) {
      const ingredientsList = this.state.selectedChoices[0].map((el) => {
        return nutritionData.find((value) => value.sku === el.sku)?.ingredients || '';
      });
      ingredientsList.map((value) => {
        text += value;
      });
    }
    return <div className="ingredients-text" dangerouslySetInnerHTML={{ __html: text }}></div>;
  };
  componentDidMount() {
    const { profile, allergens, item, nutritionData } = this.props;
    // this.setState({ itemNutritionData: nutritionData.filter((data) => data.sku == item.sku)[0] });
    let quantity = this.state.quantity;
    let selectedChoices = [];
    let validationErrors = [];
    let selectedSubChoices = [];
    if (item.menuDealGroups && item.menuDealGroups.length > 0) {
      selectedChoices = Array(item.menuDealGroups.length).fill([]);
      selectedSubChoices = Array(item.menuDealGroups.length).fill([]);
      validationErrors = Array(item.menuDealGroups.length).fill(null);
    }
    for (let i = 0; i < item.menuDealGroups?.length; i++) {
      if (item.menuDealGroups[i].bundleModifiers) {
        selectedSubChoices[i] = Array(item.menuDealGroups[i].bundleModifiers.length).fill([]);
      }
    }
    let price = _calculateItemPrice({ item, quantity });

    let locale = getLocale(profile);
    if (locale === 'fr') {
      locale = 'fr_FR';
    }
    let allergensCodes = item
      ? item.itemRichData
        ? (item.itemRichData.allergenCodes && item.itemRichData.allergenCodes.length) > 0
          ? item.itemRichData.allergenCodes
          : []
        : []
      : [];
    let newArr = [];
    allergensCodes.map((allergenCode) => {
      let dataArr = (allergens && allergens.data ? allergens.data : []).find((allergen) => {
        let dataArr2 = null;
        if (allergen.code === allergenCode) {
          dataArr2 = allergen.translations.find((data) => {
            if (data.locale === locale) {
              newArr.push(data.text);
              return data;
            }
            return null;
          });
        }
        return dataArr2;
      });
      if (!dataArr) {
        newArr.push(allergenCode);
      }
      return null;
    });

    this.setState(
      { price, selectedChoices, selectedSubChoices, validationErrors, allergensCodes: newArr },
      () => {
        const initialValidationErrors = validateItem(this.constructBasketItem());
        this.setState({ validationErrors: initialValidationErrors.errors });
      },
    );
  }
  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantity: newQuantity,
      price: _calculateItemPrice({
        item: this.props.item,
        quantity: newQuantity,
        selectedChoices: this.state.selectedChoices,
      }),
    });
  };

  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.props.item;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      this.updateSelectedChoices(
        groupIndex,
        choiceIndex,
        multiSelectionPermitted,
        multiSelectionPermitted ? event.target.checked : !!selectedChoice,
        selectedChoice ? 1 : 0,
      );
    }
  };
  handleSubInputChange = (groupIndex, choiceIndex, bundleIndex, multiSelectionPermitted, event) => {
    event.stopPropagation();
    event.preventDefault();
    const item = this.props.item;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex].bundleModifiers[choiceIndex];

    if (selectedGroup) {
      const selectedChoice = selectedGroup[0].items[bundleIndex];
      this.updateSelectedSubChoices(
        groupIndex,
        choiceIndex,
        bundleIndex,
        multiSelectionPermitted,
        multiSelectionPermitted ? event.target.checked : !!selectedChoice,
        selectedChoice ? 1 : 0,
      );
    }
  };
  updateSelectedSubChoices = (
    groupIndex,
    choiceIndex,
    bundleIndex,
    multiSelectionPermitted,
    selected = true,
    quantity = 0,
  ) => {
    const { item } = this.props;
    const { selectedSubChoices } = this.state;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex].bundleModifiers[choiceIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup[0].items[bundleIndex]
        ? deepCopy(selectedGroup[0].items[bundleIndex])
        : {};
      selectedChoice.bundleModifier = true;
      let updatedSelectedSubChoices = deepCopy(selectedSubChoices);
      let selectedSubChoiceIndex = -1;
      const existingChoice = updatedSelectedSubChoices[groupIndex][choiceIndex].find((i, index) => {
        if (selectedChoice && i.sku === selectedChoice.sku) {
          selectedSubChoiceIndex = index;
          return true;
        }
        return false;
      });
      if (multiSelectionPermitted) {
        if (selected) {
          selectedChoice.quantity = 1;
          if (existingChoice) {
            existingChoice.quantity = quantity;
            if (
              selectedSubChoiceIndex !== -1 &&
              updatedSelectedSubChoices[groupIndex][choiceIndex] &&
              updatedSelectedSubChoices[groupIndex][choiceIndex][selectedSubChoiceIndex]
            ) {
              updatedSelectedSubChoices[groupIndex][choiceIndex][
                selectedSubChoiceIndex
              ] = existingChoice;
            }
          } else {
            updatedSelectedSubChoices[groupIndex][choiceIndex].push(selectedChoice);
          }
        } else {
          selectedChoice.quantity = 0;
          updatedSelectedSubChoices[groupIndex][choiceIndex] = updatedSelectedSubChoices[
            groupIndex
          ][choiceIndex].filter((i) => i.sku !== selectedChoice.sku);
        }
      } else {
        if (selected) {
          if (existingChoice) {
            updatedSelectedSubChoices[groupIndex][choiceIndex] = updatedSelectedSubChoices[
              groupIndex
            ][choiceIndex].filter((i) => i.sku !== selectedChoice.sku);
          } else {
            selectedChoice.quantity = 1;
            updatedSelectedSubChoices[groupIndex][choiceIndex].push(selectedChoice);
          }
        }
      }
      const subChoices = updatedSelectedSubChoices[groupIndex].map((el, i) => {
        if (i === choiceIndex) {
          return el;
        } else {
          return [];
        }
      });
      updatedSelectedSubChoices[groupIndex] = subChoices;
      this.setState({ selectedSubChoices: updatedSelectedSubChoices }, () => {
        const validationErrors = validateItem(this.constructBasketItem());
        this.setState({ validationErrors: validationErrors.errors });
      });
    }
  };
  updateSelectedChoices = (
    groupIndex,
    choiceIndex,
    multiSelectionPermitted,
    selected = true,
    quantity = 0,
  ) => {
    // const item = this.props.location.state
    const { item } = this.props;
    const { selectedChoices } = this.state;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const isUpsell = selectedGroup.isUpsell;

      const selectedChoice = selectedGroup.items[choiceIndex]
        ? deepCopy(selectedGroup.items[choiceIndex])
        : {};
      let updatedSelectedChoices = deepCopy(selectedChoices);
      let selectedChoiceIndex = -1;
      const existingChoice = updatedSelectedChoices[groupIndex].find((i, index) => {
        if (selectedChoice && i.sku === selectedChoice.sku) {
          selectedChoiceIndex = index;
          return true;
        }
        return false;
      });
      if (isUpsell) {
        selectedChoice.isUpsell = true;
      }
      if (multiSelectionPermitted) {
        //checkbox
        if (selected) {
          selectedChoice.quantity = 1;
          if (existingChoice) {
            existingChoice.quantity = quantity;
            if (
              selectedChoiceIndex !== -1 &&
              updatedSelectedChoices[groupIndex] &&
              updatedSelectedChoices[groupIndex][selectedChoiceIndex]
            ) {
              updatedSelectedChoices[groupIndex][selectedChoiceIndex] = existingChoice;
            }
          } else {
            updatedSelectedChoices[groupIndex].push(selectedChoice);
          }
        } else {
          selectedChoice.quantity = 0;
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        if (selected) {
          selectedChoice.quantity = quantity;
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        } else {
          selectedChoice.quantity = 0;
          updatedSelectedChoices[groupIndex] = [];
        }
      }
      let updatedSubChoices = JSON.parse(JSON.stringify(this.state.selectedSubChoices));
      let x = [];
      if (updatedSelectedChoices[groupIndex]) {
        x = this.state.selectedSubChoices[groupIndex].map((el) => []);
        updatedSubChoices[groupIndex] = x;
      }
      this.setState(
        { selectedChoices: updatedSelectedChoices, selectedSubChoices: updatedSubChoices },
        () => {
          const { selectedChoices } = this.state;
          const newCalculatedPrice = _calculateItemPrice({
            item,
            quantity: this.state.quantity,
            selectedChoices,
          });
          this.setState(
            {
              price: _calculateItemPrice({ item, quantity: this.state.quantity, selectedChoices }),
            },
            () => {
              if (this.props.priceUpdated) {
                this.props.priceUpdated(newCalculatedPrice);
              }
              const validationErrors = validateItem(this.constructBasketItem());
              this.setState({ validationErrors: validationErrors.errors });
            },
          );
        },
      );
    }
  };
  onIncrementerChoiceUpdate = (newQuantity, data) => {
    const { item } = this.props;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[data.groupIndex];
    if (selectedGroup) {
      this.updateSelectedChoices(
        data.groupIndex,
        data.choiceIndex,
        data.multiSelectionPermitted,
        newQuantity > 0,
        newQuantity,
      );
    }
  };
  onIncrementerSubChoiceUpdate = (newQuantity, data) => {
    const { item } = this.props;

    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[data.groupIndex].bundleModifiers[data.choiceIndex];
    if (selectedGroup) {
      this.updateSelectedSubChoices(
        data.groupIndex,
        data.choiceIndex,
        data.bundleIndex,
        data.multiSelectionPermitted,
        newQuantity > 0,
        newQuantity,
      );
    }
  };
  addSubModifier = () => { };
  drawSubModifiers = (subModifiers, groupIndex, choiceIndex) => {
    const { items, max, multiMax, multiSelectionPermitted } = subModifiers[0];
    const { selectedSubChoices } = this.state;
    const allSubChoices = items.map((item, index) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedSubChoices[groupIndex][choiceIndex] || []).find((i) => {
        return i.sku === sku;
      });
      const groupQuantity =
        selectedSubChoices[groupIndex][choiceIndex] &&
          selectedSubChoices[groupIndex][choiceIndex].length > 0
          ? selectedSubChoices[groupIndex][choiceIndex].reduce((sum, i) => sum + i.quantity || 0, 0)
          : 0;
      let quantity = 0;
      if (selectedSubChoices[groupIndex][choiceIndex]) {
        const _choice = selectedSubChoices[groupIndex][choiceIndex].find((i) => i.sku === sku);
        quantity = _choice ? _choice.quantity : 0;
      }
      const shouldBeDisabled =
        isDefined(max) &&
        max > 0 &&
        isDefined(selectedSubChoices[groupIndex][choiceIndex]) &&
        groupQuantity >= max &&
        !isChecked;

      return (
        <div className="bundle-modifiers">
          <IonItem lines="none">
            <IonCheckbox
              disabled={shouldBeDisabled || !Basket.isProductUnsnoozed(item)}
              className="details"
              color="secondary"
              slot="start"
              checked={isChecked}
              onIonChange={(event) => {
                Basket.isProductUnsnoozed(item) &&
                  this.handleSubInputChange(
                    groupIndex,
                    choiceIndex,
                    index,
                    multiSelectionPermitted,
                    event,
                  );
              }}
            />

            <NormalText>{item.productName}</NormalText>
          </IonItem>
          {/* <div className="price-increment-content">
            {quantity >= 1 && max > 1 && multiMax > 1 ? (
              <div
                className={formatPrice(productPrice) ? 'incrementer-box' : 'incrementer-box-top'}
              >
                <Incrementer
                  allowNegative={false}
                  data={{ groupIndex, choiceIndex, multiSelectionPermitted, bundleIndex: index }}
                  disabled={shouldBeDisabled}
                  maxLimit={Math.min(multiMax, (quantity || 0) + max - groupQuantity)}
                  onUpdate={this.onIncrementerSubChoiceUpdate}
                  quantity={quantity || 0}
                />
              </div>
            ) : null}
          </div> */}
        </div>
      );
    });
    return allSubChoices;
  };
  drawGroupChoices = (menuGroupItem, multiSelectionPermitted, groupIndex) => {
    const { items, max, multiMax, bundleModifiers, isUpsell } = menuGroupItem;
    for (let i = 0; i < items?.length; i++) {
      if (bundleModifiers?.[i]) {
        items[i].subModifiers = bundleModifiers[i];
      }
    }
    const choices = items || [];
    const { selectedChoices } = this.state;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => {
        return i.sku === sku;
      });
      if (item.sku === undefined || !isProductJustEnabled(item)) {
        return <span key={item.sku + '_disabled'} />;
      }
      const groupQuantity =
        selectedChoices[groupIndex] && selectedChoices[groupIndex].length > 0
          ? selectedChoices[groupIndex].reduce((sum, i) => sum + i.quantity || 0, 0)
          : 0;
      let quantity = 0;
      if (selectedChoices[groupIndex]) {
        const _choice = selectedChoices[groupIndex].find((i) => i.sku === sku);
        quantity = _choice ? _choice.quantity : 0;
      }
      let groupQunatity = 0;
      (selectedChoices[groupIndex] || []).forEach((i) => {
        groupQunatity += isDefined(i.quantity) ? i.quantity : 1;
      });
      const shouldBeDisabled =
        isDefined(max) &&
        max > 0 &&
        isDefined(selectedChoices[groupIndex]) &&
        groupQunatity >= max &&
        !isChecked;
      const description = getProductDescription(item, profile);
      if (menuGroupItem.hasOwnProperty('multiMax')) {
        return (
          <div>
            <IonItem
              className={`sub-item ${choiceIndex == choices.length - 1 ? 'last-item' : ''} ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''
                } `}
              lines="none"
              key={makeKey(choiceIndex, sku, groupIndex)}
            >
              {multiSelectionPermitted ? (
                <IonCheckbox
                  disabled={shouldBeDisabled || !Basket.isProductUnsnoozed(item)}
                  className="details"
                  color="secondary"
                  slot="start"
                  checked={isChecked}
                  onIonChange={(event) => {
                    Basket.isProductUnsnoozed(item) &&
                      this.handleInputChange(
                        groupIndex,
                        choiceIndex,
                        multiSelectionPermitted,
                        event,
                      );
                  }}
                />
              ) : (
                <IonRadio
                  slot="start"
                  className={'details' + (isChecked ? ' primary-radio' : '')}
                  color={isWebConfig() ? 'secondary' : 'white'}
                  value={choiceIndex}
                  checked={isChecked}
                  disabled={shouldBeDisabled || !Basket.isProductUnsnoozed(item)}
                />
              )}
              <div className="ion-text-wrap up">
                <div>
                  <NormalText className="dark-color item-details-product-label block">
                    {__(getProductName(item, profile))}{' '}

                  </NormalText>
                  {getProductDescription(item, profile) ? (
                    <SmallText className="no-margin  item-description">
                      {getProductDescription(item, profile)}
                    </SmallText>
                  ) : null}

                  <SmallText>

                    <SmallText className='block'>{item.calories > 0 && <span className='primary-color lowercase'>{item.calories}kcal </span>}</SmallText>
                    {isDefined(item.itemRichData) &&
                      isDefined(item.itemRichData.allergenCodes) &&
                      item.itemRichData.allergenCodes.length > 0 ? (
                      <>
                        <SmallText className='block capitalize secondary-color'><span className='primary-color'>{__('Allergens')}: </span>{item.itemRichData.allergenCodes.join(' • ')}</SmallText>
                      </>
                    ) : null}
                  </SmallText>

                </div>

                {formatPrice(item.productPrice) ? (
                  <NormalText className='lh-1 secondary-font dark-color'>{formatPrice(item.productPrice)}</NormalText>

                ) : null}


              </div>
            </IonItem>
            {/* <div className="price-increment-content">
              {quantity >= 1 && max > 1 && multiMax > 1 ? (
                <div
                  className={formatPrice(productPrice) ? 'incrementer-box' : 'incrementer-box-top'}
                >
                  <Incrementer
                    allowNegative={false}
                    data={{ groupIndex, choiceIndex, multiSelectionPermitted }}
                    disabled={shouldBeDisabled}
                    maxLimit={Math.min(multiMax, (quantity || 0) + max - groupQuantity)}
                    onUpdate={this.onIncrementerChoiceUpdate}
                    quantity={quantity || 0}
                  />
                </div>
              ) : null}
            </div> */}
          </div>
        );
      } else {
        return (
          <>
            <IonItem
              className={`sub-item ${choiceIndex == choices.length - 1 ? 'last-item' : ''} ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''
                } `}
              lines="none"
              key={makeKey(choiceIndex, sku, groupIndex)}
            >
              <div tabIndex="-1"></div>
              {multiSelectionPermitted ? (
                <IonCheckbox
                  disabled={shouldBeDisabled || !Basket.isProductUnsnoozed(item)}
                  color="secondary"
                  slot="start"
                  checked={isChecked}
                  onIonChange={(event) => {
                    Basket.isProductUnsnoozed(item) &&
                      this.handleInputChange(
                        groupIndex,
                        choiceIndex,
                        multiSelectionPermitted,
                        event,
                      );
                  }}
                />
              ) : (
                <IonRadio
                  slot="start"
                  className="details-radio"
                  color={isWebConfig() ? 'secondary' : 'white'}
                  value={choiceIndex}
                  checked={isChecked}
                  disabled={shouldBeDisabled || !Basket.isProductUnsnoozed(item)}
                />
              )}
              <IonLabel className="ion-text-wrap ">
                <StrongText className="single-item item-details-product-label">
                  {__(getProductName(item, profile))}
                </StrongText>
                {isDefined(description) && description !== '' ? (
                  <>
                    <SmallText
                      color="primary"
                      className="block no-margin"
                      dangerouslySetInnerHTML={{ __html: description.replace(/(<([^>]+)>)/gi, '') }}
                    />
                    {/* <Spacer size="1" /> */}
                  </>
                ) : null}
                {isDefined(item.itemRichData) &&
                  isDefined(item.itemRichData.allergenCodes) &&
                  item.itemRichData.allergenCodes.length > 0 ? (
                  <>

                    <PillGroup items={item.itemRichData.allergenCodes} borderColor="primary" />
                  </>
                ) : null}
                <NormalText className="block">
                  {productPrice > 0 && `+${formatPrice(productPrice)}`}
                </NormalText>
              </IonLabel>
            </IonItem>
            <div className="bundle-modifiers-wrapper">
              {item.subModifiers &&
                isChecked &&
                this.drawSubModifiers(item.subModifiers, groupIndex, choiceIndex)}
            </div>
          </>
        );
      }
    });
    if (multiSelectionPermitted) {
      return <div className="item-details-group-wrapper">{allChoices}</div>;
    } else {
      //radio
      return (
        <div className="item-details-group-wrapper">
          <IonRadioGroup
            allowEmptySelection={true}
            onIonChange={(event) => {
              this.handleInputChange(
                groupIndex,
                event.target.value,
                multiSelectionPermitted,
                event,
              );
            }}
          >
            {allChoices}
          </IonRadioGroup>
        </div>
      );
    }
  };

  drawGroupLabel = (menuGroupItem, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;
    const { description, min, max } = menuGroupItem;
    const itemDontHaveMinMax = (!isDefined(min) && !isDefined(max)) || (min === 0 && max === 0);
    // let minMaxlabel = (isDefined(min) ? __('min') + ':' + min : '') + (isDefined(max) ? ', ' + __('max') + ':' + max : '')
    let minMaxlabel = null;
    if (isDefined(min) && isDefined(max)) {
      if ((min === 0 && max === 0) || (min === 0 && max === -1)) {
        minMaxlabel = null;
      }
      if (min === 0 && max > 0) {
        minMaxlabel = __('Select up to') + ' ' + max + ' ' + __('items (optional)');
      }
      if (min > 0 && min === max) {
        minMaxlabel = __('Select') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
      }
      if ((min > 0 && max === 0) || max === -1) {
        minMaxlabel =
          __('Select at least') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
      }
      if (min > 0 && max > 0 && min !== max) {
        minMaxlabel =
          __('Select between') + ' ' + min + ' ' + __('and') + ' ' + max + ' ' + __('options');
      }
      if (min == 0 && max == 1) {
        minMaxlabel = ' '
      }
    } else {
      minMaxlabel = null;
    }
    return (
      <>
        <>
          <div tabIndex="-1" >
            <Subtitle className='lh-1 secondary-font dark-color  block'>{getModifierGroupName(menuGroupItem, this.props.profile)}</Subtitle>
            
            {getModifierGroupDescription(menuGroupItem, this.props.profile) !== '' ?(
              <>
                <SmallText className={`bold uppercase primary-color`}>
                  {getModifierGroupDescription(menuGroupItem, this.props.profile)}
                </SmallText>
                <Spacer size={1} />
              </>
            ) : null}
            {itemDontHaveMinMax ? null : isDefined(minMaxlabel) && minMaxlabel !== '' ? (
              <>
                <SmallText className={`${validationErrors[groupIndex] ? 'field-error' : ''} bold uppercase primary-color`}>
                  {validationErrors[groupIndex] ? __(validationErrors[groupIndex]) : minMaxlabel}
                </SmallText>
              </>
            ) : null}
          </div>
        </>
        {itemDontHaveMinMax && validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index, showUpsell) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const isUpsell = menuGroupItem.isUpsell;
    if (isUpsell && !this.state.productHasUpsell) {
      this.setState({ productHasUpsell: true });
    }
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if ((showUpsell && isUpsell) || (!showUpsell && !isUpsell)) {
      if (isChoicesGroupValid(menuGroupItem)) {
        if (isDefined(multiSelectionPermitted)) {
          return (
            <div className={this.state.validationErrors[index] ? 'group-required' : ''} key={index}>
              {this.drawGroupLabel(menuGroupItem, index)}
              {this.drawGroupChoices(menuGroupItem, multiSelectionPermitted, index)}
            </div>
          );
        } else {
          return this.drawGroupLabel(menuGroupItem);
        }
      }
    }
  };

  constructBasketItem = () => {
    const { item } = this.props;
    const { quantity, selectedChoices, instructions, selectedSubChoices } = this.state;

    return {
      item,
      quantity,
      selectedChoices,
      instructions,
      selectedSubChoices,
    };
  };

  addToOrder = () => {
    if (this.state.productHasUpsell && !this.state.upsellModalOpen) {
      this.setState({ upsellModalOpen: true });

    } else {


      const newBasketItem = this.constructBasketItem();
      const validationErrors = validateItem(newBasketItem);
      const item = this.props.item;
      for (let i = 0; i < newBasketItem.selectedChoices.length; i++) {
        const sub = newBasketItem.selectedSubChoices[i].flat(1);
        newBasketItem.selectedChoices[i].push(...sub);
      }

      if (validationErrors.errorCount > 0) {
        this.setState({ validationErrors: validationErrors.errors }, () => {
          this.setShowValidationAlert(true);
        });
      } else {
        if (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)) {
          this.props.dispatch(storeItemWeb(newBasketItem));
          forwardTo('/click-and-collect');
        } else {
          const foundItem = Basket.getItems().find((fItm) => fItm.item.sku === newBasketItem.item.sku)
          if (!Basket.checkIfItemIsLimited(newBasketItem, (foundItem?.quantity || 0) + newBasketItem.quantity)) {
            addToBasket(newBasketItem);
            if (isWebConfig()) {
              this.props.closeModal();
            } else {
              goBack();
            }
          }
        }
      }

      let allergensCodes =
        item &&
          item.itemRichData &&
          item.itemRichData.allergenCodes &&
          item.itemRichData.allergenCodes.length > 0
          ? item.itemRichData.allergenCodes
          : [];
      if (allergensCodes.length > 0) {
        let allergensData = [{ allergens: this.state.allergensCodes }, { sku: item.sku }];
        if (isDefined(newBasketItem.selectedChoices)) {
          newBasketItem.selectedChoices.forEach((group) => {
            group.forEach((item) => {
              if (
                isDefined(item.itemRichData) &&
                isDefined(item.itemRichData.allergenCodes) &&
                item.itemRichData.allergenCodes.length > 0
              ) {
                setAllergen([{ allergens: item.itemRichData.allergenCodes }, { sku: item.sku }]);
              }
            });
          });
        }
        setAllergen(allergensData);
      }
    }

  };

  instructionsChange = (event) => this.setState({ modalInstructions: event.target.value });

  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

  calcPriceOfItem = (itemPrice, quantity) => {
    let price = itemPrice;
    if (+itemPrice > 0) {
      price = itemPrice * quantity;
    } else {
      price = 0;
    }

    return price.toFixed(2);
  };
  scrollToRequiredField = () => {
    const requiredGroups = document.querySelectorAll('.group-required');
    if (!isWebConfig()) {
      document.querySelector('.item-detals-background').scrollTo({
        top: requiredGroups[0].offsetTop + 420,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      document.querySelector('.item-details-content').scrollTo({
        top: requiredGroups[0].offsetTop + 210,
        left: 0,
        behavior: 'smooth',
      });
    }
  };
  render() {
    const { __, profile, item } = this.props;
    const { price, quantity, showValidationAlert, allergensCodes } = this.state;
    const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0;
    return (
      <>
        <div className="item-details-card-content">
          <IonList className="item-details-card-list">
            <div>
              {getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, '') && (
                <NormalText
                  color="primary"
                  className="block item-details-card-description "
                  dangerouslySetInnerHTML={{
                    __html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                  }}
                ></NormalText>
              )}
            </div>
            {allergensCodes.length > 0 ? (
              <>
                <NormalText className='block capitalize secondary-color'><span className=' primary-color'>{__('Allergens')}: </span>{allergensCodes.join(' • ')}</NormalText>
              </>
            ) : null}

            {item.calories && item.calories !== 0 ? 
              <NormalText className='block'>{item.calories}kcal</NormalText> 
              : null
            }
            <Spacer size={1} />
            <div className="item-details-card-price okx-font-secondary">
              <Subtitle className='dark-color secondary-font  uppercase'>{formatPrice(item.productPrice)}</Subtitle>
            </div>


            <Spacer size={1} />
            {menuDealGroups.map((el, index) => this.drawMenuDealGroups(el, index))}
            {getConfig().flags.specialInstructions.isDisplayed ? (
              <>
                {this.state.instructions == '' ? (
                  <IonButton
                    onClick={() => {
                      this.setState({ specialInstructionsModalOpen: true });
                    }}
                    fill="clear"
                    expand="block"
                    color="secondary"
                    className="uppercase no-borders add-instructions-button capitalized underlined  transparent"
                  >
                    {__('Add special instructions')}
                  </IonButton>
                ) : (
                  <>
                    <Spacer size={1} />
                    <div className="special-instructions-title">
                      <IonItem lines="none">
                        <div tabIndex="-1"></div>
                        <NormalText className='uppercase  secondary-color'>{__('Special Instructions')}</NormalText>
                      </IonItem>
                      <SmallText onClick={() => {
                        this.setState({ specialInstructionsModalOpen: true });
                      }} className='bold underlined uppercase'>
                        {__('Edit')}
                      </SmallText>

                    </div>

                    <div
                      className="special-instructions-wrapper "
                      onClick={() => {
                        this.setState({ specialInstructionsModalOpen: true });
                      }}
                    >
                      <NormalText>{this.state.instructions}</NormalText>
                    </div>
                  </>
                )}
                <Modal
                  onDidDismiss={() => this.setState({ specialInstructionsModalOpen: false })}
                  className="special-instructions-modal"
                  isOpen={this.state.specialInstructionsModalOpen}
                >
                  <NormalText className='bold-text uppercase secondary-color bold'>{__('Special Instructions')}</NormalText>
                  <Spacer size={1} />
                  <IonItem lines="none" className="input-field-wrapper">
                    <IonTextarea
                      ref={this.specialInstructionsEl}
                      onIonChange={this.instructionsChange}
                      rows={5}
                      placeholder={__(getConfig().general.specialInstructionsPlaceholder)}
                      value={this.state.modalInstructions}
                    ></IonTextarea>
                  </IonItem>
                  <Spacer size={1} />
                  <IonButton
                    color="secondary"
                    expand="block"
                    className="uppercase"
                    onClick={() => {
                      this.specialInstructionsEl.current.blur();
                      setTimeout(() => {
                        this.setState({
                          instructions: this.state.modalInstructions,
                          specialInstructionsModalOpen: false,
                        });
                      }, 500);
                    }}
                  >
                    {' '}
                    {__('Save & Close')}
                  </IonButton>
                </Modal>
              </>
            ) : null}
          </IonList>
        </div>
        <div className="mobile-only" style={{ height: '190px' }}></div>

        <div className="item-details-actions">
          <Incrementer
            minLimit={1}
            allowNegative={false}
            quantity={quantity}
            onUpdate={this.onIncrementerUpdate}
          />
          <IonButton
            disabled={quantity === 0}
            className={'item-details-add-to-order ' + (isAddToOrderBtnValid ? '' : 'disabled')}
            shape="round"
            color="secondary"
            onClick={!isAddToOrderBtnValid ? this.scrollToRequiredField : this.addToOrder}
          >
            {__('Add to order')}
            {/* {Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
              ? __('Start New Order')
              : __('Add to Order')} */}
            {/* {+price === 0 ? '' : ` - ${price}`} */}
          </IonButton>
        </div>
        <IonAlert
          isOpen={showValidationAlert}
          onDidDismiss={() => this.setShowValidationAlert(false)}
          header={__('Validation')}
          message={__('Please check any required options')}
          buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
    allergens: store.restaurants.allergens,
    storedItemWeb: store.orders.storedItemWeb,
    ikentooMenu: store.restaurants.ikentooMenu || {},
    nutritionData: store.common.nutritionData,
  };
};

export const ItemDetailsRaw = connect(mapStateToProps)(withTranslation(itemDetailsContent));

export class itemDetails extends Component {
  constructor(props) {
    super(props);
    this.x = React.createRef();
    this.state = {
      nameVisible: true,
    };
  }
  onContentScrollHandler() {
    if (!this.isScrolledIntoView(this.x.current)) {
      this.setState({ nameVisible: false });
    } else {
      this.setState({ nameVisible: true });
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }
  shouldComponentUpdate(prevProps, prevState) {
    if (this.state.nameVisible !== prevState.nameVisible) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { __, location, history, profile } = this.props;

    const item = location.state;
    const { selectedMenu } = item;
    let image =
      item && item.itemRichData && item.itemRichData.squareImageUrl
        ? item.itemRichData.squareImageUrl
        : '';
    if (image && image.indexOf('http://') !== -1) {
      image = image.replace(/http:\/\//g, 'https://');
    }
    // const allergens = item.allergens || ['Celery (including celeriac)', 'Fish', 'Crustaceans', 'Lupin', 'Mustard']
    return (
      <Layout
        headerTitle={__('Item Details')}
        scrollY={false}
        noPadding
        contentClassName="item-details-wrapper"
        hideSecondToolbar={true}
      >
        <div className="item-details-main-wrapper">
          <div
            className={
              this.state.nameVisible ? ' item-details-header' : 'active item-details-header'
            }
          >
            <div className="item-details-back">
              <IonButton
                className="solo-button"
                color="white"
                onClick={() => forwardTo('/order', { selectedMenu })}
              >
                <IonIcon color='dark' icon={arrowBack} />
              </IonButton>
            </div>
            <div
              style={{
                transition: !this.state.nameVisible ? 'transform 0.3s ease-in-out' : 'none',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
                transform: this.state.nameVisible ? 'translateY(-200%)' : 'translateY(0)',
              }}
            >
              <Title className="item-details-name secondary-font dark-color">
                {__(getProductName(item, profile))}
              </Title>
            </div>
          </div>
          <div
            className="scrollable-y item-detals-background"
            onScroll={() => {
              this.onContentScrollHandler();
            }}
          >
            <div className="item-details-info">
              {image && image !== '' ? (
                <div className="item-details-img">
                  <img src={image} />
                </div>
              ) : (
                <div className="image-placeholder"></div>
              )}
              <div className="item-details-name" ref={this.x}>
                <Title className='secondary-font dark-color  '> {__(getProductName(item, profile))}</Title>
              </div>
            </div>

            <ItemDetailsRaw item={item} profile={profile} />
          </div>
        </div>
        {/* <Modal
          isOpen={this.state.upsellModalOpen}
          onDidDismiss={() => this.setState({ upsellModalOpen: false })}
          className="upsell-modal"
        >
          <div>
            {menuDealGroups.map((el, index) => this.drawMenuDealGroups(el, index, true))}
            <IonButton expand="block" color="secondary" onClick={()=>this.addToOrder}>
              {__('Add to order')}
            </IonButton>
          </div>
        </Modal> */}
      </Layout>

    );
  }
}

const stateToProps = (store) => {
  return {
    profile: store.profile.profile,
  };
};

export default connect(stateToProps)(withTranslation(itemDetails));
