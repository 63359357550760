import React from 'react';
import { connect } from 'react-redux';
import { IonAlert, IonButton } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import SplitedInput from '../../components/splitedInput';
import Modal from '../../components/modal';
import { joinTable } from '../../store/actions';
import './index.css';
import { forwardTo } from '../../lib/utils';
import receipt from '../../assets/images/receipt.png';

class CheckTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTicket: false,
      code: '',
      showErrorAlert: false,
    };
  }
  codeHandler = (code) => {
    this.setState({ code: code });
  };
  submitHandler = () => {
    const { code } = this.state;
    localStorage.removeItem('tableError');
    if (code.length === 8) {
      this.props.dispatch(
        joinTable({
          location: code.slice(0, 4).toUpperCase(),
          token: code.slice(4, 8).toUpperCase(),
        }),
      );
      this.setState({ code: '' });
    } else {
      this.setState({ showErrorAlert: true });
    }
  };
  render() {
    const { __ } = this.props;
    const { code, showErrorAlert } = this.state;
    return (
      <Loading transparent>
        <Layout
          color="transparent"
          showHeader
          showFooter
          hideSecondToolbar={true}
          headerTitle={__('CheckTable')}
        >
          <NormalText className="uppercase bold-text">
            {__('Oh no! Your qr code did not work....')}
          </NormalText>
          <Spacer size={1} />
          <NormalText className="uppercase bold-text">
            {__('Try entering the 8 digit code instead.')}
          </NormalText>
          <Spacer size={1} />
          <NormalText
            className="underlined"
            onClick={() => {
              this.setState({ showTicket: true });
            }}
          >
            {__('Where can I find my code?')}
          </NormalText>
          <Spacer size={1} />
          <div className="splited-input-div">
            <SplitedInput
              format="IIIIIIII"
              text={code}
              valueHandler={this.codeHandler}
              inputClass="table-code-input"
              inputProps={{
                type: 'number',
                inputmode: 'tel',
              }}
            />
          </div>
          <IonButton
            className="student-button"
            expand="block"
            color="secondary"
            strong={true}
            onClick={this.submitHandler}
          >
            {__('Submit')}
          </IonButton>
          <IonButton
            className="cancel-button"
            expand="block"
            strong={true}
            onClick={() => forwardTo('/homepage')}
          >
            {__('Cancel')}
          </IonButton>
        </Layout>
        <IonAlert
          isOpen={showErrorAlert}
          onDidDismiss={() => this.setState({ showErrorAlert: false })}
          header={__('Oh no!')}
          message="Please enter an 8 digit code"
          cssClass="no-table-alert one-red-button-alert"
          color="secondary"
          buttons={[
            {
              text: 'OK',
              role: 'confirm',
              //handler: () =>  forwardTo('/order-to-table')
            },
          ]}
        />

        <Modal
          cssClass="validate-modal"
          isOpen={this.state.showTicket}
          onDidDismiss={() => {
            this.setState({ showTicket: false });
          }}
        >
          <img src={receipt} />
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {};
};

export default connect(stateToProps)(withTranslation(CheckTable));
