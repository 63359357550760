import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';

import { getConfig } from '../../appConfig';
import './index.css';
import { NormalText, SmallText, Spacer, Title } from '../../components/common';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

import noOrderImg from '../../assets/images/backgrounds/chef.png';
import { forwardTo, goBack } from '../../lib/utils';
import { GET_ORDER_HISTORY } from '../../store/constants';
import Basket from '../../lib/basket';
class PreviousOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };

  }

  componentDidMount() {
    this.props.dispatch({ type: GET_ORDER_HISTORY, loading: false })
  }

	orderAgain = (order) => {
		const selectedRestaurant = this.props.restaurants.filter((restaurant) => restaurant.name === order.restaurant_name);

		if (selectedRestaurant.length > 0) {
			Basket.reset();
			Basket.setDeliveryOption('collection');
			forwardTo('/click-and-collect', { reorderData: {...order,selectedRestaurant:selectedRestaurant[0]} });
		} else {
			this.props.dispatch(showToast('This restaurant is no longer receiving orders.', 'warning'));
		}
	};

  render() {
    const { __, orderHistory } = this.props;

    return (
      <Layout
        showHeader
        showFooter
        headerWithTitle={true}
        headerTitle={__('Previous orders')}
        hideSecondToolbar={true}
        color="transparent"
        scrollY={false}
        backHandler={goBack}
        showBack
      >
        <div className='absolute-content'>
          <div className='page-secondary-header' style={{ flex: 'unset' }}>
            <IonIcon onClick={() => goBack()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>
            <Title className="secondary-font dark-color">{__('Previous Orders')}</Title>
          </div>
          <div className='previous-order-content '>
            {getConfig().general.previousOrderText &&
              <NormalText className='centered block'>{__(getConfig().general.previousOrderText)}</NormalText>

            }
            <Spacer size={1} />
            {this.props?.orderHistory?.length == 0 ? <>
              <NormalText className='centered bold-text block secondary-color'>{__('NO ORDER HISTORY')}</NormalText>
              <Spacer size={1} />

              <NormalText className='centered block '>{__('You haven’t placed any order & collect yet')}</NormalText>
              <img src={noOrderImg} />
            </> : <div className=' scrollable-y'>
              
              {this.props.orderHistory.map(order => {
                const withoutZ = order.collection_time.replace('Z', '');
                const transDate = Basket.getDate(withoutZ);
                return (
                  <IonRow className='history-item' >
                    <IonCol size='7' onClick={()=>forwardTo('/history-details',{ order })}>
                      <SmallText>{order.restaurant_name}</SmallText>
                      <NormalText className='block secondary-font dark-color'>{__('Order & Collect')}</NormalText>
                      <SmallText className=" break-spaces">
                        {__(transDate.format('ddd')) +
                          transDate.format(' DD ') +
                          __(transDate.format('MMM')) +
                          transDate.format(' YYYY ') +
                          __('at') +
                          transDate.format(' h:mm a')}
                      </SmallText>
                    </IonCol>
                    <IonCol size='5' style={{display:'flex',alignItems:'center'}} >
                    <IonButton onClick={() => this.orderAgain(order)} color="secondary" className="small-btn rounded-btn ">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															className="icon icon-tabler icon-tabler-repeat"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															strokeWidth="2"
															fill="none"
															strokeLinecap="round"
															strokeLinejoin="round"
														>
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3"></path>
															<path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3"></path>
														</svg>
														{__('Repeat')}
													</IonButton>
                    </IonCol>

                  </IonRow>
                  // <IonButton onClick={()=>forwardTo('/order-completed',{ completedOrder: true })}>
                  //   {order.id}
                  // </IonButton>


                )
              })}
            </div>}



          </div>

        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { tableData, appliedVoucherModalOpen } = state.orders || {};
  return {
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
    restaurants: state.restaurants.restaurants || [],
    vouchers: state.profile.vouchers || [],
    tableData,
    appliedVoucherModalOpen,
  };
};

export default withRouter(withTranslation(connect(stateToProps)(PreviousOrders)));
