import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';

class NoData extends Component {
  render() {
    const { __, label, className, style } = this.props;
    return (
      <div className={className || ''} style={{ textAlign: 'center', ...(style || {}) }}>
        <div>{__(label || 'No data')}</div>
      </div>
    );
  }
}

export default withTranslation(NoData);
