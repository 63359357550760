import React from 'react';
import { IonHeader, IonContent, IonIcon, IonToolbar, IonButtons, IonButton } from '@ionic/react';
import menuIcon from '../../assets/images/icons/footer-menu.svg';

const SmallDrawer = ({ toggleMenu }) => {
  return (
    <>
      <IonHeader className="small-drawer-header">
        <IonToolbar className="small-drawer-toolbar">
          <IonButtons>
            <IonButton className='pointer' button onClick={() => toggleMenu()}>
              <img  src={menuIcon}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export default SmallDrawer;
