import React from 'react';
import { withRouter } from 'react-router';
import {
  IonContent,
  IonFooter,
  IonMenuToggle,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { getConfig } from '../../appConfig';
import { forwardTo, getRouteClassName, isWebConfig } from '../../lib/utils';
import './index.css';
import StaticHeader from '../staticHeader';
import { connect } from 'react-redux';
import homeIcon from '../../assets/images/icons/footer-home.svg';
import menuIcon from '../../assets/images/icons/footer-menu.svg';
import locationIcon from '../../assets/images/icons/footer-location.svg';

import { Title } from '../common';
const defaultHeaderTitle = getConfig().general.clientName;

const Layout = ({
  history,
  children,
  headerTitle,
  hideSecondToolbar,
  hideBack,
  color,
  poster,
  blank,
  noPadding,
  contentClassName,
  scrollY,
  backHandler,
  profile,
  navConfig,
  withRefresher,
  onRefreshHandler,
  showHeader,
  showFooter,
  disableRouteClassName,
  selectCategoryOnScroll,
  showBack,
  disableHeader
}) => {
  useIonViewWillEnter(() => {
    // eslint-disable-next-line no-console
    console.log('1. WillEnter event fired');
  });
  useIonViewDidEnter(() => {
    // eslint-disable-next-line no-console
    console.log('2. DidEnter event fired');
  });
  useIonViewWillLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('3. WillLeave event fired', a, b, c);
  });
  useIonViewDidLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('4. DidLeave event fired', a, b, c);
  });
  const routeClassName = disableRouteClassName ? '' : getRouteClassName(history.location.pathname, navConfig);
  return (
    <>
      <>{blank ? null : <>{showHeader && <StaticHeader disableHeader={disableHeader} showBack={showBack} backHandler={backHandler} />}</>}</>
      <IonContent
        scrollY={scrollY !== undefined ? scrollY : true}
        color={color || 'transparent'}
        className={routeClassName + (contentClassName ? ' ' + contentClassName : '')}
      >
        {withRefresher && !isWebConfig() && (
          <IonRefresher pullFactor="0.8" onIonRefresh={(e) => onRefreshHandler(e)} slot="fixed">
            <IonRefresherContent pullingIcon="chevron-down-circle-outline"></IonRefresherContent>
          </IonRefresher>
        )}
        {poster ? <div className={'poster ' + poster} /> : null}
        <div onScroll={routeClassName === 'route-order' ? selectCategoryOnScroll : null} className={noPadding ? 'no-padding' : 'ion-padding'}>{children}</div>
      </IonContent>
      {showFooter && (
        <IonFooter class="app-footer">
          <div onClick={() => forwardTo('/restaurants')}>
            <img src={locationIcon} />
          </div>
          <div onClick={() => forwardTo('/homepage')}>
            <img src={homeIcon} />
          </div>

          <IonMenuToggle>
            <img src={menuIcon} />
          </IonMenuToggle>
        </IonFooter>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    navConfig: state.common.navConfig,
    profile: state.profile.profile,
  };
}
export default connect(mapStateToProps)(withRouter(Layout));
