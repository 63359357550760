import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';

import './index.css';
import Modal from '../modal';
import { IonButton } from '@ionic/react';
import { setCommonModal } from '../../store/actions';
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../common';
import slider1 from '../../assets/images/backgrounds/slider-1.png';
import slider2 from '../../assets/images/backgrounds/slider-2.png';
import slider3 from '../../assets/images/backgrounds/slider-3.png';
import slider4 from '../../assets/images/backgrounds/slider-4.png';
import logoGif from '../../assets/images/backgrounds/welcome-box-pic.png';
import Slider, { Slide } from '../../components/slider';

class TutorialModal extends React.Component {
  render() {
    const { __, isTutorialModalOpen } = this.props;

    return (
      <Modal backdropDismiss={false} cssClass="tutorial-modal" isOpen={isTutorialModalOpen}>
        <Slider
          settings={{
            navigation: true,
            pagination: true
          }}
        >
          <Slide>
            <div className="tutorial-slider">
              <div className="tutorial-header" style={{ justifyContent: 'center' }}>
                <img src={logoGif} />
              </div>
              <div className="tutorial-content">
                <Title className="regular-font uppercase block centered">
                  WELCOME TO THE FRANCO MANCA APP
                </Title>
                <Spacer size={1} />
                <Title className="regular-font uppercase block centered">
                  SWIPE RIGHT FOR A QUICK GUIDE
                </Title>
              </div>
            </div>
          </Slide>
          <Slide>
            <div className="tutorial-slider">
              <div className="tutorial-header">
                <img src={logoGif} />
                <NormalText className="uppercase block centered">
                  CHECK IN TO YOUR TABLE TO EARN STAMPS
                </NormalText>
              </div>
              <div className="tutorial-content">
                <SmallText className="uppercase block centered">
                  THE TEAM WILL BRING A SLIP WITH YOUR DRINKS - SCAN TO CHECK IN
                </SmallText>
                <Spacer size={1} />
                <img className="block" src={slider1} />
                <Spacer size={1} />
                <SmallText className="uppercase block centered">
                  AFTER YOU'VE PAID, A STAMP WILL BE ADDED AUTOMATICALLY IF A VALID MAIN COURSE WAS
                  PURCHASED
                </SmallText>
              </div>
            </div>
          </Slide>

          <Slide>
            <div className="tutorial-slider">
              <div className="tutorial-header">
                <img src={logoGif} />
                <NormalText className="uppercase block centered">
                  EARN 6 STAMPS FOR A PIZZA ON THE HOUSE
                </NormalText>
              </div>
              <div className="tutorial-content">
                <SmallText className="uppercase block centered">
                  ONCE YOU HAVE SIX STAMPS, SELECT ‘VIEW REWARDS’ TO SEE WHAT YOU CAN REDEEM
                </SmallText>
                <Spacer size={1} />
                <img className="block" src={slider3} />
              </div>
            </div>
          </Slide>
          {this.props.profile.is_student && (
            <Slide>
              <div className="tutorial-slider">
                <div className="tutorial-header">
                  <img src={logoGif} />
                  <NormalText className="uppercase block centered">
                    STUDENT + <br /> UNIDAYS
                  </NormalText>
                </div>
                <div className="tutorial-content">
                  <SmallText className="uppercase block centered">
                    ONCE VALIDATED AS A STUDENT, YOU CAN EARN A BONUS STAMP FOR EVERY STAMP CARD
                    COMPLETED
                  </SmallText>
                  <SmallText className="uppercase block centered">
                    SELECT STUDENTS ON THE APP MENU FOR MORE INFO
                  </SmallText>
                  <Spacer size={1} />
                  <img className="block" src={slider4} />
                </div>
              </div>
            </Slide>
          )}
          <Slide>
            <div className="tutorial-slider">
              <div className="tutorial-header">
                <img src={logoGif} />
                <NormalText className="uppercase block centered">
                  EARN STAMPS FOR TAKEAWAY
                </NormalText>
              </div>
              <div className="tutorial-content">
                <SmallText className="uppercase block centered">
                  SELECT ‘ADD STAMP' & ENTER THE CODE FROM YOUR TAKEAWAY OR DELIVERY RECEIPT (1
                  STAMP PER TRANSACTION)
                </SmallText>
                <Spacer size={1} />
                <img className="block" src={slider2} />
                <Spacer size={2} />
                <IonButton
                  color="secondary"
                  className='tutorial-button'
                  onClick={() => {
                    this.props.dispatch(setCommonModal('isTutorialModalOpen', false));
                  }}
                >
                  {__("Let's go")}
                </IonButton>
              </div>
            </div>
          </Slide>
        </Slider>
      </Modal>
    );
  }
}

const stateToProps = (store) => {
  const { isTutorialModalOpen } = store.common;
  const { profile } = store.profile;
  return {
    isTutorialModalOpen,
    profile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(TutorialModal)));
