import React from 'react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import {
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import { IonRow, IonCol, IonButton, IonAlert, IonGrid, IonIcon } from '@ionic/react';
import Box, { BoxHeader } from '../../components/box';
import OrderContent from '../../components/orderContent';
import { createNewBasketInstance } from '../../lib/basket';
import Basket from '../../lib/basket';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import moment from '../../lib/moment';
import { forwardTo, isEmptyObject, visaEndingDrawText } from '../../lib/utils';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import AmountPaid from '../../components/amountPaid';
import './index.css';
import { chevronBack } from 'ionicons/icons';




export const printOrderPayment = (__, order) => {
  return (
    <IonRow className='amount-values'>
      <IonCol className="paddLR text-start" size='8'>
        <NormalText className="secondary-font dark-color ">
          {`${order.card.brand.toUpperCase()} ${order.status.toLowerCase() === 'captured' ? __('payment completed') : __('payment failed')}`}
          <br />
          {`${__('Card ending')} ${order.card.last_four}`}
        </NormalText>

      </IonCol>
      <IonCol className="righted paddLR" size='4'>
        <NormalText className="secondary-font dark-color ">
          {Basket.formatPrice(order.amount_paid / 100, true)}
        </NormalText>

      </IonCol>
    </IonRow>


  )

}
class HistoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidMount() {
    if (!this.props.location.state) {
      forwardTo('/history');
    } else {
      const { location } = this.props;
      const orderArr = location?.state?.order;
      const now = moment();
      let cutoffTime = orderArr ? orderArr.cutoff_time : now;
      let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
      const utcOffset = cutoffTimeMoment.utcOffset();
      cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
      if (now.isBefore(cutoffTimeMoment)) {
        if (orderArr.status !== 'REFUNDED') {
          this.setState({
            isCancel: true,
            orderId: orderArr.id,
            restaurantId: orderArr.restaurant_id,
          });
        }
      }
    }
  }

  backHandler = () => {
    this.setState({ orderId: null, isCancel: false, restaurantId: null });
    forwardTo('/dashboard');
    forwardTo('/previous-orders', { tab: 'order' });
  };

  formatPaymentString = (str) => {
    let splitedStr = str.split(' ').splice(2).join(' ');
    return splitedStr;
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          {__('Delivery')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
          {__('Order To Table')} #{id}
        </Title>
      );
    } else {
      return (
        <Title>
          {__('Click And Collect')} {__('Order')} #{id}
        </Title>
      );
    }
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage, status) => {
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    if (orderType === 'Click & Collect') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Collect from')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <SmallText className="block">
                {__('Collect at')}:{' '}
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </SmallText>
              <SmallText className="block">
                {__('Order placed on')}:{' '}
                {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{status && __(status)}</div>
            </div>
          </div>
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Delivery from')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <SmallText className="block">
                {__('Order placed on')}: {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
              </SmallText>
              <SmallText className="block">
                {__('Delivery at')}: {basketInstance.formatOrderTime(true)}
              </SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{status && __(status)}</div>
            </div>
          </div>
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Drop-off at')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <SmallText className="block">
                {__('Order placed on')}:{' '}
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </SmallText>
              <SmallText className="block">
                {__('Drop-off Time')}: {basketInstance.formatOrderTime(true)}
              </SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{status && __(status)}</div>
            </div>
          </div>
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Ordered at')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <StrongText className="block">
                {__('Table Number')}-{order.table_name}
              </StrongText>
              <SmallText>{moment(order.created_at).format('D MMM YYYY [at] h:mm a')}</SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{status && __(status)}</div>
            </div>
          </div>
        </>
      );
    }
  };
  drawSubItemsText = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;
    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <div className="flex-justify-between flex-col-wrapper">
              <SmallText className='uppercase dark-color'>+ {Basket.getProductName(choice, profile)}
                {parseInt(choice.productPrice) > 0 &&
                  <span className='subchoice-price secondary-color'>+{Basket.formatPrice(choice.productPrice)}</span>
                }
              </SmallText>


            </div>

          );
        });
      });
    }
    return null;
  };
  render() {
    if (!this.props.location.state) {
      return null;
    }
    const { __, location, cards, profile } = this.props;
    const order = location.state.order;
    const { id, status } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);

    const headerLabel = `${__('Order')} ${`#${id}`}`;
    const withoutZ = order.collection_time.replace('Z', '');

    const transDate = Basket.getDate(withoutZ);
    return (
      <Loading>
        <Layout
          headerWithTitle={true}
          hideSecondToolbar={true}
          color="transparent"
          headerTitle={headerLabel}
          backHandler={this.backHandler}
          showHeader
          showFooter
          showBack
        >
          <div className="flex-row-wrapper absolute-content">
            <div className='page-secondary-header' style={{ flex: 'unset' }}>
              <IonIcon onClick={() => this.backHandler()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>
              <Title className="secondary-font dark-color">{__(headerLabel)}</Title>
            </div>
            <div className="scrollable-y">
              <div className='order-header-content-wrapper'>
              {
                order.status.toLowerCase() !== 'captured' &&
                <div
                  className={
                    'pill ellipsis pill-large '
                  }
                >
                  {__('Order failed')}
                </div>
              }
                <Subtitle className='mb-10 secondary-font dark-color  block'>{__('Collect from')}
              
                </Subtitle>

                <NormalText className=' block dark-color' style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{order.restaurant_name}</span>
                </NormalText>
                <NormalText style={{ display: 'flex', justifyContent: 'space-between' }} className=' dark-color'>
                  <span>{__('Collection time')} </span>
                  <span>{transDate.format('DD MMM YYYY [at] h:mm a')}</span>
                </NormalText>
              </div>
              <Spacer size={1} />
            
              <IonGrid className="box-wrapper order-content-wrapper paddL">

                <Subtitle className="mb-15 block secondary-font dark-color">
                  {__('Your order')}
                </Subtitle>
                <div className='basket-items-wrapper'>
                  {order.items.map((basketItem, basketItemIndex) => {
                    const { quantity, item, instructions, selectedChoices } = basketItem;
                    let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
                    if (image && image.indexOf('http://') !== -1) {
                      image = image.replace(/http:\/\//g, 'https://');
                    }
                    return (
                      <div className="box-content">
                        <div
                          className="order-item-image"
                          style={image && image !== '' ? { backgroundImage: `url(${image})` } : { backgroundSize: 'contain' }}
                        />
                        <div className="item-content-wrapper flex-justify-between flex-row-wrapper">
                          <div style={{ paddingRight: '50px' }}>
                            <NormalText className="block  dark-color">{Basket.getProductName(item, profile)}</NormalText>
                            {this.drawSubItemsText(basketItem, basketItemIndex)}
                            {basketItem.instructions !== '' && (
                              <>
                                <Spacer size={1} />
                                <SmallText className="block">{`${__('Instructions')}: ${basketItem.instructions}`} </SmallText>
                              </>
                            )}
                          </div>
                          <div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
                            <div></div>
                            <NormalText className='dark-color secondary-font'>
                              {Basket.formatPrice((parseFloat(item.productPrice) * quantity) + selectedChoices.reduce((acc, obj) => acc + (parseFloat(obj[0]?.productPrice) || 0), 0))}
                            </NormalText>
                          </div>
                        </div>
                      </div>

                    )

                  })}
                </div>
                {(order.amount_paid > 0 || order.status.toLowerCase() == 'captured') && <>
                  {order.applied_discounts.length > 0 || order.applied_vouchers.length > 0 &&
                    <IonRow
                      className='amount-values'
                    >
                      <IonCol className="paddLR text-start">
                        <NormalText className="secondary-font dark-color ">{__('Items total')}</NormalText>
                      </IonCol>
                      <IonCol className="righted paddLR">
                        <NormalText className="secondary-font dark-color ">
                          {Basket.formatPrice((order.amount_paid + Math.abs(order.discount)) / 100)}
                        </NormalText>

                      </IonCol>
                    </IonRow>
                  }
                  {order.applied_discounts &&
                    order.applied_discounts.map(discount => {
                      return (
                        <div key={discount.id}>
                          <IonRow
                            className="amount-values"

                          >
                            <IonCol className="paddLR grow ">
                              <NormalText className='secondary-font dark-color'>{__(`${discount.name.charAt(0).toUpperCase()}${discount.name.substring(1)}`)}</NormalText> </IonCol>
                            <IonCol className="righted paddLR">
                              <NormalText className='secondary-font dark-color'>{Basket.formatPrice(discount.cost / 100 * -1)}</NormalText>
                            </IonCol>
                          </IonRow>

                        </div>
                      )
                    })}
                  {order.applied_vouchers &&
                    order.applied_vouchers.map(discount => {
                      return (
                        <div key={discount.id}>
                          <IonRow
                            className="amount-values"

                          >
                            <IonCol className="paddLR grow ">
                              <NormalText className='secondary-font dark-color'>{__(`${discount.reward.name.charAt(0).toUpperCase()}${discount.reward.name.substring(1)}`)}</NormalText> </IonCol>
                            <IonCol className="righted paddLR">
                              <NormalText className='secondary-font dark-color'>{Basket.formatPrice(discount.cost / 100 * -1)}</NormalText>
                            </IonCol>
                          </IonRow>

                        </div>
                      )
                    })}
                </>}
                <IonRow
                  className='amount-values'
                >
                  <IonCol className="paddLR text-start">
                    <NormalText className="secondary-font dark-color ">{__('Total paid')}</NormalText>
                  </IonCol>
                  <IonCol className="righted paddLR">
                    <NormalText className="secondary-font dark-color ">
                      {Basket.formatPrice(order.amount_paid / 100, true)}
                    </NormalText>

                  </IonCol>
                </IonRow>
                {!isEmptyObject(order.card)
                  &&
                  printOrderPayment(__, order)

                }


              </IonGrid>

            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  return {
    cards: orders.cards || [],
    lastOrder,
    restaurants: restaurants || [],
    cancelOrderModal, profile: store.profile.profile
  };
};

export default connect(mapStateToProps)(withTranslation(HistoryDetails));
