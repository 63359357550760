import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonLabel,
  IonButton,
  IonItem,
  IonInput,
} from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import {
  NormalText,
  SmallText,
  Spacer,
  StrongText,
  Subtitle,
  Title,
} from '../../components/common';
import { getConfig } from '../../appConfig';
import { isDefined, isEmptyObject } from '../../lib/utils';
import moment from 'moment';

import student from '../../assets/images/student-pic-initial.png';

const DATE_FORMAT = 'DD/MM/YYYY';
const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const getDeliveryLabel = (deliveryLabel) => {
  switch (deliveryLabel) {
    case 'Scheduled Delivery':
      return 'Delivery Order';
    case 'Order for Delivery':
      return 'Delivery Order';
    case 'Click & Collect':
      return 'Collection Order';
    default:
      return deliveryLabel;
  }
};

const HistoryTab = withRouter(
  ({
    __,
    transactionHistory,
    getTransactionHistory,
    history,
    restaurants,
    dispatch,
    onVoucherRedeem,
    tableData,
    onVoucherRedeemCode,
    onVoucherCodeHandler,
    voucherCodeValue,
    ...rest
  }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order' && item.id >= 61219) {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };

    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            <div className="no-transactions">
              <NormalText className="contact-subtitle">
                {__(`Let's start making pizzas...`)}
              </NormalText>
              <Spacer />
              <IonLabel color="primary">
                <NormalText className="uppercase blocked-input">
                  {__('Collect 6 stamps for a pizza on the house.')}
                </NormalText>
                <Spacer size={1} />
                <NormalText className="uppercase blocked-input">
                  {__(`Your rewards will be shown here.`)}
                </NormalText>
              </IonLabel>
              <Spacer size={1} />
              <img src={student} />
              <Spacer size={1} />
            </div>
          ) : (
            <>
              {type == 'rewards' ? (
                <>
                  {transactionHistory.filter((item) => !item.Redeemed).length > 0 && (
                    <>
                      <StrongText
                        color="secondary"
                        className="secondary-color blocked-input uppercase reward-code-label"
                      >
                        {__('REWARDS AVAILABLE')}
                      </StrongText>
                      <IonGrid style={{ padding: 0 }}>
                        {transactionHistory
                          .filter((item) => !item.Redeemed)
                          .map((item, index) => {
                            return (
                              <IonRow>
                                <IonCol className='description-col' size="9">
                                  <NormalText className="uppercase">{item.Description}</NormalText>
                                  <SmallText className="uppercase block" color="gray">
                                    {__('Received:')} {moment(item.URD).format(DATE_FORMAT)}
                                  </SmallText>
                                  <SmallText className="uppercase block" color="gray">
                                    {__('Expires:')} {moment(item.ExpiryDate).format(DATE_FORMAT)}
                                  </SmallText>
                                </IonCol>
                                <IonCol
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                  size="3"
                                >
                                  <IonButton
                                    className="rounded-btn"
                                    onClick={() => onVoucherRedeem(item)}
                                    color="secondary"
                                  >
                                    {__('Redeem')}
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            );
                          })}
                      </IonGrid>
                    </>
                  )}
                  <Spacer size={1} />
                  {isEmptyObject(tableData) ? (
                    <>
                      <StrongText
                        color="secondary"
                        className="secondary-color blocked-input uppercase reward-code-label"
                      >
                        {__('Reward code?')}
                      </StrongText>
                      <NormalText className="uppercase blocked-input">
                        {__(`You need to be checked in to a table to use a reward code`)}
                      </NormalText>
                    </>
                  ) : (
                    <>
                      <StrongText
                        color="secondary"
                        className="secondary-color blocked-input uppercase reward-code-label"
                      >
                        {__('Reward code?')}
                      </StrongText>
                      <SmallText className="uppercase blocked-input">
                        {__(`IF YOU HAVE A REWARD CODE, ENTER IT HERE BEFORE REQUESTING YOUR BILL`)}
                      </SmallText>
                      <div className="redeem-voucher-wrapper">
                        <IonItem lines="none">
                          <IonInput
                            onIonChange={(e) => onVoucherCodeHandler(e.target.value)}
                          ></IonInput>
                        </IonItem>
                        <IonButton
                          disabled={!voucherCodeValue}
                          color="secondary"
                          className="rounded-btn"
                          onClick={() => {
                            onVoucherRedeemCode();
                          }}
                        >
                          {__('Submit')}
                        </IonButton>
                      </div>
                    </>
                  )}

                  <Spacer size={1} />
                  {transactionHistory.filter((item) => item.Redeemed).length > 0 && (
                    <>
                      <StrongText
                        color="secondary"
                        className="secondary-color blocked-input uppercase reward-code-label"
                      >
                        {__('REWARDS REDEEMED')}
                      </StrongText>
                      <IonGrid style={{ padding: 0 }}>
                        {transactionHistory
                          .filter((item) => item.Redeemed)
                          .map((item, index) => {
                            return (
                              <IonRow>
                                <IonCol className='description-col' size="9">
                                  <NormalText className="uppercase">{item.Description}</NormalText>
                                  <SmallText className="uppercase block" color="gray">
                                    {__('Redeemed Date:')} {item?.RedemptionDate && moment(item.RedemptionDate).format(DATE_FORMAT)}
                                  </SmallText>
                                  <SmallText className="uppercase block" color="gray">
                                    {__('Location:')} {item?.Location || ''}
                                  </SmallText>
                                </IonCol>
                                <IonCol
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                  size="3"
                                >
                                  <IonButton className="rounded-btn" color="primary">
                                    {__('Redeemed')}
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            );
                          })}
                      </IonGrid>
                    </>
                  )}
                </>
              ) : (
                <IonGrid style={{ padding: 0 }}>
                  {transactionHistory.map((item, index) => {
                    const { AmountEarned, TransactionDate, TransactionDescription } = item;
                    const transactionDate = moment(TransactionDate);
                    const transactionHour =
                      transactionDate.hour() < 10
                        ? `0${transactionDate.hour()}`
                        : `${transactionDate.hour()}`;
                    const transactionMinute =
                      transactionDate.minute() < 10
                        ? `0${transactionDate.minute()}`
                        : `${transactionDate.minute()}`;
                    return (
                      <IonRow key={index} className="history-item">
                        <IonCol>
                          <SmallText color="secondary" className="blocked-input">
                            {transactionDate.format(DATE_FORMAT)}{' '}
                            {`${transactionHour}:${transactionMinute}`}
                          </SmallText>
                          <NormalText>{Math.abs(AmountEarned)}</NormalText>
                          <NormalText className="uppercase">{` ${__('Stamp')}`}</NormalText>
                          <NormalText className="blocked-input">
                            {__(TransactionDescription)}
                          </NormalText>
                          <SmallText color="gray" className="uppercase">
                            {item.LocationName ? `${item.LocationName} / ` : ''}{' '}
                          </SmallText>
                        </IonCol>
                      </IonRow>
                    );
                  })}
                </IonGrid>
              )}
            </>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
