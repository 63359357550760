import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import { getTermsAndConditions } from '../../store/actions';
import Loading from '../../components/spinner';
import './index.css';
import { Title } from '../../components/common';

class Terms extends Component {
  componentDidMount() {
    this.props.dispatch(getTermsAndConditions());
  }

  render() {
    const { __, terms } = this.props;
    return (
      <Loading>
        <Layout showHeader showBack hideSecondToolbar={true} color="transparent" headerTitle={__('Terms & Conditions')}>
          <div className="inner-padding">
            <Title>{__('Terms & Conditions')}</Title>
            <div dangerouslySetInnerHTML={{ __html: terms }}></div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    terms: state.common.terms || null,
  };
};

export default connect(stateToProps)(withTranslation(Terms));
