import {
  LOGIN_REQUEST,
  LOGOUT,
  REGISTER_REQUEST,
  GET_PROFILE,
  SET_PROFILE_PROP,
  UPDATE_PROFILE,
  RESTORE_AUTH,
  SET_MODAL,
  RESET_PASSWORD,
  SET_PROTECTED_REFERRER,
  VALIDATE_EMAIL,
  SEND_REFER,
  SEND_VOUCHER_CODE,
  GET_VOUCHERS,
  CHECKOUT_DISCOUNT_VOUCHERS,
  PROFILE_REMOVE_VOUCHER,
  CLEAR_GUEST_FORM,
  SEND_SMS,
  VERIFY_CODE,
  GET_BALANCE,
  DELETE_USER,
} from './constants';
import {
  SET_ORDERS_PROP,
  GET_ORDER_HISTORY,
  SET_COMMON_MODAL,
  /*INIT_FIREBASE_DATABASE,*/ ADD_DELIVERY_ADDRESS,
  ADD_PICKUP_POINT,
  SET_COMMON_PROP,
} from '../constants';
import { loading } from '../common/sagas';
import { take, call, put, select, cancel, spawn } from 'redux-saga/effects';
import api from '../../lib/api';
import { forwardTo, getDefaultRoute, goBack, isDefined, validateProfileData } from '../../lib/utils';
import asyncStorage from '../../lib/asyncStorage';
import { saveFcmToken, translateSaga, firebaseDatabase } from '../common/sagas';
import { getPaymentCards, showToast, storeDeliveryAddress, storePickUpPoint } from '../actions';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import delay from '@redux-saga/delay-p';
import { applayLocale } from '../../lib/moment';
import moment from '../../lib/moment';
import { errorHandlerSaga } from '../sagas';
import { Capacitor } from '@capacitor/core';

/**
 * Effect to handle authorization
 * @param { string } username The username of the user
 * @param { string } password The password of the user
 * @param { object } options Options
 * @param { boolean } options.isRegistering Is this a register request?
 */
export const authorize = function* (data) {
  const profile = yield select((state) => state.profile);
  const authStore = profile.auth;
  const token = data?.token;

  if (authStore.loggedIn && !token) {
    return authStore;
  }
  let returnValue = null;
  if (data.isRegistering) {
    data.isRegistering = undefined;
    const registerData = yield call(api.register, data);
    returnValue = registerData;
  } else if (token) {
    data.isRegistering = undefined;
    const loginData = yield call(api.loginWithToken, data.token);
    returnValue = loginData;
  } else {
    data.isRegistering = undefined;
    const { username, password } = data;
    const loginData = yield call(api.login, username, password);
    if ((getConfig().orderTesting && getConfig().orderTesting.allowedUsers.includes(loginData?.data?.profile?.ctcid)) || !getConfig().orderTesting) {
      yield call(asyncStorage.setItem, 'developer_debug_account', true);

    }
    returnValue = loginData;
  }

  return returnValue;
};

/* Login saga */
export const loginFlow = function* () {
  while (true) {
    const { username, password, userToken, referrer, showTutorialModal } = yield take(LOGIN_REQUEST);
    const store = yield select();

    yield call(loading, function* () {
      const response = yield call(authorize, { username, password, token: userToken, isRegistering: false });
      const { token, profile } = response.data;

      yield call(asyncStorage.setItem, 'token', token);
      yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile));

      yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } });
      yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile });
      yield call(saveFcmToken);

      const { navConfig } = yield select((state) => state.common);
      yield put({ type: CLEAR_GUEST_FORM });

      if (referrer) {
        forwardTo(referrer);
      } else {
        forwardTo('/homepage');
      }
      if (showTutorialModal) {
        yield put({ type: 'SET_COMMON_MODAL', modal: 'isTutorialModalOpen', value: true });
      }
    });
    yield call(postLogingFlow);
  }
};

export const restoreAuthSaga = function* () {
  const token = yield call(asyncStorage.getItem, 'token');
  const profile = yield call(asyncStorage.getItem, 'profile');

  if (isDefined(token) && isDefined(profile)) {
    yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } });
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: JSON.parse(profile) });
    yield call(api.createAxiosInstance, token);
    yield call(saveFcmToken);
    yield call(postLogingFlow);
  } else {
    yield call(api.createAxiosInstance);
  }
  return;
};

export const restoreAuthFlow = function* () {
  while (true) {
    yield take(RESTORE_AUTH);
    yield call(restoreAuthSaga);
  }
};

const periodicalSaga = function* () {
	while (true) {
		try {
			if ( isDefined(Basket.collection_time)) {
        Basket._isCollectionTimeStillValid()
        // const orderStartedTime = Basket.getOrderStartedTime();
				// if (orderStartedTime && isDefined(getConfig().general.basketTime)) {
        //   const currentTime = moment();
        //   const basketAvaibleTime = moment(orderStartedTime).add(getConfig().general.basketTime, 'minutes');
				// 	if (Basket.getOrderType() === 'Click & Collect' && currentTime.isAfter(basketAvaibleTime)) {
				// 		yield put({
				// 			type: SET_COMMON_MODAL,
				// 			modal: 'removeBasketItemsModalOpen',
				// 			value: {
				// 				showAlert: true
				// 			},
				// 		});
				// 	}
				// }
			}
			yield delay(getConfig().general.periodForSaga * 1000);
		} catch (e) {
			yield call(errorHandlerSaga, e);
		}
	}
};

const startPeriodicalSaga = function* () {
  // periodicalSaga is now at the same level as root saga
  const timer = yield spawn(periodicalSaga);
  const store = yield select();
  if (isDefined(store.profile.timer)) {
    yield cancel(store.profile.timer);
  }
  yield put({ type: SET_PROFILE_PROP, key: 'timer', value: timer });
};

const stopPeriodicalSaga = function* () {
  const store = yield select();
  if (isDefined(store.profile.timer)) {
    yield cancel(store.profile.timer);
  }
  yield put({ type: SET_PROFILE_PROP, key: 'timer', value: null });
};

const postLogingFlow = function* () {
  yield put({ type: GET_ORDER_HISTORY, loading: false });
  yield put({ type: GET_VOUCHERS });
  yield put({ type: GET_BALANCE });

  //add firebase listeners for database
  // yield put({ type: INIT_FIREBASE_DATABASE })

  // yield call(startPeriodicalSaga);
  yield call(showValidationPopUp);
  yield call(applayLocale);
  try {
    yield spawn(firebaseDatabase);
  } catch (e) {
    yield call(errorHandlerSaga, e);
  }
};

/* Register saga */
export const registerFlow = function* () {
  //const store = yield select()
  while (true) {
    yield take(REGISTER_REQUEST);

    yield call(loading, function* () {
      const profile = yield select((state) => state.profile);
      const { registerFormData, sysLocale, deviceId } = profile;
      const myLocation = yield select((state) => state.common.myLocation);
      const locale = getConfig().localization.defaultLocale;
      const data = {
        email: registerFormData.email,
        username: registerFormData.email,
        password: registerFormData.password,
        first_name: registerFormData.first_name,
        last_name: registerFormData.last_name,
        marketing_optin: registerFormData.marketing_optin || false,
        push_notifications: registerFormData.push_notifications || false,
        is_student: registerFormData.is_student || false,
        mobile: registerFormData.mobile,
        birthday: registerFormData.birthday,
        refer_code: registerFormData.referral_code || '',
        device_id: deviceId,
        // myLocation:
        //   myLocation.latitude !== null && myLocation.longitude !== null ? myLocation : undefined,
        // locale,
      };
      yield call(authorize, { ...data, isRegistering: true });
      yield put({ type: SEND_SMS, value: registerFormData.email });

      // const referrer = yield select((state) => state.profile.referrer);
      // yield put({ type: CLEAR_REGISTER_FORM });
      // //auto login after register
      // const { email, password } = data;
      // yield put({ type: LOGIN_REQUEST, username:  email, password: password, referrer });
    });
  }
};
export const sendSMSFlow = function* () {
  while (true) {
    const action = yield take(SEND_SMS);
    yield call(loading, function* () {
      const result = yield call(api.sendSMS, {
        email: action.value,
        web: Capacitor.getPlatform() === 'web',
      });
      yield call(forwardTo, '/send-code');
    });
  }
};
export const verifyCodeFlow = function* () {
  while (true) {
    const action = yield take(VERIFY_CODE);
    const profile = yield select((state) => state.profile);
    const result = yield call(api.verifyCode, action.data);

    yield call(loading, function* () {
      if (!result.error) {
        const isRegistered = yield call(
          pollingFlow,
          profile.registerFormData.email,
          profile.registerFormData.password,
        );
        if (isRegistered) {
          yield put({
            type: LOGIN_REQUEST,
            username: profile.registerFormData.email,
            password: profile.registerFormData.password,
            referrer: Capacitor.getPlatform() === 'web' ? "/order" : "/homepage",
            showTutorialModal: true,
          });
        }
      }
    });
  }
};

export const pollingFlow = async (username, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.login(username, password);
      return resolve(true);
    } catch (e) {
      setTimeout(() => {
        resolve(pollingFlow(username, password));
      }, 1500);
    }
  });
};

/* Log out saga */
export const logoutFlow = function* () {
  while (true) {
    const action = yield take(LOGOUT);
    yield call(asyncStorage.removeItem, 'token');
    yield call(asyncStorage.removeItem, 'profile');
    yield call(asyncStorage.removeItem, 'developer_debug_account');
    yield put({ type: SET_PROFILE_PROP, key: 'auth', value: {} });
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: {} });
    yield put({ type: SET_PROFILE_PROP, key: 'vouchers', value: [] });
    yield put({ type: SET_ORDERS_PROP, key: 'cards', value: [] });
    yield put({ type: SET_ORDERS_PROP, key: 'orderHistory', value: [] });

    yield put({ type: SET_PROTECTED_REFERRER, path: null });
    Basket.reset();

    //stop periodical profile fetching
    yield call(stopPeriodicalSaga);
    const { navConfig } = yield select((state) => state.common);
    forwardTo(getDefaultRoute(navConfig).path);

    if (isDefined(action.cb) && action.cb) {
      yield call(action.cb);
    }
  }
};

/* get Profile saga */
export const getProfileFlow = function* () {
  while (true) {
    const action = yield take(GET_PROFILE);
    const saga = function* () {
      const response = yield call(api.getProfile);
      const profile = response.data.profile;
      yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile });
      yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile));
      yield call(applayLocale);
    };
    if (action.skipLoading) {
      yield call(saga);
    } else {
      yield call(saga);
    }
  }
};

/* update Profile saga */
export const updateProfileFlow = function* () {
  while (true) {
    const action = yield take(UPDATE_PROFILE);
    yield call(loading, function* () {
      const response = yield call(api.updateProfile, action.data);
      const profile = response.data.profile;
      yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile, merge: true });
      yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile));
      yield put({ type: SET_MODAL, modal: 'isProfileUpdatedModalOpen', value: true });

      if (!isDefined(action.skipAlert) || !action.skipAlert) {
        yield put({ type: SET_MODAL, modal: 'isProfileModalOpen', value: true });
      }
    });
  }
};

/* reset Password saga */
export const resetPasswordFlow = function* () {
  while (true) {
    const action = yield take(RESET_PASSWORD);
    yield call(loading, function* () {
      const { phone, code, password } = action.data || {};
      if (phone, code, password) {
        yield call(api.resetPassword, phone, code, password);
        yield put({ type: SET_COMMON_PROP, key: 'passwordIsUpdatedModal', value: true });
        forwardTo('/dashboard');
      } else if (phone, code) {
        yield call(api.resetPassword, phone, code, password);
        yield put({ type: SET_COMMON_PROP, key: 'isResetPasswordCodeValid', value: true });
      } else {
        yield call(api.createResetPasswordCode, phone);
        yield put({ type: SET_COMMON_PROP, key: 'isResetPasswordPhoneValid', value: true });
      }
    });
  }
};

/* validte Email saga */
export const validateEmailFlow = function* () {
  while (true) {
    const action = yield take(VALIDATE_EMAIL);
    const validate_profile = action.validate_profile;
    yield put({ type: UPDATE_PROFILE, validate_profile, skipAlert: true });
    const result = yield call(api.validateEmail);
    yield put(showToast(yield call(translateSaga, result.data.data.success), 'success'));
  }
};

/* show validtion PopUp Flow saga */
export const showValidationPopUp = function* () {
  const profile = yield select((state) => state.profile.profile);
  const valid = validateProfileData(profile).isValid;
  if (
    getConfig().appType.hasEmailValidationEnabled &&
    valid &&
    isDefined(profile.is_verification_pop_up_shown) &&
    !profile.is_verification_pop_up_shown
  ) {
    yield put({ type: SET_COMMON_MODAL, modal: 'isValidationModalOpen', value: true });
  }
};

export const sendReferFlow = function* () {
  while (true) {
    const action = yield take(SEND_REFER);
    yield call(loading, function* () {
      const result = yield call(api.sendRefer, action.data);
      if (!result.error) {
        yield put(showToast(yield call(translateSaga, result.message), 'success'));
      }
    });
  }
};

export const sendVoucherCodeFlow = function* () {
  while (true) {
    const action = yield take(SEND_VOUCHER_CODE);
    yield call(loading, function* () {
      const result = yield call(api.sendCode, action.data);
      if (!result.is_gift) {
        yield put({ type: GET_VOUCHERS });

        // if (action.orderCreated) {
        //   yield put({
        //     type: CHECKOUT_DISCOUNT_VOUCHERS,
        //     data: Basket.parseBasketForCheckVouchers(),
        //   });
        // }

        if (!result.error && !result.Error) {
          yield put(showToast(yield call(translateSaga, 'Voucher added successfully'), 'success'));
        }
      } else {
        yield put({
          type: SET_COMMON_PROP,
          key: 'redeemGiftVoucherMessage',
          value: result.message,
        });
        yield put({
          type: SET_COMMON_MODAL,
          modal: 'isRedeemGiftVoucherModalOpen',
          value: true,
        });
      }
    });
  }
};

export const getVouchersFlow = function* () {
  while (true) {
    yield take(GET_VOUCHERS);
    const response = yield call(api.getVouchers);
    yield put({ type: SET_PROFILE_PROP, key: 'vouchers', value: response.vouchers });
  }
};

export const checkDiscountFlow = function* () {
  while (true) {
    const action = yield take(CHECKOUT_DISCOUNT_VOUCHERS);
    yield call(loading, function* () {
      yield put({
        type: SET_PROFILE_PROP,
        key: 'applicableVouchers',
        value: [],
      });
      const response = yield call(api.checkDiscount, action.data);
      if (!response.error) {
        yield put({
          type: SET_PROFILE_PROP,
          key: 'applicableVouchers',
          value: response.data.data,
        });

        const selectedVoucher = action.data.selectedVoucher;
        const vouchers = yield select((store) => store.profile.vouchers);
        const applicableVouchers = yield select((store) => store.profile.applicableVouchers);

        const appliedVoucher = vouchers.find((voucher) => voucher.VoucherID === selectedVoucher.VoucherID);
        const applicableVoucher = applicableVouchers.find(
          (applicableVoucher) => applicableVoucher.id === selectedVoucher.VoucherID,
        );
        if (applicableVoucher.cost > 0) {

          if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
            Basket.addVoucher(appliedVoucher, applicableVoucher);
            yield put({ type: SET_COMMON_PROP, key: 'voucherAddedAlertOpen', value: true });
            goBack();
          } else {
            yield put(
              showToast(
                yield call(
                  translateSaga,
                  'Your basket is higher then voucher cost. Please add more items in basket',
                ),
                'warning',
              ),
            );
          }
        }
        else {
          goBack();
          const errorMessage = response?.data?.data?.[0]?.error_message ?? null;
          yield put(showToast(errorMessage || getConfig().general.voucherRemovedFromBasketMessage, 'warning'));
        }
  
      }
    });
  }
};


export const removeVoucherFlow = function* () {
  while (true) {
    yield take(PROFILE_REMOVE_VOUCHER);
    if (getConfig().appType.hasCampaignManager) {
      // eslint-disable-next-line require-yield
      yield call(loading, function* () {
        Basket.clearAllDiscounts(false, true);
      });
    }
  }
};

export const getBalanceFlow = function* () {
  while (true) {
    yield take(GET_BALANCE);
    //get restaurants
    yield call(loading, function* () {
      let balance = yield call(api.getBalance);

      yield put({ type: SET_PROFILE_PROP, key: 'balance', value: balance.PointBalance });
    });
  }
};
export const deleteUserFlow = function* () {
  while (true) {
    const action = yield take(DELETE_USER);
    yield call(loading, function* () {
      yield put({ type: LOGOUT });
      let response = yield call(api.deleteUser, { token: action.token });
      response = response.data;
      if (response.error) {
        yield put(showToast(yield call(translateSaga, response.error), 'danger'));
      } else if (response.message) {
        yield put(showToast(yield call(translateSaga, response.message), 'success'));
      }
    });
  }
};
