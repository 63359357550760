import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { setCommonModal } from '../../store/actions';
import { IonAlert } from '@ionic/react';

class NoClientFoundModal extends React.Component {
  render() {
    const { __, dispatch, noClientFoundModalOpen } = this.props;
    return (
      <>
        <IonAlert
          isOpen={noClientFoundModalOpen}
          onDidDismiss={() => dispatch(setCommonModal('noClientFoundModalOpen', false))}
          header={__('Oh no!')}
          message="No Client Sessions Found"
          cssClass="no-table-alert one-red-button-alert"
          buttons={[
            {
              text: 'OK',
              cssClass: 'two-button-secondary',
            },
          ]}
        />
      </>
    );
  }
}

const stateToProps = (store) => {
  const { noClientFoundModalOpen } = store.common;
  return {
    noClientFoundModalOpen,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(NoClientFoundModal)));
