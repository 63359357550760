import {
  GET_TRANSACTION_HISTORY,
  IMPORT_BASKET,
  LIST_PAYMENT_CARDS,
  ADD_PAYMENT_CARD,
  REMOVE_PAYMENT_CARD,
  CREATE_ORDER,
  SET_SCROLL_TOP,
  ADD_SCANNED_CARD,
  SET_DELIVERY_OPTION,
  SET_DELIVERY_ADDRESS,
  SET_DELIVERY_TIME,
  SET_PAYMENT_TOKEN,
  ADD_DELIVERY_ADDRESS,
  SET_PICK_UP_POINT,
  POSTCODE_CHECK,
  SET_POSTCODE_DATA,
  GET_NEAREST_LOCATION,
  LOCATION_CODE_CHECK,
  SET_LOCATION_CODE_DATA,
  ADD_PICKUP_POINT,
  STORE_DELIVERY_ADDRESS,
  STORE_PICKUP_POINT,
  REMOVE_DELIVERY_ADDRESS,
  CHECK_CANCEL_ORDER,
  SET_ORDERS_PROP,
  STORE_ITEM_WEB,
  UPDATE_PAYMENT_INFO,
  SET_SELECTED_SUBSCRIPTION,
  SET_ORDER_TYPE,
  ADD_CARD_AND_CONTINUE,
  SEND_GIFT_VOUCHER,
  REDEEM_GIFT_VOUCHER,
  GET_SENT_GIFT_VOUCHERS,
  CLEAR_SENT_GIFT_VOUCHERS,
  JOIN_TABLE,
  GET_MY_TABLE,
  GET_MYCHECK_URL,
  GET_TABLE_APPLIED_VOUCHERS,
  LEAVE_TABLE,
  ACCEPT_USER,
  APPLY_VOUCHER,
  REMOVE_VOUCHER,
  DISMISS_USER,
  VALIDATE_VOUCHER,
  REDEEM_FEEDBACK_CODE,
  GET_ACCESS_TOKEN,
  SET_USER_CARDS,
  SET_ACCESS_TOKEN,
  GLOBALPAY_ORDER_PAYMENT,
  SET_GLOBALPAY_AUT,
  CHECK_ORDER,
  RELEASE_VOUCHER,
  SET_CARD_TOKEN,
  GET_CARD_TOKEN,
} from './constants';

export const sendGiftVoucher = (data) => ({ type: SEND_GIFT_VOUCHER, data });

export const redeemGiftVoucher = (data) => ({ type: REDEEM_GIFT_VOUCHER, data });

export const getSentGiftVouchers = () => ({ type: GET_SENT_GIFT_VOUCHERS });

export const clearSentGiftVouchers = () => ({ type: CLEAR_SENT_GIFT_VOUCHERS });

export const getTransactionHistory = () => ({ type: GET_TRANSACTION_HISTORY });

export const importBasket = () => ({ type: IMPORT_BASKET });

export const getPaymentCards = () => ({ type: LIST_PAYMENT_CARDS });

export const addPaymentCard = (name, options = {}) => ({ type: ADD_PAYMENT_CARD, name, options });

export const removePaymentCard = (cardData, options = {}) => ({
  type: REMOVE_PAYMENT_CARD,
  cardData,
  options,
});

export const createOrder = (paymentType = null, paymentWebType, cb) => ({
  type: CREATE_ORDER,
  paymentType,
  paymentWebType,
  cb,
});

export const setScrollTop = (value = 0) => ({ type: SET_SCROLL_TOP, value });

export const addScannedCard = (name = '', payment_token = '', options = {}) => ({
  type: ADD_SCANNED_CARD,
  name,
  options,
  payment_token,
});

export const setDeliveryOption = (deliveryOption) => ({
  type: SET_DELIVERY_OPTION,
  deliveryOption,
});

export const setDeliveryAddress = (deliveryAddress) => ({
  type: SET_DELIVERY_ADDRESS,
  deliveryAddress,
});

export const setDeliveryTime = (deliveryTime) => ({ type: SET_DELIVERY_TIME, deliveryTime });

export const setPickUpPoint = (pickUpPoint) => ({ type: SET_PICK_UP_POINT, pickUpPoint });

export const setPaymentToken = (token) => ({ type: SET_PAYMENT_TOKEN, token });

export const addDeliveryAddress = (deliveryAddress, flag) => ({
  type: ADD_DELIVERY_ADDRESS,
  deliveryAddress,
  flag,
});

export const postCodeCheck = (postcode) => ({ type: POSTCODE_CHECK, postcode });

export const setPostCodeData = (checkedCodeData) => ({ type: SET_POSTCODE_DATA, checkedCodeData });

export const getNearestLocation = (postcode, charter_delivery) => ({
  type: GET_NEAREST_LOCATION,
  postcode,
  charter_delivery,
});
export const locationCodeCheck = (locationCode) => ({ type: LOCATION_CODE_CHECK, locationCode });

export const setLocationCodeData = (checkedLocationCodeData) => ({
  type: SET_LOCATION_CODE_DATA,
  checkedLocationCodeData,
});

export const addPickupPoint = (pickUpPoint, code) => ({
  type: ADD_PICKUP_POINT,
  pickUpPoint,
  code,
});

export const storeDeliveryAddress = (deliveryAddress) => ({
  type: STORE_DELIVERY_ADDRESS,
  deliveryAddress,
});

export const storePickUpPoint = (pickUpPoint) => ({ type: STORE_PICKUP_POINT, pickUpPoint });

export const removeDeliveryAddress = (index, order_type) => ({
  type: REMOVE_DELIVERY_ADDRESS,
  index,
  order_type,
});

export const checkCancelOrder = (orderId, restaurantId) => ({
  type: CHECK_CANCEL_ORDER,
  orderId,
  restaurantId,
});

export const setOrdersProp = (key, value) => ({ type: SET_ORDERS_PROP, key, value });

export const storeItemWeb = (item, cb = null) => ({ type: STORE_ITEM_WEB, item, cb });

export const updatePaymentInfo = (paymentInfo) => ({ type: UPDATE_PAYMENT_INFO, paymentInfo });

export const setSelectedSubscription = (subscription) => ({
  type: SET_SELECTED_SUBSCRIPTION,
  value: subscription,
});

export const setOrderTypeMembership = () => ({ type: SET_ORDER_TYPE, value: 'membership' });

export const setOrderTypeRegular = () => ({ type: SET_ORDER_TYPE, value: 'regular' });

export const addCardAndContinue = (name = '', payment_token = '', options = {}) => ({
  type: ADD_CARD_AND_CONTINUE,
  name,
  options,
  payment_token,
});

export const joinTable = (data) => ({ type: JOIN_TABLE, data });

export const getMyTable = (fromHomepage, cb, disableRedirect) => ({
  type: GET_MY_TABLE,
  fromHomepage,
  cb,
  disableRedirect,
});
export const getTableAppliedVouchers = (data) => ({ type: GET_TABLE_APPLIED_VOUCHERS, data });
export const leaveTable = (tableId) => ({ type: LEAVE_TABLE, tableId });

export const getMycheckUrl = () => ({ type: GET_MYCHECK_URL });
export const acceptUser = (data) => ({ type: ACCEPT_USER, data });
export const dismissUser = (data) => ({ type: DISMISS_USER, data });

export const applyVoucher = (data) => ({ type: APPLY_VOUCHER, data });
export const removeVoucher = (data) => ({ type: REMOVE_VOUCHER, data });

export const validateVoucher = (data) => ({ type: VALIDATE_VOUCHER, data });
export const globalpayOrderPayment = (data) => ({ type: GLOBALPAY_ORDER_PAYMENT, data })
export const redeemFeedbackCode = (feedback_code) => ({
  type: REDEEM_FEEDBACK_CODE,
  feedback_code,
});

export const getAccessToken = () => ({
  type: GET_ACCESS_TOKEN,
})

export const setAccessToken = (token) => ({
  type: SET_ACCESS_TOKEN,
  token
})
export const getCardToken = () => ({
  type: GET_CARD_TOKEN
})
export const setCardToken = (token) => ({
  type: SET_CARD_TOKEN,
  token
})
export const setGlobalpayAut = (aut) => ({
  type: SET_GLOBALPAY_AUT,
  aut
})
export const setUserCards = (cards) => ({
  type: SET_USER_CARDS,
  cards
})

export const checkOrder = (data) => ({
  type: CHECK_ORDER,
  data
})
export const releaseVoucher = (data) => ({
  type: RELEASE_VOUCHER,
  data
})