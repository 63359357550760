import React, { Component } from 'react';
import { IonList, IonItem, IonIcon, IonButton } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { forwardTo } from '../../lib/utils';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import { addCircleOutline } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import Modal from '../../components/modal';

class TileOrderList extends Component {
  constructor(props) {
    super(props);
    this.ionList = null;
    this.orderListItems = null;
    this.state = {
      isChooseSizeModalOpen: false,
      selectedItem: null,
    };
  }
  componentDidMount() {
    const item = document.querySelector('.order-list-items');
    const itemList = document.querySelector('.order-list-items > ion-list');
    if (item && itemList) {
      this.ionList = itemList;
    }
  }

  itemList = (__, item, index) => {
    const { profile } = this.props;
    if (item !== null) {
      let image =
        item.itemRichData && item.itemRichData.squareImageUrl
          ? item.itemRichData.squareImageUrl
          : '';
      if (image && image.indexOf('http://') !== -1) {
        image = image.replace(/http:\/\//g, 'https://');
      }
      item.selectedMenu = this.props.selectedMenu || [];
      return (
        <IonItem
          className={!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''}
          key={index}
          lines="none"
          onClick={() =>
            Basket.isProductUnsnoozed(item)
              ? item.hasLarge
                ? this.setState({ isChooseSizeModalOpen: true, selectedItem: item })
                : forwardTo('/item-details', item)
              : null
          }
        >
          <div tabIndex="-1" className="order-list-item grid-order-list-item">
            <div className="item-price">
              <NormalText className='secondary-font dark-color'>{Basket.formatPrice(item.productPrice)}</NormalText>
              {item.originalPrice ? (
                <>
                  <br />
                  <NormalText className="secondary-font dark-color original-price">&#163;{item.originalPrice}</NormalText>
                </>
              ) : null}
            </div>
            <div className="item-content">
              <SmallText className='dark-color'>{__(Basket.getProductName(item, profile))}</SmallText>
              <div
                dangerouslySetInnerHTML={{
                  __html: Basket.getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                }}
              />
            </div>

            <div className="item-image-wrapper">
              <div
                className="item-image"
                style={image && image !== '' ? { backgroundImage: `url(${image})` } : {}}
              />
            </div>
          </div>
        </IonItem>
      );
    }
  };
  selectItem = (data) => {
    const { large } = data;
    const item = JSON.parse(JSON.stringify(this.state.selectedItem));
    if (large) {
      item.sku = item.largeSku;
      item.productPrice = item.largePrice;
    }
    forwardTo('/item-details', item);
    this.setState({ isChooseSizeModalOpen: false, selectItem: null });
  };
  setBackgroundImage = (category) => {
    if (category) {
      if (!category.menuEntry) {
        let image = category.itemRichData?.rawImageUrl;
        if (image && image.indexOf('http://') !== -1) {
          image = image.replace(/http:\/\//g, 'https://');
        }
        return { backgroundImage: `url(${image})` };
      }
      const item = category.menuEntry.find((el) => el.itemRichData && el.itemRichData.rawImageUrl);
      if (item) {
        let image = item.itemRichData.rawImageUrl;
        if (image && image.indexOf('http://') !== -1) {
          image = image.replace(/http:\/\//g, 'https://');
        }
        return { backgroundImage: `url(${image})` };
      }
      return this.setBackgroundImage(category.menuEntry[0]);
    }
  };

  drawTiles(__, data) {
    const categories = data.menuEntry || data.menuEntryGroups || [];
    return categories.map((el, index) => {
      if (el['@type'] === 'group') {
        return (
          <div
            className={
              getConfig().menuDisplayType == 3
                ? 'order-categories-two-columns-tiles-wrapper'
                : 'order-categories-tiles-wrapper'
            }
            key={index}
          >
            <div
              className="order-categories-tile"
              style={this.setBackgroundImage(el)}
              onClick={(e) => {
                this.props.selectMenu(index);
                this.ionList.parentElement.parentElement.scrollIntoView();
              }}
            >
              <span className="order-categories-tile-title">{el?.name.toLowerCase()}</span>
            </div>
          </div>
        );
      } else {
        return this.itemList(__, el, index);
      }
    });
  }

  render() {
    const { __, category } = this.props;

    return (
      <>
        <div className="order-list-items" id="order-list-items">
          <p className="category-title">{category.name || null}</p>
          <IonList lines="none">{this.drawTiles(__, category)}</IonList>
        </div>
        <Modal
          className="choose-size-modal"
          isOpen={this.state.isChooseSizeModalOpen}
          onDidDismiss={() => this.setState({ isChooseSizeModalOpen: false })}
        >
          <div>
            <Title>{__('Go Large?')}</Title>
            <Spacer size={1} />
            <IonButton
              onClick={() => this.selectItem({ large: false })}
              expand="block"
              color="secondary"
            >
              <div>
                <NormalText>{__('Regular')}</NormalText>
                <NormalText>{Basket.formatPrice(this.state.selectedItem?.productPrice)}</NormalText>
              </div>
            </IonButton>
            <Spacer size={1} />
            <IonButton
              onClick={() => this.selectItem({ large: true })}
              expand="block"
              color="secondary"
            >
              <div>
                <NormalText>{__('Large')}</NormalText>
                <NormalText>{Basket.formatPrice(this.state.selectedItem?.largePrice)}</NormalText>
              </div>
            </IonButton>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
  };
};

export default connect(mapStateToProps)(withTranslation(TileOrderList));
