import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonItem, IonInput, IonList, IonAlert, IonIcon } from '@ionic/react';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import { NormalText, StrongText } from '../../components/common';
import { forwardTo, goBack, isEmptyObject } from '../../lib/utils';
// import moment from '../../lib/moment'
import { withTranslation } from '../../lib/translate';
import {
  deleteUser,
  getMyTable,
  getProfile,
  getTermsAndConditions,
  logout,
  updateProfile,
} from '../../store/actions';
import './index.css';
import defaultImg from '../../assets/images/gray-avatar.png';
import * as icons from 'ionicons/icons';
import SwipeableTabs from '../../components/swipeableTabs';
import { SET_MODAL } from '../../store/constants';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      email: this.props.profile.email || '',
      mobile: this.props.profile.mobile || '',
      birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
      imageFile: null,
      is_subscribed: this.props.profile.is_subscribed || false,
      formErrors: {},
      deleteAvatarImageAlert: false,
      locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      deleteAccountModalOpen: false,
      changePasswordSelected: false,
      newPassword: '',
      showTerms: false,
      tableIsOpenModal: false,
    };
    this.formConfig = {
      email: { type: 'email', required: false },
      mobile: { type: 'tel', required: false },
      locale: { type: 'text', required: false },
    };
    this.inputRef = React.createRef();
  }
  componentDidMount() {
    const { tableData } = this.props;
    this.props.dispatch(getProfile());
    if (isEmptyObject(tableData)) {
      this.props.dispatch(getMyTable(true, null, true));
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.email !== prevProps.profile.email) {
      this.setState({ email: this.props.profile.email });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
    if (this.props.profile.birthday !== prevProps.profile.birthday) {
      this.setState({ birthday: this.props.profile.birthday ? this.props.profile.birthday : '' });
    }
    if (this.props.profile.locale !== prevProps.profile.locale) {
      this.setState({
        locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      });
    }
    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
    if (this.props.profile.is_subscribed !== prevProps.profile.is_subscribed) {
      this.setState({ is_subscribed: this.props.profile.is_subscribed });
    }
  }

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.addCardFromAccount
    ) {
      forwardTo('/dashboard');
    } else {
      goBack();
    }
  };
  getTermsPage = () => {
    this.props.dispatch(getTermsAndConditions());
    this.setState({ showTerms: true });
  };
  onTabChange = (tab) => {
    if (tab == 0) {
      this.setState({ showTerms: false });
    }
  };
  changePassword = () => {
    if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,50}$/.test(this.state.newPassword)) {
      const newProfile = this.props.profile;
      newProfile.password = this.state.newPassword;
      this.props.dispatch(updateProfile(newProfile, true));
    }
  };
  render() {
    const { __, profile, isProfileUpdatedModalOpen, tableData } = this.props;
    const {
      first_name,
      last_name,
      email,
      mobile,
      deleteAccountModalOpen,
      changePasswordSelected,
      newPassword,
      tableIsOpenModal,
    } = this.state;
    return (
      <>
        <Layout
          showHeader
          showFooter
          color="transparent"
          headerTitle="My Account"
          hideBack={true}
          hideSecondToolbar={true}
        >
          <SwipeableTabs
            onTabChange={(value) => this.onTabChange(value)}
            {...(this.props?.location?.state?.toSettings ||
              (this.state.showTerms && { defaultTab: 1 }))}
            tabs={[
              {
                label: __('Profile'),
                tabContent: (
                  <div className="absolute-content">
                    <IonList className="account-inputs">
                      <div className="input-field-container">
                        <NormalText>{__('First Name')}</NormalText>
                        <IonItem lines="none" className="">
                          <StrongText>{first_name}</StrongText>
                        </IonItem>
                      </div>
                      <div className="input-field-container">
                        <NormalText>{__('Last Name')}</NormalText>
                        <IonItem lines="none" className="input-field-wrapper no-border">
                          <StrongText>{last_name}</StrongText>
                        </IonItem>
                      </div>

                      <div className="input-field-container">
                        <NormalText>{__('Email')}</NormalText>
                        <IonItem lines="none" className="input-field-wrapper no-border">
                          <StrongText>{email}</StrongText>
                        </IonItem>
                      </div>
                      <div className="input-field-container">
                        <NormalText>{__('Phone Number')}</NormalText>
                        <IonItem lines="none" className="input-field-wrapper no-border">
                          <StrongText>{mobile}</StrongText>
                        </IonItem>
                      </div>
                      <div className="input-field-container ">
                        <NormalText>{__('Password')}</NormalText>
                        <IonItem
                          lines="none"
                          className={`input-field-wrapper ${
                            changePasswordSelected ? '' : 'no-border'
                          }`}
                        >
                          {changePasswordSelected ? (
                            <IonInput
                              type="password"
                              pattern="password"
                              inputmode="password"
                              value={newPassword}
                              onIonChange={(e) => this.setState({ newPassword: e.target.value })}
                            ></IonInput>
                          ) : (
                            <StrongText>****************</StrongText>
                          )}
                        </IonItem>
                        {changePasswordSelected && (
                          <StrongText>
                            {__(
                              ' Your password must have at least 8 characters including 1 number, 1 upper case and 1 lower case letter.',
                            )}
                          </StrongText>
                        )}
                      </div>
                    </IonList>
                    <div className="account-actions">
                      {changePasswordSelected ? (
                        <>
                          {newPassword.length >= 8 ? (
                            <NormalText onClick={() => this.changePassword()} className="uppercase">
                              {__('Save Changes')}
                            </NormalText>
                          ) : (
                            <NormalText
                              onClick={() => this.setState({ changePasswordSelected: false })}
                              className="uppercase"
                            >
                              {__('Cancel Changes')}
                            </NormalText>
                          )}
                        </>
                      ) : (
                        <NormalText
                          onClick={() => this.setState({ changePasswordSelected: true })}
                          className="uppercase"
                        >
                          {__('Change Password')}
                        </NormalText>
                      )}
                      <NormalText
                        onClick={() => this.props.dispatch(logout())}
                        className="uppercase"
                      >
                        {__('Log Out')}
                      </NormalText>
                      <NormalText
                        onClick={() => {
                          if (isEmptyObject(tableData)) {
                            this.setState({ deleteAccountModalOpen: true });
                          } else {
                            this.setState({ tableIsOpenModal: true });
                          }
                        }}
                        className="uppercase"
                      >
                        {__('Delete Account')}
                      </NormalText>
                      <NormalText size="small" slot="start" color="white"></NormalText>
                    </div>
                    <IonItem lines="none" className="input-field-wrapper no-border app-version">
                      <StrongText>{`v${this.props.appVersion}`}</StrongText>
                    </IonItem>
                  </div>
                ),
              },
              {
                label: __('Settings'),
                tabContent: (
                  <div className="absolute-content">
                    {!this.state.showTerms ? (
                      <>
                        <div
                          className="arrow-button"
                          onClick={() => {
                            forwardTo('/marketing-preferences');
                          }}
                        >
                          <NormalText className="uppercase">
                            {__('Marketing Preferences')}
                          </NormalText>
                          <IonIcon icon={icons.chevronForward}></IonIcon>
                        </div>
                        <div className="arrow-button" onClick={() => this.getTermsPage()}>
                          <NormalText className="uppercase">{__('Terms & Conditions')}</NormalText>
                          <IonIcon icon={icons.chevronForward}></IonIcon>
                        </div>
                      </>
                    ) : (
                      <>
                        <NormalText
                          onClick={() => this.setState({ showTerms: false })}
                          className="pointer block righted uppercase"
                        >
                          {__('GO BACK')}
                        </NormalText>
                        <div dangerouslySetInnerHTML={{ __html: this.props.terms }}></div>
                      </>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </Layout>
        <IonAlert
          cssClass="two-button-alert"
          isOpen={deleteAccountModalOpen}
          onDidDismiss={() => this.setState({ deleteAccountModalOpen: false })}
          message={__('Any active stamps & rewards will be removed')}
          subHeader={__('Your app account will be permanently deleted')}
          buttons={[
            {
              text: __('Cancel'),
              cssClass: 'two-button-secondary',
            },
            {
              text: __('Delete my account'),
              cssClass: 'two-button-gray',
              handler: () => this.props.dispatch(deleteUser(profile.user_token)),
            },
          ]}
        />
        <IonAlert
          cssClass="no-table-alert one-red-button-alert"
          isOpen={isProfileUpdatedModalOpen === true}
          onDidDismiss={() =>
            this.props.dispatch({
              type: SET_MODAL,
              modal: 'isProfileUpdatedModalOpen',
              value: false,
            })
          }
          message={__('Profile updated successfully')}
          header={__('Success')}
          buttons={[
            {
              text: __('OK'),
              cssClass: 'secondary',
              handler: () => this.props.dispatch({ type: SET_MODAL, modal: 'isProfileUpdatedModalOpen', value: false }),
            },
          ]}
        />
        <IonAlert
          cssClass="no-table-alert one-red-button-alert"
          isOpen={tableIsOpenModal}
          onDidDismiss={() => this.setState({ tableIsOpenModal: false })}
          message={__('Please leave your table in order to delete your account')}
          buttons={[
            {
              text: __('OK'),
              cssClass: 'secondary',
            },
          ]}
        />
      </>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, isProfileModalOpen, isProfileUpdatedModalOpen } = state.profile;
  const { isShowTimePicker } = state.restaurants;
  const { terms, appVersion } = state.common;
  const { tableData } = state.orders;
  return {
    auth,
    profile,
    isProfileModalOpen,
    isShowTimePicker,
    terms,
    appVersion,
    isProfileUpdatedModalOpen: isProfileUpdatedModalOpen || false,
    tableData,
  };
};

export default connect(stateToProps)(withTranslation(Account));
