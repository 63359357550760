import React from 'react';
import { IonButton, IonInput, IonItem } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Spacer, NormalText, Subtitle, SmallText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { connect } from 'react-redux';
import TermsModal from '../../components/termsModal';
import { getConfig } from '../../appConfig';
import { Share } from '@capacitor/share';

class ReferAFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      formErrors: {},
      showTerms: false,
    };
    this.formConfig = {
      email: { type: 'email', required: true },
    };
  }

  render() {
    const { __, profile } = this.props;
    const { showTerms } = this.state;
    const { shareConfig } = getConfig();
    return (
      <Loading transparent>
        <Layout
          showHeader
          showFooter
          color="transparent"
          headerTitle={__('Refer a Friend')}
          hideBack={true}
          hideSecondToolbar={true}
        >
          <Subtitle>{__('PIZZA LOVES COMPANY')}</Subtitle>
          <Spacer />
          <SmallText className="uppercase">
            {__(
              'For every friend who downloads the app, registers with your reference code below and buys a pizza, you will both receive 1 bonus stamp',
            )}
          </SmallText>
          <Spacer />
          <Subtitle>{__('Share this code with a friend')}</Subtitle>
          <Spacer size={1} />
          <IonItem lines="none" className="">
            <IonInput
              className="refer-fiend-code uppercase"
              placeholder={__('Code')}
              type="text"
              pattern="text"
              inputmode="text"
              value={profile.referrer_code}
              disabled={true}
            ></IonInput>
          </IonItem>
          <NormalText
            onClick={() => this.setState({ showTerms: true })}
            className="underlined link-color blocked-input"
          >
            {__('T&CS')}
          </NormalText>
          <IonButton
            expand="block"
            color="secondary"
            strong={true}
            onClick={async () =>
              await Share.share({
                title: __(shareConfig?.shareTitle || ''),
                text: `${__(shareConfig?.shareText || '')} ${__(
                  profile?.referrer_code ? profile.referrer_code : '',
                )}`,
                dialogTitle: __(shareConfig?.shareDialogTitle || ''),
                url: `${shareConfig?.shareUrl || '    '}`,
              })
            }
          >
            {__(shareConfig?.shareButtonText || '')}
          </IonButton>
          <Spacer size={1} />
          <SmallText color="gray" className="uppercase">
            {__('You can refer up to 10 friends per month')}
          </SmallText>
        </Layout>
        <TermsModal isOpen={showTerms} onDidDismiss={() => this.setState({ showTerms: false })} />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    profile: state.profile.profile,
  };
};

export default connect(stateToProps)(withTranslation(ReferAFriend));
