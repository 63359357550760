import { isWeb, isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import CheckTable from './screens/orderToTable';
import ApplyVouchers from './screens/applyVouchers';
import ApplyAllergens from './screens/allergens';
import { ServiceCharge } from './screens/service-charge';
import GiftVouchers from './screens/giftVouchers';
import Restaurants from './screens/restaurants';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import ApplyLoyalty from './screens/applyLoyalty';
import CreateAccount from './screens/createAccount';
import api from './lib/api';
import Homepage from './screens/homepage';
import SendCode from './screens/sendCode';
import MarketingPreferences from './screens/marketingPreferences';
import AddStamps from './screens/addStamps';
import students from './screens/students';
import YourTable from './screens/yourTable';
import QRScanner from './screens/qrScanner';
import loyalty from './assets/images/icons/loyalty.svg';
import myAccount from './assets/images/icons/account.svg';
import feedback from './assets/images/icons/feedback.svg';
import history from './assets/images/icons/history.svg';
import restaurants from './assets/images/icons/locations.svg';
import social from './assets/images/icons/social-media.svg';
import order from './assets/images/icons/order.svg';
import gift from './assets/images/icons/gift.svg';
import login from './assets/images/icons/login.svg';
import logout from './assets/images/icons/logout.svg';
import settings from './assets/images/icons/refer.svg'; //'./assets/images/settings-icon.svg';
import referAFriend from './assets/images/icons/refer.svg';

import qm from './assets/images/icons/qm.svg';
import menu from './assets/images/icons/menu.svg';
import offline from './screens/offline';
import PreviousOrders from './screens/previousOrders';
import PaymentResultView from './screens/paymentResultView';
import NativeCheckout from './screens/nativeCheckout';
import NativeCardAdd from './screens/nativeCardAdd';
import NativePaymentResultView from './screens/nativePaymentResultView';
import NativeOrderCompleted from './screens/nativeOrderCompleted';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;
const { appType } = getConfig();
const { hasCampaignManager, hasOrdering, hasLoyalty } = appType;

let navConfig;
export const initRouter = async () => {
  //const config = await api.getFrontEndAppConfig();
  const singleDeliveryOption = getSingleDeliveryOption();

  navConfig = {
    routes: [
      isWebConfig()
        ? []
        : {
          label: 'Homepage',
          path: '/homepage',
          component: Homepage,
          icon: menu,
          exact: true,
          default: true,
          protected: true,
          group: 'button',
        },

      ...(hasOrdering
        ? [
          {
            label: 'Menu',
            path: '/order',
            component: Order,
            icon: menu,
            exact: true,
            default: isWebConfig() && hasOrdering,
            group: 'button',
          },
        ]
        : []),
      {
        label: 'Offline',
        path: '/offline',
        component: offline,
        exact: true,
        default: false,
        protected: false,
      },
      {
        label: 'Marketing Preferences',
        path: '/marketing-preferences',
        component: MarketingPreferences,
        icon: menu,
        exact: true,
        protected: true,
        group: 'button',
      },
      {
        label: 'Students',
        path: '/students',
        component: students,
        icon: menu,
        protected: true,
        group: 1,
      },
      {
        label: 'Apply Loyalty',
        path: '/apply-loyalty',
        component: ApplyLoyalty,
        icon: menu,
        protected: true,
        notInDrawer: true,
        group: 1,
      },
      // ...(true
      //   ? [
      //     {
      //       label: 'Gift Vouchers',
      //       path: '/gift-vouchers',
      //       component: GiftVouchers,
      //       icon: gift,
      //       protected: false,
      //       group: 1,
      //     },
      //   ]
      //   : []),
      ...(hasOrdering
        ? [
          {
            label: 'Start New Order',
            path: '/delivery-options',
            component: DeliveryOptions,
            icon: order,
            protected: false,
            notInDrawer: !!singleDeliveryOption,
          },
        ]
        : []),
      ...(hasOrdering
        ? [
          {
            label: singleDeliveryOption ? singleDeliveryOption.label : '',
            path: '/click-and-collect',
            component: ClickAndCollect,
            icon: settings,
            protected: false,
            notInDrawer: !singleDeliveryOption,
          },
        ]
        : []),
      // ...(hasLoyalty
      //   ? [
      //     {
      //       label: 'Loyalty',
      //       path: '/loyalty',
      //       component: Loyalty,
      //       icon: loyalty,
      //       protected: true,
      //       state: { tab: 'points' },
      //       group: 1,
      //     },
      //   ]
      //   : []),
      // ...(hasCampaignManager
      //   ? [
      //     {
      //       label: 'Vouchers',
      //       path: '/vouchers',
      //       component: Vouchers,
      //       icon: vocuher,
      //       protected: true,
      //       group:1
      //     },
      //   ]
      //   : []),
      ...(hasCampaignManager
        ? [
          {
            label: 'Refer a Friend',
            path: '/refer-a-friend',
            component: ReferAFriend,
            icon: referAFriend,
            protected: true,
            group: 1,
          },
        ]
        : []),
      {
        label: 'My Account',
        path: '/account',
        component: Account,
        icon: myAccount,
        protected: true,
        group: 3,
      },
      {
        label: 'Restaurants',
        path: '/restaurants',
        component: Restaurants,
        icon: myAccount,
        protected: false,
        notInDrawer: true,
      },
      {
        label: 'Send SMS',
        path: '/send-code',
        component: SendCode,
        icon: myAccount,
        protected: false,
        notInDrawer: true,
      },
      ...(hasOrdering
        ? [
          {
            label: 'Checkout',
            path: '/checkout',
            component: Checkout,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
        ...((hasOrdering && isWeb())
          ? [
            {
              label: 'Checkout',
              path: '/native-checkout',
              component: NativeCheckout,
              icon: settings,
              notInDrawer: true,
            },
            {
              label: 'Checkout',
              path: '/native-card-add',
              component: NativeCardAdd,
              icon: settings,
              notInDrawer: true,
            },
          ]
          : []),
      ...(hasOrdering
        ? [
          {
            label: 'Apply Points',
            path: '/apply-points',
            component: ApplyPoints,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      ...(hasOrdering
        ? [
          {
            label: 'Apply Points',
            path: '/allergens',
            component: ApplyAllergens,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      // ...(hasOrdering
      //   ? [
      //     {
      //       label: 'Service Charge',
      //       path: '/service-charge',
      //       component: ServiceCharge,
      //       notInDrawer: true,
      //       protected: false,
      //     },
      //   ]
      //   : []),
      ...(hasOrdering
        ? [
          {
            label: 'Apply Points',
            path: '/allergens',
            component: ApplyAllergens,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      {
        label: 'History',
        path: '/history',
        component: History,
        icon: history,
        protected: true,
        group: 3,
      },
      {
        label: 'Previous orders',
        path: '/previous-orders',
        component: PreviousOrders,
        notInDrawer:true,
        protected: true,

      },
      {
        label: 'Leave Feedback',
        path: '/feedback',
        component: Feedback,
        icon: feedback,
        protected: true,
        group: 3,
      },
      // {
      //   label: 'Locations',
      //   path: '/locations',
      //   component: Locations,
      //   icon: restaurants,
      //   protected: false,
      //   group: 4,
      // },
      // {
      //   label: 'Social Media',
      //   path: '/social',
      //   component: Social,
      //   icon: social,
      //   protected: false,
      //   group: 4,
      // },
      {
        label: 'Faq & Support',
        path: '/faq',
        component: Faq,
        icon: qm,
        protected: false,
        group: 4,
      },

      // {
      //   label: 'Contact Details',
      //   path: '/contact-details',
      //   component: ContactDetails,
      //   protected: true,
      //   notInDrawer: true,
      // },
      {
        label: 'Apply Vouchers',
        path: '/apply-vouchers',
        component: ApplyVouchers,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Add Stamps',
        path: '/add-stamps',
        component: AddStamps,
        notInDrawer: true,
        protected: true,
      },
      ...(isWebConfig() ? [] : [

        {
          label: 'Your Table',
          path: '/table',
          component: YourTable,
          notInDrawer: true,
          protected: true,
        },
        {
          label: 'Check',
          path: '/check-table',
          component: CheckTable,
          protected: false,
          notInDrawer: true,
        },
        {
          label: 'Scan',
          path: '/scan',
          component: QRScanner,
          protected: false,
          notInDrawer: true,
        },
      ]),
    ],
    authRoutes: [
      {
        label: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        icon: menu,
        exact: true,
        group: 'button',
      },
      { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
      { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
      { path: '/contact-details', component: ContactDetails },
    ],
    additionalRoutes: [
      { label: 'Terms & Conditions', path: '/terms', component: Terms },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy },
    ],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/reset-password', component: ResetPassword },
      { path: '/create-account', component: CreateAccount },

      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      // ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
      ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
      ...(hasOrdering ? [{ path: '/payment-result-view', component: PaymentResultView }] : []),
      ...(hasOrdering ? [{ path: '/native-payment-result-view', component: NativePaymentResultView }] : []),

      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/native-order-completed', component: NativeOrderCompleted }] : []),
      // ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      // ...(hasOrdering
      //   ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }]
      //   : []),
      // ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      // ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
      // ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
      // ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
    ],
  };
  return navConfig;
};

export default navConfig;
