import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import { Spacer, Title } from '../../components/common';
import { IonSpinner } from '@ionic/react';

class NativeOrderCompleted extends Component {

  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout
          showHeader
          disableHeader
          color="transparent"
          hideBack={true}
          hideSecondToolbar={true}
        >

          {!this.props.paymentProcessCompleted ? <div className="content-spinner">
            <IonSpinner color="primary" name="bubbles" />
          </div> : < div style={{ textAlign: 'center' }}>
            <Spacer />
            <Title className='secondary-font dark-color'>{__('You can now close this page.')}</Title>
          </div>}
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    paymentProcessCompleted: state.common.paymentProcessCompleted
  };
};

export default connect(stateToProps)(withTranslation(NativeOrderCompleted));
