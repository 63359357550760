import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { INIT } from '../../store/constants';
import { withTranslation } from '../../lib/translate';
import { IonButton } from '@ionic/react';
import Layout from '../../components/layout';
import swirl from '../../assets/images/swirl.gif';
import './index.css';

class Offline extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { __ } = this.props;
    return (
      <Layout showHeader showFooter={false} color="transparent" noPadding={true}>
        <div className="absolute-content scrollable-y">
          <img src={swirl} />
          <div className="offline-banner">
            <IonButton className="uppercase" color={'secondary'}>
              {__('oops! you are offline.')}
            </IonButton>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    isConnectedToNetwork: state.common.isConnectedToNetwork,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Offline)));
