import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { setCommonModal } from '../../store/actions';
import { IonAlert } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import './index.css';

class NoTableModal extends React.Component {
  render() {
    const { __, dispatch, noTableModalOpen } = this.props;
    return (
      <>
        <IonAlert
          isOpen={noTableModalOpen}
          onDidDismiss={() => dispatch(setCommonModal('noTableModalOpen', false))}
          header={__('Oh no!')}
          message="You have to check in first to view your table."
          cssClass="no-table-alert two-button-alert"
          color="secondary"
          buttons={[
            {
              cssClass: 'two-button-secondary',
              text: 'Check in now',
              role: 'confirm',
              handler: () => forwardTo('/scan'),
            },
            {
              cssClass: 'two-button-gray',
              text: 'Good to know',
              role: 'cancel',
              
            },
          ]}
        />
      </>
    );
  }
}

const stateToProps = (store) => {
  const { noTableModalOpen } = store.common;
  return {
    noTableModalOpen,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(NoTableModal)));
