import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonAlert } from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { forwardTo, isWebConfig } from '../../lib/utils';
import logoGif from '../../assets/images/plane.gif';
import { StrongText } from '../../components/common';
import { loginRequest, setCommonModal } from '../../store/actions';
import Loading from '../../components/spinner';
import Slider, { Slide } from '../../components/slider';
import { getConfig } from '../../appConfig';
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderContent: [
        'THERE IS ONLY ONE THING BETTER THAN SOURDOUGH PIZZA. FREE SOURDOUGH PIZZA',
        'EAT PIZZA. COLLECT 6 STAMPS AND ENJOY A FREE PIZZA',
        'EARN PIZZA, REFER A FRIEND AND ENJOY REWARDS',
      ],
    };
  }
  componentDidMount() {
    if (isWebConfig()) {
      let hasOrdering = false
      if ((getConfig().orderTesting && localStorage.getItem('developer_debug_account')) || !getConfig().orderTesting) {
        hasOrdering = true
      }
      hasOrdering ? forwardTo('/order') : forwardTo('/homepage')
    } else {
      const { profile, dispatch, history } = this.props;
      const tokenFromUrl = new URLSearchParams(window.location.search).get('token');
      const tokenIsSame = profile && tokenFromUrl && profile?.mycheck_token === tokenFromUrl ? true : false;
      if (tokenFromUrl && !tokenIsSame) {
        history.push('/dashboard');
        dispatch(loginRequest({ userToken: tokenFromUrl, referrer: '/account' }));
      } else if (tokenIsSame) {
        forwardTo('/account');
      }
    }

  }

  render() {
    const { __, passwordIsUpdatedModal } = this.props;
    return (
      <Loading transparent>
        <Layout color="transparent" disableRouteClassName={true} contentClassName={"route-dashboard"}>
          <div className="absolute-content">
            <div className="dashboard-wrapper">
              <div style={{marginTop:'50px'}}>
                <img className="animated-header" src={logoGif} />
                <Slider
                  settings={{
                    navigation: true,
                    pagination: true,
                    autoplay: {
                      delay: 3000,
                      disableOnInteraction: false,
                    },
                  }}
                >
                  {this.state.sliderContent.map((el) => (
                    <Slide>{__(el)}</Slide>
                  ))}
                </Slider>
                <IonButton onClick={() => forwardTo('/login')} color="secondary" expand="block">
                  {__('Log in')}
                </IonButton>
                <IonButton onClick={() => forwardTo('/register')} color="secondary" expand="block">
                  {__('Register')}
                </IonButton>
                <IonButton
                  onClick={() => forwardTo('/restaurants')}
                  class="link underlined"
                  expand="block"
                >
                  {__('Find your local')}
                </IonButton>
                {((getConfig().orderTesting && localStorage.getItem('developer_debug_account')) || !getConfig().orderTesting) &&
                <IonButton
                  onClick={() => forwardTo('/order')}
                  class="link underlined"
                  expand="block"
                >
                  {__('View our menu')}
                </IonButton>
                 }
              </div>
              <IonItem lines="none" className="input-field-wrapper no-border app-version">
                <StrongText>{`v${this.props.appVersion}`}</StrongText>
              </IonItem>
            </div>
          </div>
          <IonAlert
            isOpen={passwordIsUpdatedModal}
            onDidDismiss={() => this.props.dispatch(setCommonModal('passwordIsUpdatedModal', false))}
            header={__('Success')}
            message={__('Password updated')}
            cssClass="no-table-alert one-red-button-alert"
            buttons={[
              {
                text: __('OK'),
                cssClass: 'two-button-secondary',
                handler: () => this.props.dispatch(setCommonModal('passwordIsUpdatedModal', false))
              },
            ]}
          />
        </Layout>
      </Loading>
    );
  }
}
const stateToProps = (state) => {
  const { appVersion, passwordIsUpdatedModal } = state.common;
  const { profile } = state.profile
  return {
    appVersion,
    passwordIsUpdatedModal,
    profile
  };
};

export default connect(stateToProps)(withTranslation(Homepage));
