import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';

import Loading from '../../components/spinner';
import { NormalText, Spacer } from '../../components/common';
import api from '../../lib/api';
import { loading } from '../../store/actions';
import Basket from '../../lib/basket';
import { GET_ORDER_HISTORY, SET_PROFILE_PROP } from '../../store/constants';
import { IonButton } from '@ionic/react';
import asyncStorage from '../../lib/asyncStorage';

class NativePaymentResultView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderFailed: false,
    };
  }
  async componentDidMount() {
    try {
      this.props.dispatch(loading(true))
      const orders = await api.getOrderHistory()
      const authenticationResult = await api.authenticationResultView3ds({ token: Basket.getGlobalpayToken(), id: Basket.getGlobalpayAut(), custom: { id: orders[0]?.id, } })
      this.setState({ authText: JSON.stringify(authenticationResult) })
      const isAuthenticated = authenticationResult.status === "SUCCESS_AUTHENTICATED"
      if (isAuthenticated) {
        const payment = await api.globalpayOrderPayment({
          id: orders[0]?.id,
          pmt: Basket.getPMT(),
          pmt_selected: Basket.getPMTSelected(),
          pmt_single: Basket.getPMTSingle(),
          aut: Basket.getGlobalpayAut(),
          token: Basket.getGlobalpayToken(),
          card_token:Basket.getGlobalpayCardToken()
        })

        this.props.dispatch({ type: GET_ORDER_HISTORY, loading: false })
        this.setState({ orderFailed: false })
      }
      else {
        this.setState({ orderFailed: true })
      }
      this.props.dispatch(loading(false))
    }
    catch (err) {
      this.setState({ orderFailed: true })
      this.props.dispatch(loading(false))

    }

  }
  render() {
    const { __ } = this.props

    const { orderFailed } = this.state
    return (
      <Loading>
       <Layout
          showHeader
          disableHeader
          color="transparent"
          hideBack={true}
          hideSecondToolbar={true}
        >

        <div className='payment-result-wrapper' style={{ textAlign: 'center', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '40px' }}>
          {orderFailed ?
            <NormalText className='dark-color'>{__("Your payment has failed")}</NormalText>
            :
            <NormalText className='dark-color'>{__("Your payment has been successfully processed")}</NormalText>
          }
          <Spacer size={1} />
          <IonButton
            style={{ width: '30%' }}
            color="secondary"
            onclick={() => window.parent.postMessage(`GLOBALPAY_WINDOW_CLOSED${orderFailed ? '_FAILED' : ''}`, "*")}>{__('Close')}</IonButton>
        </div>
        </Layout>

      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    orderHistory: state.orders.orderHistory || [],
  };
};

export default connect(stateToProps)(withTranslation(NativePaymentResultView));
