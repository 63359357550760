import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import './index.css';
import { Spacer, Title } from '../../components/common';
import { connect } from 'react-redux';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { IonButton } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import { joinTable } from '../../store/actions';

class QRScanner extends Component {
  getTableData = (value) => {
    const searchParams = new URLSearchParams(value);
    const token = searchParams.get('c');
    const location = searchParams.get('l');
    this.props.dispatch(
      joinTable({
        location,
        token,
      }),
    );
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout delay={2000} color="transparent" hideBack={true} hideSecondToolbar={true}>
          <div className="absolute-content scrollable-y">
            <>
              <BarcodeScannerComponent
                delay={1000}
                onUpdate={(err, result) => {
                  if (result) {
                    this.getTableData(result.text);
                  }
                }}
                onError={(err) => {
                  forwardTo('/check-table');
                }}
              />
              <Spacer />
              <IonButton color="secondary" onClick={() => forwardTo('/check-table')}>
                {__('CANCEL')}
              </IonButton>
            </>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {};
};

export default connect(stateToProps)(withTranslation(QRScanner));
