import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Modal from '../modal';
import { getTermsAndConditions } from '../../store/actions';
import { NormalText } from '../common';
import './index.css';

class TermsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModal: false,
    };
  }

  componentDidMount() {
    if (!this.props.terms) {
      this.props.dispatch(getTermsAndConditions());
    }
  }

  render() {
    const { __, terms, isOpen, onDidDismiss } = this.props;
    return (
      <>
        <Modal
          title={
            <NormalText className="link-color-secondary uppercase">
              {__('Terms & Conditions')}
            </NormalText>
          }
          isOpen={isOpen}
          className="terms-modal"
          onDidDismiss={onDidDismiss}
        >
          <div dangerouslySetInnerHTML={{ __html: terms }}></div>
        </Modal>
      </>
    );
  }
}

const stateToProps = (store) => {
  return {
    termsModal: store.common.termsModal || null,
    terms: store.common.terms || null,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(TermsModal)));
