import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonIcon, IonLabel, IonInput } from '@ionic/react';
import { withRouter } from 'react-router';
import {
  checkmarkCircle,
  checkmarkCircleOutline,
  chevronBack,
  chevronForwardOutline,
  pencil,
  pencilOutline,
} from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import ValidateButton from '../../components/validateButton';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { PillGroup } from '../../components/pill';
import {
  Title,
  SmallText,
  Sectiontitle,
  Spacer,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import OrderContent from '../../components/orderContent';
import { ApplyPoints } from '../../screens/applyPoints';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  isEmptyObject,
  isWebConfig,
  getDefaultRoute,
  isWeb,
} from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import moment from '../../lib/moment';
import { setModal, setProtectedReferrer, restoreAuth, checkOrder } from '../../store/actions';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import { Browser } from '@capacitor/browser';
const { SelectOption } = Mobiscroll;
const {
  getRestauranName,
  getOrderDate,
  getOrderTime,
  changeItemQuantity,
  itemsCount,
  getItems,
  setMobile,
  getMobile,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
  getTableNumber,
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      applyPointsModalOpen: false,
      mobile: getMobile() || '',
      tableNumberModalOpen: false,
      tableNumberValue: Basket.getTableNumber(),
    };
  }
  selectTableNumber = (event, data) => {
    const tableId = data.getVal();
    this.setState({
      tableNumberValue: tableId,
    });
  };
  componentDidUpdate(prevProps) {

    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }
  componentDidMount() {
    if (Basket.applicable_vouchers?.length > 0 || getConfig().flags.checkOrderWithoutVouchers) {
      this.props.dispatch(checkOrder({ ...Basket.parseBasketForCheckVouchers(true) }))

    }
    if (!Basket.getDeliveryOption().id) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(
        getConfig().general.defaultRoute ? getConfig().general.defaultRoute : defaultRoute.path,
      );
    } else {
      const tableNumbers = Basket.getRestaurant().table_plan_json;
      this.setState({
        totalTableNumbers: tableNumbers,
      });
    }
  }
  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };
  tableNumberChanged = () => {
    if (this.state.tableNumberValue != '') {
      Basket.setTableNumber(this.state.tableNumberValue);
    }
    this.setState({ tableNumberModalOpen: false });
  };
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };

  handleSubmit = async () => {
    const { cards, auth } = this.props;
    if (auth.loggedIn) {
      if (!await Basket._isCollectionTimeStillValid()) {
        return
      }
      if (!isMinimumOrderTotalSatisfied()) {
        // display toast with flag 'true'
        isMinimumOrderTotalSatisfied(true);
        return;
      }
      if (parseFloat(getTotal()) === 0) {
        Basket.setSelectedCard({ location: 'ORDER SUMMARY' })
        Basket.createOrder();
        return;
      }
      if (!isWeb()) {
        Basket.createOrder()

      } else {
        if (cards && cards.length >= 1) {
          if (this.props.profile.mobile && this.props.profile.first_name) {
            forwardTo('/checkout', {
              giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
            });
            setMobile(this.props.profile.mobile);
          } else {
            forwardTo('/login');
          }
        } else {
          if (this.props.profile.mobile && this.props.profile.first_name) {
            setMobile(this.props.profile.mobile);
            forwardTo('/checkout', {
              giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
            });
          } else {
            forwardTo('/login');
          }
        };
      }
    } else {
      forwardTo('/login');
    }

  }

  handleOrderItemClick = (item, index, quantity) => {
    changeItemQuantity(index, quantity);
    // this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  formatDayName = (name) => {
    if (name.includes('Today')) {
      name = 'Today'.toLowerCase();
    } else if (name.includes('Tomorrow')) {
      name = 'Tomorrow'.toLowerCase();
    } else {
      name = getOrderDate();
    }
    return name;
  };
  isRedeemPointsDisabled = () => {
    const { redeemPointsMin } = getConfig().general;
    const { available_balance } = this.props.profile;
    return available_balance < redeemPointsMin ? true : false;
  };

  findSelectedSlot = (dayInWeek, time) => {
    const restaurant = Basket.getRestaurant();
    const selectedDay =
      !isEmptyObject(restaurant) &&
        restaurant.delivery_times_json &&
        !isEmptyObject(restaurant.delivery_times_json) &&
        restaurant.delivery_times_json.slots &&
        !isEmptyObject(restaurant.delivery_times_json.slots)
        ? restaurant.delivery_times_json.slots[dayInWeek]
        : null;
    if (selectedDay) {
      const selectedSlot = selectedDay.find(
        (day) =>
          moment(day.start_time, 'HH:mm').format('HH:mm') ===
          moment(time, 'HH:mm a').format('HH:mm'),
      );
      return (
        moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
        ' - ' +
        moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
      );
    }
    return null;
  };

  drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
    let dateName = this.formatDayName(
      moment(getOrderDate(), 'dddd Do MMMM').calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
      }),
    );
    if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <NormalText>
            {__('Ordering for')} {__('drop-off')} {__('at')} {getRestauranName()} {dateName}{' '}
            {__('at')} {getOrderTime()}
            <br />
          </NormalText>
        </>
      );
    } else if (deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <NormalText>
            {__('Ordering for')} {__('delivery')} {__('to')} {deliveryAddress.addressLine1},{' '}
            {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')}{' '}
            {getOrderTime()}
            <br />
          </NormalText>
        </>
      );
    } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>

          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Table order at')}</StrongText>
              <NormalText>{getRestauranName()}</NormalText>
            </div>
            <div
              className="pointer"
              onClick={() => {
                this.setState({ tableNumberModalOpen: true });
              }}
            >
              <StrongText> {getTableNumber()} </StrongText>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Subtitle className='mb-10 secondary-font dark-color  block'>{__('Collect from')}</Subtitle>
          <NormalText className=' block dark-color lined-row' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{getRestauranName()}</span>
            <span style={{ cursor: 'pointer' }} onClick={() => forwardTo('/click-and-collect', { selectedRestaurantId: Basket.getRestaurant()?.id })}><IonIcon icon={pencil} /></span>
          </NormalText>
          <NormalText style={{ display: 'flex', justifyContent: 'space-between' }} className=' dark-color'>
            <span>{__('Collection time')} </span>
            <span>{getOrderTime('h:mm a')}</span>

          </NormalText>
        </>
      );
    }
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      // this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-points');
      }
    }
  };
  handleApplyLoyaltyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      forwardTo('/apply-vouchers');
    }
  };
  formatTableNumberForSelect = (selectedRestaurantId) => {
    const { __ } = this.props;
    let arrForSelect = [];
    if (selectedRestaurantId) {
      (this.state.totalTableNumbers || []).map((table, index) => {
        arrForSelect.push({ text: table, value: table });
      });
    }
    return [{ text: __('Select table'), value: null }, ...arrForSelect];
  };
  render() {
    const { __, profile, auth, dispatch } = this.props;
    const { quantityModal, allergenModalOpen, applyPointsModalOpen, mobile } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' };
    const isAuth = auth.loggedIn;
    return (
      <>
        <div className='page-secondary-header' style={{ flex: 'unset' }}>
          <IonIcon onClick={() => this.backHandler()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>

          <Title className="secondary-font dark-color">{__('Your order')}</Title>
        </div>
        <div className="absolute-content flex-row-wrapper summary-wrapper">


          <div className="scrollable-y checkout">
            {Basket.getDeliveryOption().id != 'gift-vouchers' ? (
              <>
                {isWebConfig() &&
                  <>
                    <Subtitle color='dark' className='secondary-font centered dark-color'>{__(
                      'Order & collect'
                    )}</Subtitle>
                    <Spacer size={1} />
                  </>
                }
                <div className='order-header-content-wrapper'>
                  {this.drawContentHeader(
                    __,
                    Basket.getDeliveryOption(),
                    Basket.getOrderType(),
                    Basket.getDeliveryAddress(),
                  )}
                </div>

                <Spacer size={1} />
                {/* <div className="input-field-container order-summary-mobile">
                  <NormalText>{__('Contact Number For This Order')}</NormalText>
                  <IonItem lines="none" className="input-field-wrapper">
                    <IonInput
                      onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                      clearInput
                      required={false}
                      type="tel"
                      pattern="tel"
                      inputmode="tel"
                      value={mobile}
                    ></IonInput>
                  </IonItem>
                </div> */}
                <Spacer size={1} />
                <Subtitle className="mb-15 block secondary-font dark-color">
                  {__('Your order')}
                </Subtitle>

                {itemsCount() > 0 ? (
                  <OrderContent
                    showAddItems={true}
                    handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                  />
                ) : (
                  <IonItem lines="none">
                    <div className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
                      {__('No items')}
                    </div>
                  </IonItem>
                )}
                <Spacer size={1} />
                {/* {allergens.length > 0 ? (
                  <IonButton
                    className="order-summary-additional-button"
                    expand="block"
                    color="primary"
                    fill="clear"
                    onClick={() => this.setState({ allergenModalOpen: true })}
                  >
                    <NormalText> {__('View allergen information')}</NormalText>
                    <IonIcon icon={chevronForwardOutline}></IonIcon>
                  </IonButton>
                ) : null} */}

                {auth.loggedIn && (
                  <>
                    <Subtitle className="mb-15 block secondary-font dark-color">
                      {__('Your rewards')}
                    </Subtitle>
                    <IonButton
                      onClick={() => {
                        itemsCount() == 0
                          ? dispatch(setModal('isVerfiedModalOpen', true))
                          : this.handleApplyLoyaltyModal(true);
                      }}
                      className={`${itemsCount() == 0 ? 'disabled' : ''
                        } order-summary-additional-button`}
                      expand="block"
                      color="primary"
                      fill="clear"
                    >
                      <NormalText className='uppercase dark-color'>{`${__(this.props.vouchers.filter(voucher => !voucher.Redeemed).length)} ${__(`${this.props.vouchers.filter(voucher => !voucher.Redeemed).length == 1 ? 'REWARD AVAILABLE' : 'REWARDS AVAILABLE'}`)}`}</NormalText>
                      <IonIcon icon={chevronForwardOutline}></IonIcon>
                    </IonButton>
                  </>
                )}

              </>
            ) : (
              <>
                {/* <Title>{__('eGift Voucher Summary')}</Title>

                <div className="box-wrapper gift-voucher-order-content">
                  <IonIcon icon={checkmarkCircle}></IonIcon>

                  <div className="gift-voucher-order-content-summary">
                    <Subtitle className="bold">
                      {Basket.formatPrice(this.props.giftVoucherData?.points_value / 100)}{' '}
                      {__('eGift Voucher')}
                    </Subtitle>
                    <NormalText>
                      {__('Recipient')}
                      {': '}
                      {this.props.giftVoucherData?.recipient_email}
                    </NormalText>
                    {this.props.giftVoucherData?.send_on_date && (
                      <NormalText className="block">
                        {__('Send date')}
                        {': '}
                        {this.props.giftVoucherData?.send_on_date}
                      </NormalText>
                    )}
                  </div>
                </div>
                <Spacer />

                <OrderContent showAddItems={false} /> */}
              </>
            )}
          </div>

          <div className="flex-min">
            <IonButton
              disabled={itemsCount() === 0}
              onClick={this.handleSubmit}
              expand="block"
              color="secondary"
              className={
                'checkout-btn' +
                (!isMinimumOrderTotalSatisfied() ? 'greyed' : '') +
                '  '
              }
            >
              {Basket.getTotal() == 0 ? <>{__('Place order')}</> : <>{`${__('Pay')} ${Basket._getTotal(true)}`}</>}

            </IonButton>
          </div>
        </div>
        <Modal
          cssClass="table-number-modal"
          isOpen={this.state.tableNumberModalOpen}
          onDidDismiss={() =>
            this.setState({
              tableNumberModalOpen: false,
              tableNumberValue: Basket.getTableNumber(),
            })
          }
        >
          <Subtitle className="centered">{__('Change Table Number')}</Subtitle>
          <Spacer size={1} />
          <IonItem
            className={`${getConfig().flags.tableNumberPicker ? 'dropdown-field' : ''
              } input-field-wrapper`}
            lines="none"
          >
            {getConfig().flags.tableNumberPicker ? (
              <SelectOption
                display="center"
                label="Table Number"
                inputStyle="box"
                setText={__('OK')}
                cancelText={__('Cancel')}
                data={this.formatTableNumberForSelect(Basket.getRestaurant())}
                value={this.state.tableNumberValue}
                onSet={(e, a) => this.selectTableNumber(e, a)}
                disabled={(this.state.totalTableNumbers || []).length <= 1 ? true : false}
              />
            ) : (
              <div className="input-field-container ">
                <NormalText>{__('Enter table number')}</NormalText>
                <IonItem class='input-field-wrapper'>
                  <IonInput
                    className="table-number-input"
                    onIonChange={(event) => {
                      this.setState({ tableNumberValue: event.target.value });
                    }}
                    value={this.state.tableNumberValue}
                    type="text"
                    placeholder={__('Enter table number')}
                  />
                </IonItem>

              </div>
            )}
          </IonItem>
          <Spacer size={1} />
          <IonButton
            color="secondary"
            expand="block"
            className="uppercase"
            onClick={() => {
              this.tableNumberChanged();
            }}
          >
            {' '}
            {__('Confirm')}
          </IonButton>
        </Modal>
        <Modal
          cssClass="quantity-modal full-screen-modal"
          // title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          <Title>{__('Change quantity')}</Title>
          <Spacer />
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
          <Spacer />
        </Modal>
        <Modal
          cssClass="allergen-modal full-screen-modal"
          isOpen={allergenModalOpen}
          onDidDismiss={() => this.setState({ allergenModalOpen: false })}
        >
          <div className="flex-row-wrapper">
            <div className="scrollable-y">
              <Title>{__('Allergen Information')}</Title>
              <Spacer />
              {getItems().map((item, index) => {
                let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
                if (data && data.length > 0) {
                  return (
                    <div key={'allergen-' + index}>
                      <Sectiontitle>
                        {item.quantity}x {item.item.productName}
                      </Sectiontitle>
                      <PillGroup items={data[0].allergens} borderColor="primary" />
                      <Spacer />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </Modal>
        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          <ApplyPoints
            handleApplyModal={this.handleApplyModal}
            applyPointsModalOpen={applyPointsModalOpen}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption, giftVoucherData } = store.orders;
  const { auth } = store.profile;
  return {
    basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    deliveryOption,
    itemAllergens: store.restaurants.itemAllergens,
    auth,
    giftVoucherData,
    navConfig: store.common.navConfig,
    vouchers: store.profile.vouchers || null,

  };
};

export const OrderSummary = connect(mapStateToProps)(withRouter(withTranslation(OrderSummaryRaw)));

class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/order');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading transparent>
        <Layout
          showHeader={true}
          color="transparent"
          headerTitle={__('Order Summary')}
          backHandler={this.backHandler}
          scrollY={false}
          hideSecondToolbar={true}
          showBack
        >
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
