import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonInput, IonLabel } from '@ionic/react';
import { withRouter } from 'react-router';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import './index.css';
import TermsModal from '../../components/termsModal';

import student from '../../assets/images/student-pic-initial.png';

class Students extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
    };
    this.test = false;
  }

  render() {
    const { __, terms, history } = this.props;
    return (
      <Loading transparent>
        <Layout
          color="transparent"
          showHeader
          showFooter
          hideSecondToolbar={true}
          headerTitle={__('Students')}
        >
          <NormalText className="contact-subtitle">
            {__(
              'SOURDOUGH PIZZA MAKES YOU SMART! STUDENTS GET 2 BONUS STAMPS ON EVERY STAMP CARD.',
            )}
          </NormalText>
          <Spacer />
          <IonLabel color="primary" className="comment-wrapper-light">
            <NormalText className="uppercase bold blocked-input">
              {__(
                'NO NEED TO TELL US YOUR GRADES, BUT WE DO NEED TO CHECK YOUR STUDENT STATUS. NEXT TIME YOU VISIT US, SHOW YOUR SERVER YOUR STUDENT ID AND THEY WILL VALIDATE YOUR PROFILE.',
              )}
            </NormalText>
            <Spacer size={1} />
            <NormalText className="uppercase bold blocked-input">
              {__(
                `ALTERNATIVELY, YOU CAN ENTER A FRANCO MANCA UNIDAYS CODE INTO OUR APP TO CONFIRM YOUR STATUS (YOU CAN DOWNLOAD A CODE FROM THE UNIDAYS WEBSITE OR APP). JUST FOLLOW THESE SIMPLE STEPS:`,
              )}
            </NormalText>
            <Spacer size={1} />
            <NormalText className="uppercase  bold blocked-input align-initial">
              {__(
                '1. WHEN YOU NEXT VISIT, CHECK IN TO YOUR TABLE USING OUR APP. ONCE YOU’VE PLACED YOUR ORDER YOU CAN ADD YOUR CODE.',
              )}
            </NormalText>
            <Spacer size={1} />
            <NormalText className="uppercase  bold blocked-input align-initial">
              {__(
                '2. IN THE ‘YOUR TABLE’ AREA, PRESS THE ‘REWARDS’ TAB, AND THEN THE ‘REDEEM’ BUTTON NEXT TO YOUR NAME.',
              )}
            </NormalText>
            <Spacer size={1} />
            <NormalText className="uppercase  bold blocked-input align-initial">
              {__(
                '3. AT THE BOTTOM OF THE NEXT SCREEN, ENTER YOUR CODE IN THE GREY BOX, THEN PRESS ‘SUBMIT’ – THIS MAY TAKE A FEW MOMENTS TO LOAD - THE REWARD WILL BE ADDED TO YOUR TABLE AND YOUR STUDENT STATUS IS NOW VERIFIED!',
              )}
            </NormalText>
          </IonLabel>
          <Spacer size={1} />
          <NormalText
            className="underlined link-color blocked-input"
            onClick={() => this.setState({ showTerms: true })}
          >
            {__('T&CS')}
          </NormalText>
          <img src={student} />
          <IonButton
            className="student-button"
            expand="block"
            color="secondary"
            strong={true}
            onClick={() => history.goBack()}
          >
            {__('Ok')}
          </IonButton>
        </Layout>
        <TermsModal
          isOpen={this.state.showTerms}
          onDidDismiss={() => this.setState({ showTerms: false })}
        />
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  return {
    terms: store.common.terms || null,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Students)));
