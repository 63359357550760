import axios from 'axios';
import { isDefined } from './utils';
import { getConfig } from '../appConfig';
import packageInfo from '../../package.json';

let axiosInstance;
const baseURL = getConfig().api.baseURL;
const wordPressBaseURL = getConfig().api.wordPressBaseURL;
const endpoints = {
  login: '/api-token-auth', //post
  register: '/me/register', //post
  getProfile: '/me', //get
  changePassword: '/me', //put
  changeProfile: '/me', //put
  resetPassword: '/me/reset_password', //put
  deleteCard: '/delete_payment_card', //put
  sendFeedback: '/feedback/create', //post,
  firebaseToken: '/firebase-token', //post,
  appVersion: '/appversion', //get,
  social: '/social', //get,
  restaurants: '/restaurants/all', //get
  terms: '/terms', //get,
  privacyPolicy: '/privacy', //get
  faq: '/faq', //get
  rewards: '/reward_milestones', //get
  getBalance: '/get_balance', //get
  history: '/transactions/history', //get
  ikentooMenu: '/ikentoo_menu/', //get
  ikentooMenusForLocation: '/ikentoo_menus/', //get
  getPaymentCards: '/orders/list_payment_cards', //post
  addPaymentCard: '/orders/create_payment_card', //post
  removePaymentCard: '/order/delete_payment_method', //post
  createOrder: '/order/create', //post
  orderHistory: '/me/orders', //get
  getOrderById: '/order/get_order_by_id', //get
  validateEmail: '/me/validate_email', //get
  sendVoucherCode: '/voucher/code', //put,
  referFriend: '/refer_friend', //post
  getVouchers: '/me/vouchers', //get
  publicStripeKey: '/public_stripe_key', //get
  frontEndAppConfig: '/front_end_app_config', //get
  getTranslations: '/translation_json', //get
  addDeliveryAdress: '/me/add_new_address', //put
  getRestaurantSnoozeData: '/ikentoo_menu/get_snooze_data', //get
  postCodeCheck: '/order/postcode_check', //post
  getNearestLocation: '/order/get_nearest_restaurant_location', //put
  getDeliveryRangeType: '/get_delivery_range_type', //get
  locationCodeCheck: '/order/location_code_check', // post
  addPickupPoint: '/me/add_pickup_point', // put,
  getOrderProduction: '/order_production_length',
  removeDeliveryAddress: '/orders/remove_delivery_address', //post
  cancelOrder: '/orders/cancel_order', // post
  getDefaultMenuId: '/get_default_menu_id', //get
  getDefaultMenu: '/get_default_menu', //get
  saveJudoPayCard: '/orders/save_judo_pay_cards', //post
  checkDiscount: '/order/voucher_discount', //post
  getLastOrder: '/orders/get_last_order', // get
  paymentInfo: '/update_payment_info',
  subscriptionPackages: '/subscription/packages', // get
  createSubscription: '/subscription/create', // post
  cancelSubscription: '/subscription/cancel', // put
  payMembership: '/subscription/memberships', // post
  sendGiftVoucher: '/gift_voucher/create',
  resendGiftVoucher: '/gift_voucher/resend',
  redeemGiftVoucher: '/gift_voucher/redeem_code',
  getSentGiftVouchers: '/gift_voucher/sent_gift_vouchers',
  updateGiftVoucher: '/gift_voucher/update',
  getNews: '/news',
  nutritionData: '/nutrition_data',
  sendSMS: '/send_sms',
  verifyCode: '/verify_code',
  deleteUser: '/me/delete_user', // post
  joinTable: '/join_table',
  getMyTable: '/get_my_table',
  getMycheckUrl: '/get_mycheck_url',
  getTableAppliedVouchers: '/get_applied_vouchers',
  leaveTable: '/leave_table',
  acceptUser: '/accept_user',
  dismissUser: '/dismiss_user',
  applyVoucher: '/apply_voucher',
  removeVoucher: '/remove_voucher',
  validateVoucher: '/validate_voucher',
  redeemFeedbackCode: '/redeem_feedback_code',
  getAccessToken: '/order/get_access_token',
  deleteUserCard: '/order/delete_payment_method',
  globalpayOrderPayment: '/order/globalpay_order_payment',
  authenticationResultView3ds: '/order/authentication_result_view_3ds',
  checkOrder: '/order/check_order',
  releaseVoucher: '/order/release_voucher'
}
const createAxiosInstance = (token) => {
  const headers = isDefined(token)
    ? {
      Authorization: 'JWT ' + token,
    }
    : {};
  axiosInstance = axios.create({
    headers,
    timeout: 50000,
  });
  return axiosInstance;
};

export const isFieldValid = (fieldName, errors = {}) => !isDefined(errors[fieldName]);

export const getErrorMessage = (fieldName, errors = {}) =>
  !isFieldValid(fieldName, errors) ? errors[fieldName] : '';

const api = {
  login: async (username, password) => {
    const response = await createAxiosInstance().post(baseURL + endpoints.login, {
      username,
      password,
      version: packageInfo.version,
    });
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  loginWithToken: async (token) => {
    const response = await createAxiosInstance().post(baseURL + endpoints.login, {
      token,
      version: packageInfo.version,
    });
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  register: async (userData) => {
    userData = { ...userData, version: packageInfo.version };
    const response = await createAxiosInstance().post(baseURL + endpoints.register, userData);
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  logout: () => {
    // eslint-disable-next-line no-console
    console.log('logout');
  },
  resetPassword: async (phone, code, password) =>
    await axiosInstance.put(baseURL + endpoints.resetPassword, { phone, code, password, version: packageInfo.version }),
  createResetPasswordCode: async (phone) =>
    await axiosInstance.post(baseURL + endpoints.resetPassword, { phone, version: packageInfo.version }),
  getProfile: async () => await axiosInstance.get(baseURL + endpoints.getProfile + `?version=${packageInfo.version}`),
  updateProfile: async (data = {}) =>
    await axiosInstance.put(baseURL + endpoints.changeProfile, { ...data, version: packageInfo.version }),
  sendFirebaseToken: async (data) =>
    await axiosInstance.post(baseURL + endpoints.firebaseToken, { ...data, version: packageInfo.version }),
  getAppVersion: () =>
    axiosInstance.get(baseURL + endpoints.appVersion + `?version=${packageInfo.version}`).then((res) => res.data.data.app_version),
  getSocialLinks: () =>
    axiosInstance.get(baseURL + endpoints.social + `?version=${packageInfo.version}`).then((res) => res.data.social_link_json),
  sendFeedback: async (data = {}) =>
    await axiosInstance.post(baseURL + endpoints.sendFeedback, { ...data, version: packageInfo.version }),
  getRestaurants: () => axiosInstance.get(baseURL + endpoints.restaurants + `?version=${packageInfo.version}`).then((res) => res.data),

  getPrivacyPolicy: (locale = 'en') =>
    axios
      .create()
      .get(wordPressBaseURL + '/' + locale + '/privacy/' + getConfig().envs.APP_DOMAIN + `?version=${packageInfo.version}`, {
        responseType: 'text',
      })
      .then((res) => res.data),
  // getFaq: (locale = 'en') =>
  //   axios
  //     .create()
  //     .get(wordPressBaseURL + '/' + locale + '/faqs/' + getConfig().envs.APP_DOMAIN, {
  //       responseType: 'text',
  //     })
  //     .then((res) => res.data),
  getRewards: () => axiosInstance.get(baseURL + endpoints.rewards + `?version=${packageInfo.version}`).then((res) => res.data.data),
  getBalance: () => axiosInstance.get(baseURL + endpoints.getBalance + `?version=${packageInfo.version}`).then((res) => res.data),
  getTerms: () => axiosInstance.get(baseURL + '/terms' + `?version=${packageInfo.version}`).then((res) => res.data),
  getFaq: () => axiosInstance.get(baseURL + '/faq' + `?version=${packageInfo.version}`).then((res) => res.data),

  getIkenooMenu: (menuId, businessLocationId) =>
    axiosInstance
      .get(baseURL + endpoints.ikentooMenu + menuId + '/location/' + businessLocationId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data),
  getIkentooMenusForLocation: (businessLocationId) =>
    axiosInstance
      .get(baseURL + endpoints.ikentooMenusForLocation + 'location/' + businessLocationId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data),
  getHistory: () => axiosInstance.get(baseURL + endpoints.history + `?version=${packageInfo.version}`).then((res) => res.data),
  getPaymentCards: () =>
    axiosInstance
      .post(baseURL + endpoints.getPaymentCards, { version: packageInfo.version })
      .then((res) => res.data.data),
  addPaymentCard: (cardData) =>
    axiosInstance
      .post(baseURL + endpoints.addPaymentCard, { ...cardData, version: packageInfo.version })
      .then((res) => res.data.data),
  removePaymentCard: (cardData) =>
    axiosInstance
      .post(baseURL + endpoints.removePaymentCard, { ...cardData, version: packageInfo.version })
      .then((res) => res.data),
  createOrder: (orderData) =>
    axiosInstance
      .post(baseURL + endpoints.createOrder, { ...orderData, version: packageInfo.version })
      .then((res) => res.data),
  getOrderHistory: () =>
    axiosInstance.get(baseURL + endpoints.orderHistory + `?version=${packageInfo.version}`).then((res) => res.data),

  getOrderById: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.getOrderById, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  sendCode: async (data) =>
    await axiosInstance
      .put(baseURL + endpoints.sendVoucherCode, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  sendRefer: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.referFriend, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  getVouchers: async () =>
    await axiosInstance.get(baseURL + endpoints.getVouchers + `?version=${packageInfo.version}`).then((res) => res.data),
  validateEmail: async () => await axiosInstance.get(baseURL + endpoints.validateEmail + `?version=${packageInfo.version}`),
  getPublicStripeKey: () =>
    axiosInstance
      .get(baseURL + endpoints.publicStripeKey + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.public_stripe_key),
  getFrontEndAppConfig: () =>
    axiosInstance
      .get(baseURL + endpoints.frontEndAppConfig + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.front_end_app_config),
  // getTranslations: () => axiosInstance.get(baseURL + endpoints.getTranslations).then(res => res.data.data.translation_json),
  getTranslations: () =>
    axiosInstance
      .get(`${wordPressBaseURL}/en/dictionary/${getConfig().envs.APP_DOMAIN}`)
      .then((res) => res.data),
  addDeliveryAdress: (data) =>
    axiosInstance.put(baseURL + endpoints.addDeliveryAdress, { ...data, version: packageInfo.version }),
  getRestaurantSnoozeData: () =>
    axiosInstance.get(baseURL + endpoints.getRestaurantSnoozeData + `?version=${packageInfo.version}`).then((res) => res.data.data),
  postCodeCheck: (data) =>
    axiosInstance.post(baseURL + endpoints.postCodeCheck, { ...data, version: packageInfo.version }),
  getNearestLocation: (data) =>
    axiosInstance.put(baseURL + endpoints.getNearestLocation, { ...data, version: packageInfo.version }),
  getDeliveryRangeType: () =>
    axiosInstance
      .get(baseURL + endpoints.getDeliveryRangeType + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.delivery_range_type),
  locationCodeCheck: (data) =>
    axiosInstance.post(baseURL + endpoints.locationCodeCheck, { ...data, version: packageInfo.version }),
  addPickupPoint: (data) =>
    axiosInstance.put(baseURL + endpoints.addPickupPoint, { ...data, version: packageInfo.version }),
  getOrderProduction: () =>
    axiosInstance
      .get(baseURL + endpoints.getOrderProduction + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.order_production_mins),
  removeDeliveryAddress: (postalCode) =>
    axiosInstance
      .post(baseURL + endpoints.removeDeliveryAddress, { ...postalCode, version: packageInfo.version })
      .then((res) => res.data),
  cancelOrder: (data) =>
    axiosInstance
      .post(baseURL + endpoints.cancelOrder, { ...data, version: packageInfo.version })
      .then((res) => res.data.data),
  getDefaultMenuId: () =>
    axiosInstance
      .get(baseURL + endpoints.getDefaultMenuId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.default_menu_id),
  getDefaultMenu: (menuId) =>
    axiosInstance
      .get(baseURL + endpoints.getDefaultMenu + '/' + menuId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data),
  saveJudoPayCard: (cardData) =>
    axiosInstance
      .post(baseURL + endpoints.saveJudoPayCard, { ...cardData, version: packageInfo.version })
      .then((res) => res.data.data),
  checkDiscount: async (data) =>
    await axiosInstance.post(baseURL + endpoints.checkDiscount, { ...data, version: packageInfo.version }),
  getLastOrder: (id) =>
    axiosInstance.get(baseURL + endpoints.getLastOrder + '/' + id + `?version=${packageInfo.version}`).then((res) => res.data.data),
  updatePaymentInfo: async (paymentInfo = {}) =>
    await axiosInstance
      .post(baseURL + endpoints.paymentInfo, { ...paymentInfo, version: packageInfo.version })
      .then((res) => res.data.data),
  getSubscriptions: async () => await axiosInstance.get(baseURL + endpoints.subscriptionPackages + `?version=${packageInfo.version}`),
  createSubscription: async (data) =>
    await axiosInstance.post(baseURL + endpoints.createSubscription, { ...data, version: packageInfo.version }),
  cancelUserSubscription: (data) =>
    axiosInstance.put(baseURL + endpoints.cancelSubscription, { ...data, version: packageInfo.version }),
  payMembership: (membership) =>
    axiosInstance.post(baseURL + endpoints.payMembership, { ...membership, version: packageInfo.version }),
  sendGiftVoucher: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.sendGiftVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  resendGiftVoucher: async (data) =>
    await axiosInstance
      .put(baseURL + endpoints.resendGiftVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  updateGiftVoucher: async (data) =>
    await axiosInstance
      .put(baseURL + endpoints.updateGiftVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  redeemGiftVoucher: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.redeemGiftVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  getSentGiftVouchers: () =>
    axiosInstance.get(baseURL + endpoints.getSentGiftVouchers + `?version=${packageInfo.version}`).then((res) => res.data),
  getNews: () => axiosInstance.get(baseURL + endpoints.getNews + `?version=${packageInfo.version}`).then((res) => res.data),
  getNutritonData: async () =>
    await axiosInstance.get(baseURL + endpoints.nutritionData + `?version=${packageInfo.version}`).then((res) => res.data.data),
  sendSMS: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.sendSMS, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  verifyCode: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.verifyCode, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  deleteUser: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.deleteUser, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  joinTable: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.joinTable, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  getMyTable: async () => await axiosInstance.get(baseURL + endpoints.getMyTable + `?version=${packageInfo.version}`),
  getTableAppliedVouchers: async (data) =>
    await axiosInstance.post(baseURL + endpoints.getTableAppliedVouchers, { ...data, version: packageInfo.version }),
  leaveTable: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.leaveTable, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  getMycheckUrl: async () =>
    await axiosInstance.put(baseURL + endpoints.getMycheckUrl, { version: packageInfo.version }).then((res) => res.data),
  acceptUser: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.acceptUser, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  dismissUser: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.dismissUser, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  applyVoucher: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.applyVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  removeVoucher: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.removeVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  validateVoucher: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.validateVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  redeemFeedbackCode: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.redeemFeedbackCode, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  getAccessToken: () =>
    axiosInstance.get(baseURL + endpoints.getAccessToken + `?version=${packageInfo.version}`).then((res) => res.data),
  deleteUserCard: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.deleteUserCard, { ...data, version: packageInfo.version })
      .then((res) => res.data),
  globalpayOrderPayment: async (data) => {
    return await axiosInstance
      .post(baseURL + endpoints.globalpayOrderPayment, { ...data, version: packageInfo.version })
      .then((res) => res.data)
  },
  authenticationResultView3ds: async (data) => {
    return await axiosInstance
      .post(baseURL + endpoints.authenticationResultView3ds, { ...data, version: packageInfo.version })
      .then((res) => res.data)
  },
  checkOrder: async (data) => {
    return await axiosInstance
      .post(baseURL + endpoints.checkOrder, { ...data, version: packageInfo.version })
      .then((res) => res.data)
  },
  releaseVoucher: async (data) => {
    return await axiosInstance
      .post(baseURL + endpoints.releaseVoucher, { ...data, version: packageInfo.version })
      .then((res) => res.data)
  }
};

api.createAxiosInstance = createAxiosInstance;

export default { ...api };
