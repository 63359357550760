import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import { getFaq } from '../../store/actions';
import Loading from '../../components/spinner';
import './index.css';
import { Title } from '../../components/common';

class Faq extends Component {
  componentDidMount() {
    this.props.dispatch(getFaq());
  }

  render() {
    const { __, faq } = this.props;
    return (
      <Loading>
        <Layout
          showHeader
          showFooter
          color="transparent"
          headerTitle={__('Faq & Support')}
          hideBack={true}
          hideSecondToolbar={true}
        >
          <div className="inner-padding">
            <div dangerouslySetInnerHTML={{ __html: faq }}></div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    faq: state.common.faq || null,
  };
};

export default connect(stateToProps)(withTranslation(Faq));
