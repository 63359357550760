import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import HistoryTab from './historyTab';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import {
  applyVoucher,
  getMyTable,
  getTransactionHistory,
  getVouchers,
  validateVoucher,
} from '../../store/actions';
import SwipableTabs from '../../components/swipeableTabs';
import { getConfig } from '../../appConfig';
import './index.css';

import { IonAlert } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import { SET_APPLIED_VOUCHERS_MODAL } from '../../store/constants';

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCodeModalOpen: false,
      selectedVoucher: false,
    };
  }

  componentDidMount() {
    // this.props.dispatch(getMyTable(false,true));

    this.getTransactionHistory();
    this.props.dispatch(getVouchers());
  }

  getTransactionHistory = () => {
    this.props.dispatch(getTransactionHistory());
  };

  getNameOfLabelFirstTab = () => {
    const { hasOrdering } = getConfig().appType;
    const { __, transactionHistory } = this.props;

    const firstTabListAdditional = transactionHistory.map((item) => ({
      ...item,
      label:
        item.business_location_id + '' === '-1'
          ? __('Referral Bonus')
          : item.business_location_id + '' === '-2'
            ? __('Sign up Bonus')
            : item.business_location_id + '' === '-3'
              ? __('Refunded points')
              : hasOrdering
                ? item.stamp_power < 0
                  ? __('Redeemed')
                  : __('Earned')
                : transactionHistory
                  .filter((i) => i.stamp_power >= 0)
                  .map((item) => ({ ...item, label: 'Earned' })),
    }));

    return firstTabListAdditional;
  };
  redeemVoucher = (selectedVoucher) => {
    const { tableData } = this.props;
    if (this.props.tableData && this.props.tableData.id) {
      this.props.dispatch(applyVoucher({ code: selectedVoucher.VoucherCode, id: tableData?.id }));
      forwardTo('/table', { selectedVoucher });
    } else {
      this.setState({ voucherCodeModalOpen: true, selectedVoucher });
    }
  };
  redeemVoucherCode = () => {
    const { voucherCode } = this.state;
    this.props.dispatch(validateVoucher({ code: voucherCode, id: this.props.tableData?.id }));
  };
  onVoucherCodeHandler = (code) => {
    this.setState({ voucherCode: code });
  };
  render() {
    const { __ } = this.props;
    return (
      <Layout
        showHeader
        showFooter
        headerWithTitle={true}
        headerTitle={__('Your History')}
        hideSecondToolbar={true}
        noPadding={true}
        color="transparent"
        scrollY={false}
      >
        <SwipableTabs
          defaultTab={this?.props?.location?.state?.tab == 'history' ? 1 : 0}
          history={this.props.location}
          tabs={[
            {
              label: __('Rewards'),
              tabContent: (
                <HistoryTab
                  type={'rewards'}
                  transactionHistory={this.props.vouchers || []}
                  getTransactionHistory={this.getTransactionHistory}
                  restaurants={this.props.restaurants}
                  dispatch={this.props.dispatch}
                  onVoucherRedeem={this.redeemVoucher}
                  tableData={this.props.tableData}
                  onVoucherRedeemCode={this.redeemVoucherCode}
                  onVoucherCodeHandler={this.onVoucherCodeHandler}
                  voucherCodeValue={this.state.voucherCode}
                />
              ),
            },
            {
              label: __('History'),
              tabContent: (
                <HistoryTab
                  type={'history'}
                  transactionHistory={this.getNameOfLabelFirstTab()}
                  getTransactionHistory={this.getTransactionHistory}
                  restaurants={this.props.restaurants}
                  dispatch={this.props.dispatch}
                  onVoucherRedeem={this.redeemVoucher}
                  onVoucherRedeemCode={this.redeemVoucherCode}
                  tableData={this.props.tableData}
                  onVoucherCodeHandler={this.onVoucherCodeHandler}
                  voucherCodeValue={this.state.voucherCode}
                />
              ),
            },
          ]}
        />
        <IonAlert
          isOpen={this.state.voucherCodeModalOpen}
          onDidDismiss={() =>
            this.setState({ voucherCodeModalOpen: false, selectedVoucher: false })
          }
          header={__('Takeaway?')}
          message={__('Show this code to redeem your reward.')}
          cssClass="no-table-alert two-button-alert"
          color="secondary"
          inputs={[
            {
              value: this.state.selectedVoucher.VoucherCode,
            },
          ]}
          buttons={[
            {
              cssClass: 'two-button-secondary',
              text: 'To eat, check in!',
              role: 'confirm',
              handler: () => forwardTo('/check-table'),
            },
            {
              cssClass: 'two-button-gray',
              text: 'Close',
            },
          ]}
        />
        <IonAlert
          isOpen={this.props.appliedVoucherModalOpen}
          onDidDismiss={() =>
            this.props.dispatch({ type: SET_APPLIED_VOUCHERS_MODAL, value: false })
          }
          header={__('All done!')}
          message={__('Your reward has been added to the table.')}
          cssClass="two-button-alert"
          buttons={[
            {
              cssClass: 'two-button-secondary',
              text: 'Add another',
              role: 'confirm',
              handler: () =>
                this.props.dispatch({ type: SET_APPLIED_VOUCHERS_MODAL, value: false }),
            },
            {
              cssClass: 'two-button-gray',
              text: 'Back to table',
              role: 'cancel',
              handler: () => forwardTo('/table'),
            },
          ]}
        />
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { tableData, appliedVoucherModalOpen } = state.orders || {};
  return {
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
    restaurants: state.restaurants.restaurants || [],
    vouchers: state.profile.vouchers || [],
    tableData,
    appliedVoucherModalOpen,
  };
};

export default withRouter(withTranslation(connect(stateToProps)(History)));
