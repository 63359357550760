/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { IonButton, IonItem, IonInput, IonCol, IonRow, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
// import { close } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, goBack, validateForm } from '../../lib/utils';
import { sendVoucherCode, checkDiscount, showToast, getVouchers, loading } from '../../store/actions';
import Layout from '../../components/layout';
import {
  Title,
  Spacer,
  FieldError /*, NormalText, SmallText*/,
  NormalText,
  Subtitle,
  SmallText,
} from '../../components/common';
import plateImage from '../../assets/images/icons/eat.png';

import Basket from '../../lib/basket';
import Modal from '../../components/modal';
import { getConfig } from '../../appConfig';
import './index.css';
import moment from 'moment';
import { chevronBack } from 'ionicons/icons';

class ApplyVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: null,
      selectedVoucher: null,
      showModal: null,
      voucherCode: '',
      disabledAppliedButton: false,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getVouchers())
  }

  setVoucher = (voucher) => {
    const { dispatch, __ } = this.props;
    this.props.dispatch(loading(true))
    this.setState({ selectedVoucher: voucher }, () => {

      const { vouchers, applicableVouchers } = this.props;
      const { selectedVoucher } = this.state;
      const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      if (appliedVoucher) {
        const voucherDataFromBasket = Basket.parseBasketForCheckVouchers();
        // if (Basket.pointsApplied > 0) {
        //   Basket.clearAllDiscounts(
        //     true,
        //     false,
        //     'Applying a voucher to this order will remove any applied coins',
        //   );
        // }
        dispatch(checkDiscount({ ...voucherDataFromBasket, selectedVoucher }));
      } else {
        dispatch(showToast(__('Voucher is not available.'), 'warning'));
      }
      this.props.dispatch(loading(false))

    });
  };

  addVoucher = () => {
    const { selectedVoucher } = this.state;
    const { vouchers, applicableVouchers, dispatch, __ } = this.props;
    const appliedVoucher = vouchers.find((voucher) => voucher.VoucherID === selectedVoucher.VoucherID);
    const applicableVoucher = applicableVouchers.find(
      (appliableVoucher) => appliableVoucher.VoucherID === selectedVoucher.VoucherID,
    );
    if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
      this.setState({ selectedVoucher: null }, () => {
        Basket.addVoucher(appliedVoucher, applicableVoucher);
        goBack();
      });
    } else {
      dispatch(
        showToast(
          __('Your basket is higher then voucher cost. Please add more items in basket'),
          'warning',
        ),
      );
    }
  };

  showModal = (show) => {
    this.setState({ showModal: show });
    this.clearFields();
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data, true));
      this.showModal(false);
    }
  };

  filterVouchers = (vouchers, appliedVouchers) => {
    const { applicableVouchers, availableBalance } = this.props;
    let filteredVouchers = [];
    if (applicableVouchers && applicableVouchers.length > 0) {
      applicableVouchers.forEach((discount) => {
        let findedVoucher = vouchers.find((voucher) => voucher.id === discount.id);
        if (getConfig().vouchersType === 2) {
          if (findedVoucher && availableBalance > findedVoucher.cost) {
            filteredVouchers.push(findedVoucher);
          }
        } else {
          if (findedVoucher) {
            filteredVouchers.push(findedVoucher);
          }
        }
      });
    }
    if (appliedVouchers && appliedVouchers.length > 0) {
      const ids = appliedVouchers.map((i) => i.id);
      return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
    }
    return filteredVouchers;
  };
  render() {
    const { __, vouchers } = this.props;
    const { selectedVoucher, voucherCode, showModal, disabledAppliedButton } = this.state;
    const appliedVouchers = Basket ? Basket.applied_vouchers : [];
    const filteredVouchers = vouchers.filter(voucher => !voucher.Redeemed)
    return (
      <Loading>
      <Layout showBack backHandler={goBack} showHeader>
        <div className='page-secondary-header' style={{ flex: 'unset' }}>
          <IonIcon onClick={() => goBack()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>
          <Title className="secondary-font dark-color">{__('Rewards')}</Title>
        </div>
        <div className="absolute-content scrollable-y">
          {/* {getConfig().vouchersType === 2 ? null : (
                <div className="flex-min">
                  <IonButton expand="block" color="secondary" onClick={() => this.showModal(true)}>
                    {__('Voucher code')}
                  </IonButton>
                </div>
              )}
              <Spacer /> */}
          <div className="input-field-container">
            <NormalText>{__('Enter Reward Code')}</NormalText>
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                className='uppercase redeem-code-input'
                value={voucherCode}
                onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                placeholder={__('Reward code')}
              />
            </IonItem>
          </div>
          <Spacer size={1} />
          <IonButton style={{ width: '100%' }} expand='block' onClick={() => this.sendCode()} color="secondary">
            {__('Submit')}
          </IonButton>
          <Spacer />
          <NormalText className="bold-text uppercase secondary-color">{__('Your Rewards')}</NormalText>
          <div className="scrollable-y vaucher-list" style={{ marginTop: '10px' }}>
            {filteredVouchers.length === 0 ? (
              <NormalText>{__(`You don't have any applicable vouchers in your wallet`)}</NormalText>
            ) : (
              <></>
            )}
            {filteredVouchers.length > 0 &&
              filteredVouchers.map((voucher, index) => {
                return (
                  <IonRow>
                    <IonCol className='description-col' size="9">
                      <NormalText className="uppercase">{voucher.Description}</NormalText>
                      <SmallText className="uppercase block" color="gray">
                        {__('Received:')} {moment(voucher.URD).format('DD/MM/YYYY')}
                      </SmallText>
                      <SmallText className="uppercase block" color="gray">
                        {__('Expires:')} {moment(voucher.ExpiryDate).format('DD/MM/YYYY')}
                      </SmallText>
                    </IonCol>
                    <IonCol
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                      size="3"
                    >
                      <IonButton
                        onClick={() => this.setVoucher(voucher)}
                        className="rounded-btn"
                        color="secondary"
                      >
                        {__('Redeem')}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                );
              })}
          </div>
          {/* {filteredVouchers.length > 0 && (
            <div className="flex-min">
              <IonButton
                color="secondary"
                disabled={selectedVoucher ? false : true}
                onClick={() => {
                  this.addVoucher();
                }}
                className={disabledAppliedButton ? 'box-button-color-tertiary' : 'box-button-color'}
                size="full"
                shape="round"
              >
                {__('Apply Vouchers')}
              </IonButton>
            </div>
          )} */}
        </div>
        <Modal
          className="apply-voucher-modal"
          isOpen={!!showModal}
          onDidDismiss={() => this.setState({ showModal: null })}
          title={__('Enter code')}
        >
          <div className="flex-row-wrapper">
            <div className="centered flex-row-wrapper">
              <div className=" centered scrollable-y">
                <NormalText>
                  {__('Enter a valid voucher code below to receive your reward')}
                </NormalText>
                <div className="lefted padded voucher-item">
                  <IonItem style={{ '--min-height': '32px' }}>
                    <IonInput
                      value={voucherCode}
                      onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                      placeholder={__('Voucher code')}
                    />
                  </IonItem>
                  <FieldError
                    className="field-error"
                    value={__(this.state.formErrors.voucherCode)}
                  />
                </div>
              </div>
            </div>
            <div className="flex-min">
              <IonButton onClick={() => this.sendCode()} expand="full" color="secondary">
                {__('Submit')}
              </IonButton>
            </div>
          </div>
        </Modal>
      </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
    vouchers: store.profile.vouchers || null,
    applicableVouchers: store.profile.applicableVouchers || null,
  };
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
