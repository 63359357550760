import React from 'react';
import { connect } from 'react-redux';
import { IonItem, IonButton, IonInput, IonAlert, IonList } from '@ionic/react';
import Layout from '../../components/layout';
import { FieldError, Title, Spacer, NormalText } from '../../components/common';
import { resetPassword, setModal } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { forwardTo, validateForm } from '../../lib/utils';
import SplitedInput from '../../components/splitedInput';
import { SET_COMMON_PROP } from '../../store/constants';
import Loading from '../../components/spinner';
import './index.css';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      code: '',
      password: '',
      repeat_password: null,
      formErrors: {},
      registerErrorAlertOpen: false
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.formConfig = {
      phone: { type: 'tel', required: true },
    };
    this.fullFormConfig = {
      phone: { type: 'tel', required: true },
      password: { type: 'password', required: true },
      repeat_password: { type: 'repeat_password', required: true },
    };
  }

  handleResetPasswordPhone() {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { phone } = this.state;
      this.props.dispatch(resetPassword({ phone }));
    } else {
      this.setState({ registerErrorAlertOpen: true });
    }
  }

  handleResetPasswordCode() {
    const { phone, code } = this.state;
    if (code !== '') {
      this.props.dispatch(resetPassword({ phone, code }));
    }
  }

  handleResetPassword() {
    let formErrors = validateForm(this.fullFormConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { phone, code, password } = this.state;
      this.props.dispatch(resetPassword({ phone, code, password }));
    } else {
      this.setState({ registerErrorAlertOpen: true });
    }
  }

  handleInput = (key, val) => this.setState({ [key]: val });

  returnToLogin = (history) => history.goBack();

  cancelResetPassword = () => {
    this.props.dispatch({ type: SET_COMMON_PROP, key: 'isResetPasswordPhoneValid', value: false });
    this.props.dispatch({ type: SET_COMMON_PROP, key: 'isResetPasswordCodeValid', value: false });
    forwardTo('/dashboard');
  }

  loadByState = () => {
    const { __, isResetPasswordPhoneValid, isResetPasswordCodeValid } = this.props;
    const { phone, code, password, repeat_password, formErrors } = this.state;
    if (isResetPasswordPhoneValid && isResetPasswordCodeValid) {
      return (
        <>
          <NormalText className="block centered">
            {__(
              'Enter your new password:',
            )}
          </NormalText>
          <Spacer size={2} />
          <div className="input-field-container">
            <NormalText>{__('Password *')}</NormalText>
            <IonItem
              lines="none"
              className={`input-field-wrapper ${formErrors.password ? 'invalid' : ''}`}
            >
              <IonInput
                onIonChange={(e) => {
                  this.handleInput('password', e.target.value);
                }}
                onIonBlur={(e) => this.handleInput('password', e.target.value)}
                clearInput
                required={true}
                type="password"
                pattern="password"
                inputmode="password"
                value={password}
                autocomplete="on"
              ></IonInput>
            </IonItem>
          </div>
          <Spacer size={1} />
          <div className="input-field-container">
            <NormalText>{__('Repeat Password *')}</NormalText>
            <IonItem
              lines="none"
              className={`input-field-wrapper ${formErrors.repeat_password ? 'invalid' : ''
                }`}
            >
              <IonInput
                onIonChange={(e) => {
                  this.handleInput('repeat_password', e.target.value);
                }}
                onIonBlur={(e) => this.handleInput('repeat_password', e.target.value)}
                clearInput
                required={true}
                type="password"
                pattern="password"
                inputmode="password"
                value={repeat_password}
                autocomplete="on"
              ></IonInput>
            </IonItem>
          </div>
          <Spacer />
          <IonButton
            onClick={() => {
              this.handleResetPassword();
            }}
            color="secondary"
            expand="block"
          >
            {__('Confirm')}
          </IonButton>
        </>
      );
    } else if (isResetPasswordPhoneValid) {
      return (
        <>
          <NormalText className="block centered">
            {__(
              'We have sent you a 6 digit code to your SMS. Please type this in to confirm your telephone number',
            )}
          </NormalText>
          <Spacer size={2} />
          <div className="code-wrapper">
            <SplitedInput
              predefinedCode={code}
              format="IIIIII"
              text={code}
              valueHandler={(code) => this.setState({ code })}
              inputProps={{
                inputmode: 'tel',
              }}
            />
          </div>
          <Spacer />
          <IonButton
            onClick={() => {
              this.handleResetPasswordCode();
            }}
            color="secondary"
            expand="block"
          >
            {__('Confirm')}
          </IonButton>
        </>
      );
    }
    return (
      <>
        <NormalText color="primary" className="block centered uppercase">
          {__('Please put in your phone number to reset your password  ')}
        </NormalText>
        <Spacer size={2} />
        <IonList>
          <div className="input-field-container">
            <NormalText>{__('Telephone number')}</NormalText>
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                onIonChange={(e) => this.handleInput('phone', e.target.value)}
                clearInput
                required={true}
                type="tel"
                pattern="tel"
                inputmode="tel"
                value={phone}
              />
            </IonItem>
          </div>
          {formErrors.phone ? (
            <FieldError className="field-error" value={__(formErrors.phone)} />
          ) : null}
        </IonList>
        <Spacer size={1} />
        <IonButton
          expand="block"
          color="secondary"
          onClick={() => this.handleResetPasswordPhone()}
        >
          {__('Reset Your Password')}
        </IonButton>
      </>
    );
  }

  render() {
    const { __, isResetPasswordModalOpen } = this.props;
    const { phone, formErrors, registerErrorAlertOpen } = this.state;
    return (
      <Loading transparent>
        <Layout headerTitle={__('Reset Password')} hideSecondToolbar={true} color="transparent">
          <div className="flex-row-wrapper absolute-content reset-password-background">
            <div className="scrollable-y">
              <Title className="centered">{__('Forgot password')}</Title>
              {
                this.loadByState()
              }
              <IonButton
                onClick={() => this.cancelResetPassword()}
                class="link underlined"
                expand="block"
              >
                {__('Cancel')}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={isResetPasswordModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isResetPasswordModalOpen', false))}
          header={__('Success')}
          message={__('Reset processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isResetPasswordModalOpen', false))),
            },
          ]}
        />
        <IonAlert
          isOpen={registerErrorAlertOpen}
          onDidDismiss={() => this.setState({ registerErrorAlertOpen: false })}
          header={__('Oh, no!')}
          message={__(`${formErrors[Object.keys(formErrors)[0]]}`)}
          cssClass="no-table-alert one-red-button-alert"
          buttons={[
            {
              text: __('OK'),
              cssClass: 'two-button-secondary',
              handler: () => this.setState({ registerErrorAlertOpen: false }),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isResetPasswordModalOpen } = state.profile;
  const { isResetPasswordPhoneValid, isResetPasswordCodeValid } = state.common;
  return {
    auth,
    isResetPasswordModalOpen,
    isResetPasswordPhoneValid,
    isResetPasswordCodeValid
  };
};

export default connect(stateToProps)(withTranslation(ResetPassword));
