import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QRCode from 'qrcode-react';
import Basket from '../../lib/basket';
import moment from 'moment';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import {
  getFaq,
  getProfile,
  getRewards,
  getTransactionHistory,
  getVouchers,
  sendVoucherCode,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, isWebConfig, validateForm } from '../../lib/utils';
import UnlockRewardItems from '../../components/unlockRewardItems';

import {
  NormalText,
  SmallText,
  StrongText,
  Subtitle,
  BigLabel,
  Title,
  FieldError,
  Spacer,
} from '../../components/common';
import { IonButton, IonCol, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react';
import { getConfig } from '../../appConfig';
import ApplyVouchers from '../applyVouchers';
import Modal from '../../components/modal';
import { informationCircle, informationCircleOutline } from 'ionicons/icons';
import RedeemGiftVoucher from '../../components/redeemGiftVoucher';

const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div
      style={{ backgroundImage: `url(${reward.image})` }}
      className={'voucher' + (className ? ' ' + className : '')}
      onClick={action ? () => action(id) : null}
    ></div>
  );
};
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyType: '',
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
      loyaltyFaqModalOpened: false,
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    this.props.dispatch(getFaq());

    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const loyaltyType = getConfig().appType.hasUnlockRewardsOnLoyalty ? 'rewards' : 'points';
    this.setState({ loyaltyType });
    if (this.props.location && this.props.location.state && this.props.location.state.tab) {
      this.setState({ page: this.props.location.state.tab });
    }
  }
  getLabel(item) {
    let label = '';
    if (`${item.business_location_id}` == '-1') {
      label = 'Referral Bonus';
    } else if (`${item.business_location_id}` == '-2') {
      label = 'Sign up Bonus';
    } else if (`${item.business_location_id}` == '-3') {
      label = 'Refunded points';
    } else if (item.stamp_power < 0) {
      label = 'Redeemed Points';
    } else if (item.stamp_power > 0) {
      label = 'Earned Points';
    }
    return label;
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  drawLoyaltyContent(profile, rewards, __, qr_code) {
    const { loyaltyType } = this.state;
    if (loyaltyType == 'points') {
      return (
        <div className="loyalty-content">
          <Subtitle className="bold">{__('Loyalty Stamp Card')}</Subtitle>
          <div className="content-box box-wrapper small-padding loyalty-content-wrapper">
            <Subtitle className="bold">{__('Loyalty Balance')}</Subtitle>
            <div style={{ textAlign: 'end' }}>
              <Title className="no-margin lowercase points-balance-value">
                {profile.available_balance}
              </Title>
              <SmallText className="lowercase">{__('Points')}</SmallText>
            </div>
          </div>
          <div className="loyalty-content-desc">
            <NormalText className="block small-line-height">
              {__('Collect points every time you scan.')}
            </NormalText>
            <NormalText className="block small-line-height block">
              {__('Earn')}{' '}
              <b>
                {getConfig().api_config.points_value_percentage} {__('points')}
              </b>{' '}
              {__('for every')} {Basket.getCurrency().label} {__('spend')}.
            </NormalText>
          </div>
        </div>
      );
    } else if (loyaltyType === 'rewards') {
      return (
        <div className="loyalty-content">
          <Subtitle className="bold">{__('Available rewards')}</Subtitle>
          <div className="loyalty-rewards-wrapper">
            <UnlockRewardItems
              available_balance={profile.available_balance}
              rewards={rewards}
              qr_code={qr_code}
              __={__}
            />
          </div>

          {/* <div className='loyalty-rewards-wrapper'>
            {rewards.map(item => {
              return (
                <div className='loyalty-reward' style={{backgroundImage:`url(${item.reward.image})`}}>
                  {item.reward.cost>0 && <div className='loyalty-reward-points'><StrongText>{item.reward.cost} Points</StrongText></div>}
                  <div  className='loyalty-reward-name'><StrongText>{item.reward.name}</StrongText></div>
                </div>
              )
            })}
            </div> */}
        </div>
      );
    }
  }

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data, false));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };
  onRefreshHandler = (e) => {
    setTimeout(() => {
      this.props.dispatch(getProfile());
      this.props.dispatch(getTransactionHistory());
      this.props.dispatch(getRewards());
      this.props.dispatch(getVouchers());

      e.target.complete();
    }, 2000);
  };
  render() {
    const {
      __,
      rewards,
      profile,
      screenName,
      transactionHistory,
      vouchers,
      qr_code,
      faq,
    } = this.props;

    const { voucherCode, selectedVoucher, loyaltyFaqModalOpened } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    return (
      <Loading transparent>
        <Layout
          onRefreshHandler={this.onRefreshHandler}
          withRefresher={true}
          color="transparent"
          hideSecondToolbar={true}
          headerTitle={__('Loyalty and Rewards')}
          noPadding={true}
          pageWithTitle={true}
          headerWithTitle={true}
        >
          <div className="scrollable-y">
            {/* <ApplyVouchers></> */}

            {/* <Title className='page-title'>{__('Loyalty')}</Title> */}
            <div className="loyalty-header">
              <div className="loyalty-header-name">
                <Subtitle className="bold loyalty-name" color="white">
                  {screenName}
                </Subtitle>
                <Title className="bold user-consumer-id">{profile.consumer_id}</Title>
              </div>
              <div className="loyalty-header-content">
                <div>
                  <Title className="loyalty-points lowercase">
                    {profile.available_balance.toLocaleString()} {__('pts')}{' '}
                    <IonIcon
                      onClick={() => {
                        this.setState({ loyaltyFaqModalOpened: true });
                      }}
                      icon={informationCircleOutline}
                    />
                  </Title>
                  {Basket.calculatePointsAppliedPrice(profile.available_balance) > 0 && (
                    <NormalText>
                      {`${__('worth')} ${Basket.formatPrice(
                        Basket.calculatePointsAppliedPrice(profile.available_balance),
                      )}`}
                    </NormalText>
                  )}
                  {/* <div>
                    <NormalText className="">{__('Member since')}</NormalText>
                    <NormalText className="uppercase ">
                      {moment(profile.created_at).format('MM/YY')}
                    </NormalText>
                  </div> */}
                </div>
                <div className="qr-code">
                  {isDefined(profile.qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={profile.qr_code} size={isWebConfig() ? 150 : 200} />
                    </div>
                  ) : (
                    <div>
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* {this.drawLoyaltyContent(profile, rewards, __, profile.qr_code)} */}
            {/* <div className="loyalty-redeem-voucher">
              <RedeemGiftVoucher __={__} />
            </div> */}
            <div className="loyalty-vouchers-wrapper">
              <div className="redeem-voucher-field-wrapper">
                <div className="input-field-container">
                  <Subtitle className="bold">{__('Enter Voucher Code')}</Subtitle>
                  <IonItem lines="none" className="input-field-wrapper">
                    <IonInput
                      placeholder={__('Voucher Code')}
                      onIonChange={(e) => this.handlerOfVoucherCode(e)}
                      value={voucherCode}
                    ></IonInput>
                  </IonItem>
                </div>
                <IonButton
                  disabled={voucherCode === ''}
                  color="secondary"
                  expand="block"
                  onClick={this.sendCode}
                >
                  {__('Submit')}
                </IonButton>
              </div>
              <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              <div>
                {voucherRes.length > 0 && (
                  <Subtitle className="bold">{__('Your Vouchers')}</Subtitle>
                )}
                {voucherRes &&
                  voucherRes.map((voucher, index) => {
                    return (
                      <VoucherItem
                        key={'vc-' + index}
                        {...voucher}
                        __={__}
                        action={this.viewVoucher}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="loyalty-history">
              {transactionHistory.length > 0 && (
                <Subtitle className="bold ">{__('Latest Transactions')}</Subtitle>
              )}
              <div className="transactions-wrapper box-wrapper">
                {transactionHistory.map((el) => {
                  return (
                    <div className="content-box small-padding ">
                      <IonRow>
                        <IonCol
                          size="8"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <SmallText>{el.location_name}</SmallText>
                          <Subtitle className="block bold">{__(this.getLabel(el))}</Subtitle>
                          <NormalText>
                            {Basket.getDate(el.transaction_date).format('DD MMM YYYY h:mm a')}
                          </NormalText>{' '}
                        </IonCol>
                        <IonCol
                          ize="4"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <div className="transaction-points-wrapper">
                            <Subtitle
                              className={
                                el.stamp_power < 0 ? 'bold danger-color' : 'bold secondary-color'
                              }
                            >
                              {el.stamp_power < 0 ? '-' : '+'}
                              {Math.abs(el.stamp_power)}
                            </Subtitle>
                            <SmallText>{__('points')}</SmallText>
                          </div>
                        </IonCol>
                      </IonRow>
                      {/* <IonRow>
                        <IonCol>
                          
                        </IonCol>
                        <IonCol style={{ textAlign: 'end' }}>
                          <NormalText >
                            {' '}
                            {Basket.getDate(el.transaction_date).format('DD MMM YYYY h:mm a')}
                          </NormalText>
                        </IonCol>
                      </IonRow> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Layout>
        <Modal
          className="loyalty-faq-modal"
          isOpen={loyaltyFaqModalOpened}
          onDidDismiss={() => {
            this.setState({ loyaltyFaqModalOpened: false });
          }}
        >
          <div className="lefted">
            <Title>{__('Faq & Support')}</Title>
            <div dangerouslySetInnerHTML={{ __html: faq }}></div>
          </div>{' '}
        </Modal>

        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => {
            this.setState({ selectedVoucher: null });
            this.props.history.replace({ state: {} });
          }}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div style={{ textAlign: 'center' }}>
              <Title>{__('Voucher Info')}</Title>
              <VoucherItem {...selectedVoucher} __={__} />
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <Title className="centered uppercase bold no-margin-bottom">
                      {selectedVoucher.reward.name}
                    </Title>
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                ) : null}
                <hr />
                {selectedVoucher.reward.expiry_date ? (
                  <div>
                    <StrongText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </StrongText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <>
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText className="block small-line-height">
                      {__(selectedVoucher.reward.description)}
                    </SmallText>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    profile,
    rewards: rewards || [],
    screenName,
    transactionHistory: history || [],
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
    faq: state.common.faq || null,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
