import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
  IonMenuToggle,
} from '@ionic/react';
import { scan, basket, arrowBack, chevronBack } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import { SmallText, StrongText, Subtitle, Title } from '../common';
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import menuWhite from '../../assets/images/menu-white.svg';
import menuDark from '../../assets/images/menu-dark.svg';

import './index.css';

const StaticHeader = ({
  __,
  auth,
  profile,
  history,
  backHandler,
  title,
  headerWithTitle,
  showHamburgerIcon,
  navConfig,
  hasLoyaltyPoints,
  showBack,
  disableHeader
  
}) => {
  const currentPath = history.location.pathname;
  const config = getConfig();
  const authPages = config.general.authRoutes.indexOf(currentPath) !== -1;
  const pagesWithBackButton = config.general.routesWithBackButton.indexOf(currentPath) !== -1;
  const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath, navConfig);
  const routesWithDarkBg = config.general.routesWithDarkBg.indexOf(currentPath) !== -1;
  return (
    <>
      {hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar className={`${headerWithTitle ? 'no-logo' : ''} ${disableHeader?"toolbar-disabled":""} primary-toolbar`}>
            {(showBack && !isWebConfig()) &&
                 <IonButtons slot="start">
                 <IonButton onClick={() => (backHandler ? backHandler() : goBack())} size={24}>
                   <Icon   icon={chevronBack} classname="icon" color='primary' />
                 </IonButton>
               </IonButtons>
            }
       

            <IonButtons
              style={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: 'absolute',
                width: '100%',
                zIndex: '-1',
              }}
              color="primary"
            >
              <IonButton
                className="image-button"
                onClick={() => (auth.loggedIn ? forwardTo('/homepage') : forwardTo('/dashboard'))}
              />
            </IonButtons>
            {Basket.itemsCount() > 0 &&
              <IonButtons slot="end">
                <IonButton className="basket-button" onClick={() => forwardTo('/order-summary')} size={24}>
                  <div>
                    <div className="basket-icon-wrapper">
                      <IonBadge slot="end" color="secondary" className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}>
                        {Basket.itemsCountAll()}
                      </IonBadge>
                      <Icon icon={basket} classname="icon" color='primary' />
                    </div>
                  </div>
                </IonButton>
              </IonButtons>
            }

          </IonToolbar>
          {/* <IonToolbar color="transparent" /> */}
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { navConfig } = store.common;
  const { profile, auth } = store.profile;
  return {
    basketUpdated: orders.basketUpdated,
    navConfig,
    profile,
    auth,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
