import React from 'react';
import { IonItem, IonInput, IonButton, IonAlert, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute, goBack } from '../../lib/utils';
import { loginRequest } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import { FieldError, Spacer, NormalText, Title } from '../../components/common';
import { validateForm } from '../../lib/utils';
import './index.css';
import { SET_COMMON_MODAL } from '../../store/constants';
import logoGif from '../../assets/images/plane.gif';
import { chevronBack } from 'ionicons/icons';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.formConfig = {
      password: { type: 'password', required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleLogin() {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { protectedReferrer } = this.props;
      let referrer;
      if (this.props?.location?.state?.fromGiftVoucher) {
        referrer = '/gift-vouchers';
      } else if (this.props?.location?.state?.backToOrder) {
        referrer = '/order';
      } else {
        referrer = protectedReferrer;
      }
      const { username, password } = this.state;
      this.props.dispatch(loginRequest({ username, password, referrer }));
    }
  }

  checkLoginStatus = () => {
    const { loggedIn } = this.props.auth;
    const { navConfig } = this.props;
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(navConfig);
      forwardTo(defaultRoute.path);
    }
  };

  componentDidUpdate() {
    this.checkLoginStatus();
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

  render() {
    const { __ } = this.props;
    const { username, password } = this.state;
    return (
      <Loading transparent>
        <Layout
          hideSecondToolbar={true}
          headerTitle={__('Log in')}
          hideBack={true}
          color="transparent"
          contentClassName="login-background"
          showHeader
          showBack
          backHandler={goBack}
        >
          <div className="absolute-content scrollable-y flex-row-wrapper">
          <div className='page-secondary-header' style={{ flex: 'unset' }}>
              <IonIcon onClick={() => goBack()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>

              <Title className="secondary-font dark-color">{__('Log in')}</Title>
            </div>
            {/* <img className="animated-header" src={logoGif} /> */}
            <div>
              <Spacer size={1} />
              <div className="input-field-container">
                <NormalText>{__('Email Or Phone No.')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    onIonChange={(e) => this.handleInput('username', e.target.value)}
                    onIonBlur={(e) => {
                      const usernameInput = document.querySelector(
                        'input[type="text"]:-webkit-autofill',
                      );
                      if (usernameInput) {
                        this.handleInput('username', usernameInput.value);
                      }
                      this.handleInput('username', e.target.value);
                    }}
                    clearInput
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={username}
                  />
                </IonItem>
              </div>
              <FieldError className="field-error" value={__(this.state.formErrors.username)} />
              <Spacer size={1} />
              <div className="input-field-container">
                <NormalText>{__('Password')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <PasswordInput
                    __={__}
                    onIonChange={(e) => this.handleInput('password', e.target.value)}
                    value={password}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        this.handleLogin()
                      }
                    }}
                  />
                </IonItem>
              </div>
              <FieldError className="field-error" value={__(this.state.formErrors.password)} />
              {/* <IonButton
                fill="clear"
                color="primary"
                className="no-spacing capitalized underlined transparent"
                onClick={() => forwardTo('/reset-password')}
              >
                {__('Forgot password')}
              </IonButton> */}
              <Spacer size={2} />
              <IonButton
                expand="block"
                color="secondary"
                onClick={() => this.handleLogin()}
              >
                {__('Log in')}
              </IonButton>
              <IonButton
                expand="block"
                color="secondary"
                className=" transparent"
                onClick={() => forwardTo('/register')}
              >
                {__('Not registered')}
              </IonButton>
              <IonButton
                onClick={() => forwardTo('/reset-password')}
                class="link underlined"
                expand="block"
              >
                {__('Missing password')}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.loginErrorModalOpen}
          onDidDismiss={() =>
            this.props.dispatch({
              type: SET_COMMON_MODAL,
              modal: 'loginErrorModalOpen',
              value: false,
            })
          }
          message={__("Sorry we could't log you in. please double check your password.")}
          cssClass="no-table-alert one-red-button-alert"
          buttons={[
            {
              text: __('OK'),
              cssClass: 'two-button-secondary',
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, protectedReferrer } = state.profile;
  const { navConfig, loginErrorModalOpen } = state.common;
  return {
    auth,
    protectedReferrer,
    navConfig,
    loginErrorModalOpen,
  };
};

export default connect(stateToProps)(withTranslation(Login));
