import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { IonButton, IonLabel, IonInput } from '@ionic/react';
import Layout from '../../components/layout';
import { NormalText, Spacer, Subtitle, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getDefaultRoute, isDefined, sortLocations } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import SwipableTabs from '../../components/swipeableTabs';
import mapIcon from '../../assets/images/icons/icon_mapper.svg';
import geoIcon from '../../assets/images/icons/icon_geolocation.svg';
import phoneIcon from '../../assets/images/icons/icon_phone.svg';
import trainIcon from '../../assets/images/icons/train.svg';
import { showToast } from '../../store/actions';
import Slider, { Slide } from '../../components/slider';

class Restaurants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant: null,
      pickTime: null,
      selectedTimeOption: null,
      error: '',
      showRestaurants: false,
      showTime: false,
      isLocationAllowed: false,
      orderPassed: false,
      continueButtonClicked: false,
      searchValue: '',
    };
  }

  componentDidMount() {
    const { restaurants, myLocation } = this.props;
    const filteredRestaurants = sortLocations(restaurants, myLocation) || [];
    this.setState({ selectedRestaurant: filteredRestaurants[0] });
  }

  backHandler = () => {
    if (this.state.showTime) {
      this.setState({ showTime: false });
    } else {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path);
    }
  };

  searchRestaurantsByName = (searchValue = '') => {
    const { restaurants, myLocation } = this.props;
    let filteredRestaurants = [];
    restaurants &&
      restaurants.forEach((restaurant) => {
        if (
          restaurant.name.toLowerCase().includes(`${searchValue.toLowerCase()}`) || 
          restaurant.postcode.toLowerCase().includes(`${searchValue.toLowerCase()}`)
        ) {
          filteredRestaurants.push(restaurant);
        }
      })
      const result = filteredRestaurants.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
    return sortLocations(result, myLocation);

  };
  formatDistance(distance) {
    const { __ } = this.props;
    if (isDefined(distance)) {
      return distance < 1
        ? `(${distance.toFixed(1)} ${__('MI')})`
        : `(${distance.toFixed(0)} ${__('MI')})`;
    } else {
      return '';
    }
  }
  openMap(restaurant) {
    const coords = encodeURI(restaurant?.position);
    const url = 'https://maps.google.com/?daddr=' + coords;
    return url;
  }

  goCitymapper(restaurant) {
    const coords = encodeURI(restaurant?.position);
    const address = encodeURI(restaurant?.address);
    const url =
      'https://citymapper.com/directions?endcoord=' +
      coords +
      '&endname=Franco%20Manca&endaddress=' +
      address;
    return url;
  }
  render() {
    const { __ } = this.props;
    const { selectedRestaurant, searchValue } = this.state;
    const stores = this.searchRestaurantsByName(searchValue);
    let search = '';
    return (
      <Loading transparent>
        <Layout
          showFooter={true}
          showHeader={true}
          //   headerWithTitle={true}
          backHandler={this.backHandler}
          //   headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          color="transparent"
        >
          <div className="flex-row-wrapper absolute-content">
            <SwipableTabs
              history={this.props.location}
              tabs={[
                {
                  label: __('My Local'),
                  tabContent: (
                    <div className="selected-restaurant-wrapper">
                      <Slider
                      
                        settings={{
                          navigation: true,
                          pagination: true,
                          onSwiper: (swiper) => this.setState({swiper})
                        }}
                      >
                        {this.state.swiper && <>
                        <Slide>
                          <img src={selectedRestaurant?.image_1} />
                        </Slide>
                        <Slide>
                          <img src={selectedRestaurant?.image_2} />
                        </Slide>
                        </>}
                      </Slider>
                      <Title>{selectedRestaurant?.name}</Title>
                      <Spacer size={1} />
                      <div className="restaurant-links-wrapper">
                        <a href={`tel:${selectedRestaurant?.telephone}`}>
                          <img src={phoneIcon} />
                        </a>
                        <a target="_blank" href={this.openMap(selectedRestaurant)}>
                          <img src={mapIcon} />
                        </a>
                        <a target="_blank" href={this.goCitymapper(selectedRestaurant)}>
                          <img src={geoIcon} />
                        </a>
                      </div>
                      <Spacer size={1} />
                      <Subtitle>{selectedRestaurant?.address}</Subtitle>
                      <Spacer size={1} />
                      <Subtitle
                        dangerouslySetInnerHTML={{ __html: selectedRestaurant?.opening_times }}
                      ></Subtitle>
                      <Spacer size={1} />

                      <div className="restaurant-station-wrapper">
                        <div>
                          <img src={trainIcon} />
                        </div>
                        <div>
                          <NormalText className="uppercase">{__('Closest Station:')}</NormalText>
                          <Subtitle>{selectedRestaurant?.closest_tube}</Subtitle>
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  label: __('All Pizzerias'),
                  tabContent: (
                    <div className="all-restaurants-wrapper">
                      <IonInput
                        className="uppercase input-field-wrapper search-input"
                        placeholder={__('enter postcode or location')}
                        value={searchValue}
                        onIonChange={(e) => (search = e.target.value)}
                      />
                      <IonButton
                        onClick={() => this.setState({ searchValue: search })}
                        expand="block"
                        color="secondary"
                        className=" small-button"
                      >
                        {__('Search')}
                      </IonButton>
                      {stores.map((restaurant) => (
                        <IonLabel
                          className="lefted"
                          onClick={() =>
                            this.setState(
                              { selectedRestaurant: restaurant },
                              forwardTo('/restaurants', { tab: 'rewards' }),
                            )
                          }
                        >
                          <NormalText className="block link-color restaurant-name">
                            {`${__(restaurant.name)} ${this.formatDistance(restaurant.distance)}`}
                          </NormalText>
                        </IonLabel>
                      ))}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isChooseMenuModalOpen, orderProductionMins, myLocation, navConfig } = state.common;
  const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants;
  const { deliveryOption } = state.orders;
  return {
    auth,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    profile: state.profile.profile,
    isShowTimePicker: isShowTimePicker,
    deliveryOption,
    orderProductionMins: orderProductionMins,
    myLocation: myLocation,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Restaurants));
