import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import './index.css';
import { NormalText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import { IonAlert, IonButton, IonCheckbox, IonRadio, IonToggle } from '@ionic/react';
import { updateProfile } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import { SET_MODAL } from '../../store/constants';
import TermsModal from '../../components/termsModal';
import { getConfig } from '../../appConfig';

class MarketingPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      push_notification: props.profile.push_notification,
      marketing_optin: props.profile.marketing_optin,
      showTerms: false,
    };
  }
  componentDidMount() {
    this.setState({
      push_notification: this.props.profile.push_notification,
      marketing_optin: this.props.profile.marketing_optin,
    });
  }
  updateProfile = () => {
    const profile = JSON.parse(JSON.stringify(this.props.profile));
    profile.push_notification = this.state.push_notification;
    profile.marketing_optin = this.state.marketing_optin;
    this.props.dispatch(updateProfile(profile));
  };
  render() {
    const { __, profile, isProfileUpdatedModalOpen } = this.props;
    const { marketing_optin, push_notification } = this.state;
    const privacyPolicyLink =
      getConfig().general.privacyPolicyLink ||
      'https://www.francomanca.co.uk/legal/#privacy-cookies';
    return (
      <Loading>
        <Layout showHeader showFooter hideSecondToolbar={true} color="transparent">
          <div className="absolute-content scrollable-y">
            <Subtitle color="secondary" className="regular-font uppercase secondary-color">
              {__('Marketing Preferences')}
            </Subtitle>
            <Spacer size={1} />
            <div className="comunication-preferences-block">
              <NormalText className="centered block">
                {__('Here you can change your communication preferences.')}
              </NormalText>
            </div>
            <Spacer size={2} />
            <NormalText className="centered">
              {__('I would like to receive news, offers and competitions from franco manca:')}
            </NormalText>
            <div className="toggle-item">
              <NormalText>{__('Via Email')}</NormalText>
              <IonToggle
                onIonChange={() => {
                  this.setState({ marketing_optin: !this.state.marketing_optin }, () =>
                    this.updateProfile(),
                  );
                }}
                checked={marketing_optin}
                color="primary"
              />
            </div>
            <div className="toggle-item">
              <NormalText>{__('Via sms or push notification')}</NormalText>
              <IonToggle
                onIonChange={() => {
                  this.setState({ push_notification: !this.state.push_notification }, () =>
                    this.updateProfile(),
                  );
                }}
                checked={push_notification}
                color="primary"
              />
              <NormalText>
                {__(
                  'We’ll still notify you of table joining activity & new rewards if you toggle to off',
                )}
              </NormalText>
            </div>
            <Spacer />
            <NormalText className="centered">
              {__('You can view our ')}
              <NormalText
                className="link link-color pointer"
                onClick={() => window.open(privacyPolicyLink, '_blank')}
              >
                {__('privacy policy here')}
              </NormalText>
            </NormalText>
            <Spacer />
            <Spacer />
            <div className="separator"></div>
            <NormalText
              onClick={() => forwardTo('/account', { toSettings: true })}
              className="pointer block righted uppercase"
            >
              {__('Back to settings')}
            </NormalText>
          </div>
        </Layout>
        <IonAlert
          cssClass="no-table-alert one-red-button-alert"
          isOpen={isProfileUpdatedModalOpen === true}
          header={__('Success')}
          onDidDismiss={() =>
            this.props.dispatch({
              type: SET_MODAL,
              modal: 'isProfileUpdatedModalOpen',
              value: false,
            })
          }
          message={__('Profile updated successfully')}
          buttons={[
            {
              text: __('OK'),
              cssClass: 'secondary',
              handler: () => this.props.dispatch({ type: SET_MODAL, modal: 'isProfileUpdatedModalOpen', value: false }),
            },
          ]}
        />
        <TermsModal
          isOpen={this.state.showTerms}
          onDidDismiss={() => this.setState({ showTerms: false })}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    profile: state.profile.profile || null,
    isProfileUpdatedModalOpen: state.profile.isProfileUpdatedModalOpen || false,
  };
};

export default connect(stateToProps)(withTranslation(MarketingPreferences));
