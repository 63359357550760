import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton, IonAlert } from '@ionic/react';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../common';
import { makeKey, isDefined, forwardTo, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { releaseVoucher, setModal, showToast } from '../../store/actions';
import Modal from '../../components/modal';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ServiceCharge } from '../../screens/service-charge';
import { getConfig } from '../../appConfig';
import Incrementer from '../incrementer';

class OrderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeModalOpen: false,
      showCharge: false,
      removeItemAlertOpen: false,
      itemToRemove: null
    };
  }
  handleServiceChargeModal = (flag) => {
    if (isWebConfig()) {
      this.setState({ serviceChargeModalOpen: flag, showCharge: !flag });
    } else {
      forwardTo('/service-charge');
    }
  };

  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices, quantity } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow
              style={{ paddingLeft: choice.bundleModifier ? '40px' : null }}
              className=""
              key={makeKey(
                choiceGroupIndex,
                choiceIndex,
                sku,
              )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
            >
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow">
                {choice.quantity} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance.calculateSubItemPrice(choice, quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, quantity)
                  : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };
  drawSubItemsText = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;
    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <div className="flex-justify-between flex-col-wrapper">
              <SmallText className='uppercase dark-color'>+ {Basket.getProductName(choice, profile)}
                {parseInt(choice.productPrice) > 0 &&
                  <span className='subchoice-price secondary-color'>+{Basket.formatPrice(choice.productPrice)}</span>
                }
              </SmallText>


            </div>

          );
        });
      });
    }
    return null;
  };
  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      showAddItems,
      dispatch,
      isRemoveVoucherModalOpen,
    } = this.props;
    const { serviceChargeModalOpen } = this.state;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const appliedVoucher = basketInstance.getApplicableVocuher();
    const orderType = basketInstance.getOrderType();
    let location =
      history && history.location && history.location.pathname === '/history-details'
        ? true
        : false;
    return (
      <>
        <IonGrid className={`${this.props.checkOrderLoading ? 'is-loading' : ""} box-wrapper order-content-wrapper  paddL`}>
          <div className="add-items-button-wrapper">
            {showAddItems ? (
              <IonButton
                size="small"
                color="secondary "
                className="add-items-btn rounded-btn "
                onClick={() => forwardTo('/order')}
              >
                {__('Add items')}
              </IonButton>
            ) : null}
          </div>
          <div className="basket-items-wrapper">
            {basketInstance.getItems().map((basketItem, basketItemIndex) => {
              const { quantity, item, instructions } = basketItem;
              let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
              if (image && image.indexOf('http://') !== -1) {
                image = image.replace(/http:\/\//g, 'https://');
              }
              return (
                <div className="box-content">
                  <div
                    className="order-item-image"
                    style={image && image !== '' ? { backgroundImage: `url(${image})` } : { backgroundSize: 'contain' }}
                  />
                  <div className="item-content-wrapper flex-justify-between flex-row-wrapper">
                    <div style={{ paddingRight: '50px' }}>
                      <NormalText className="block  dark-color">{Basket.getProductName(item, profile)}</NormalText>
                      {this.drawSubItemsText(basketItem, basketItemIndex)}
                      {basketItem.instructions !== '' && (
                        <>
                          <Spacer size={1} />
                          <SmallText className="block">{`${__('Instructions')}: ${basketItem.instructions}`} </SmallText>
                        </>
                      )}
                    </div>
                    <div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
                      <Incrementer
                        {...(Basket.getLimitedCount(basketItem) && { maxLimit: Basket.getLimitedCount(basketItem) })}
                        onUpdate={(val) => {
                          if (handleOrderItemClick && val !== 0) {
                            handleOrderItemClick(basketItem, basketItemIndex, val);
                          } else {
                            this.setState({ removeItemAlertOpen: true, itemToRemove: { basketItem, basketItemIndex, val } })
                          }
                        }}
                        onIncrease={(val,) => {
                          if (val == quantity && quantity == Basket.getLimitedCount(basketItem)) {
                            this.props.dispatch(showToast(__(getConfig().general.itemsLimit.limitAlertMessage), 'warning', __(getConfig().general.itemsLimit.limitAlertHeader)))
                          }
                        }}
                        quantity={quantity}
                        step={1}
                        allowNegative={false}
                      >
                        <NormalText className=" primary-color">{quantity}</NormalText>
                      </Incrementer>
                      <NormalText className='dark-color secondary-font'>
                        {basketInstance.calculateItemPriceByIndex(basketItemIndex, true) > 0 ? basketInstance._calculateItemPriceByIndex(basketItemIndex, true) : null}
                      </NormalText>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {basketInstance.service_percentage > 0 &&
            orderType === 'Table' &&
            getConfig().general.hasServiceCharge &&
            basketInstance.getServiceChargeValue() == null ? (
            <div key="service_charge" className="basket-item-wrapper pointer">
              <IonRow
                className="basket-item-service-charge-row service-charge-item"
                onClick={() => this.handleServiceChargeModal(true)}
              >
                <>
                  <IonCol className="paddLR grow ">
                    {__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                  </IonCol>
                  <IonCol className="righted paddLR self-center ">
                    {basketInstance.getServiceChargeValue() > 0
                      ? basketInstance.getFormatedServiceChargeValue()
                      : basketInstance.getServiceCharge(true)}{' '}
                  </IonCol>
                </>
              </IonRow>
            </div>
          ) : null}
          {orderType === 'Table' && basketInstance.getServiceChargeValue() > 0 ? (
            <div key="service_charge" className="basket-item-wrapper pointer">
              <IonRow
                className="basket-item-service-charge-row service-charge-item"
                onClick={() => this.handleServiceChargeModal(true)}
              >
                <>
                  <IonCol className="paddLR grow ">
                    {__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                  </IonCol>
                  <IonCol className="righted paddLR self-center ">
                    {basketInstance.getFormatedServiceChargeValue()}
                  </IonCol>
                </>
              </IonRow>
            </div>
          ) : null}
          {deliveryPriceUnformated > 0 ? (
            <IonRow className="">
              <IonCol className="paddLR grow text-start">{__('Delivery Charge')}</IonCol>
              <IonCol className="righted paddLR">
                {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
              </IonCol>
            </IonRow>
          ) : null}
          {(appliedVoucher.length || basketInstance.discount || basketInstance.applied_discounts.length > 0 || appliedPoints > 0) && (
            <IonRow
              className={appliedVoucher.length > 0 ? ' amount-values' : 'subtotal- amount-values'}
            >
              <IonCol className=" paddLR grow ">
                <NormalText className='secondary-font dark-color'>{__('Items total')}</NormalText>
              </IonCol>

              <IonCol className="righted paddLR">
                <NormalText className='secondary-font dark-color'>{basketInstance._getSubTotal()}</NormalText>


              </IonCol>
            </IonRow>
          )}
          {basketInstance.applied_discounts &&
            basketInstance.applied_discounts.map(discount => {
              return (
                <div key={discount.id}>
                  <IonRow
                    className="amount-values"

                  >
                    <IonCol className="paddLR grow ">
                      <NormalText className='secondary-font dark-color'>{__(discount.name)}</NormalText> </IonCol>
                    <IonCol className="righted paddLR">
                      <NormalText className='secondary-font dark-color'>{basketInstance.formatPrice(discount.cost / 100 * -1)}</NormalText>


                    </IonCol>
                  </IonRow>

                </div>
              )
            })
          }
          {appliedVoucher &&
            appliedVoucher.length > 0 &&
            appliedVoucher.map((voucher) => {

              return (
                <div key={voucher.id}>
                  <IonRow
                    className="amount-values"
                    onClick={() =>
                      location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                    }
                  >

                    <IonCol className="paddLR grow ">
                      <NormalText className='secondary-font dark-color'>{__(`${voucher.reward.name.charAt(0).toUpperCase()}${voucher.reward.name.substring(1)}`)}</NormalText> </IonCol>
                    <IonCol className="righted paddLR">
                      <NormalText className='secondary-font dark-color'>{basketInstance._calculateAppliedVocuhersPrice(true, null)}</NormalText>


                    </IonCol>
                  </IonRow>

                  <IonAlert
                    cssClass="two-button-alert"
                    isOpen={isRemoveVoucherModalOpen}
                    onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                    message={__('Would you like to remove your reward?')}
                    buttons={[
                      {
                        text: __('Yes'),
                        cssClass: 'two-button-secondary',
                        handler: () => dispatch(releaseVoucher(voucher))
                      },
                      {
                        text: __('CANCEL'),
                        cssClass: 'two-button-gray',
                      },
                    ]}
                  />
                
                </div>
              );
            })}

          {basketInstance.discount && (
            <IonRow className="">
              <IonCol className="paddLR grow">{basketInstance.discount.title}</IonCol>
              <IonCol className="righted paddLR">
                -{basketInstance.formatPrice(basketInstance.calculateMembershipDiscount())}
              </IonCol>
            </IonRow>
          )}

          <>
            {isDefined(appliedPoints) && appliedPoints > 0 ? (
              <IonRow
                className="total- "
                onClick={() => {
                  if (type === 'order' && history) {
                    history.push('/apply-loyalty');
                  }
                }}
              >
                <IonCol className="paddLR grow">
                  {appliedPoints}&nbsp;{__('Points Redeem')}
                </IonCol>
                <IonCol className="righted paddLR">
                  {basketInstance._calculatePointsAppliedPrice(null, true)}
                </IonCol>
              </IonRow>
            ) : null}
          </>

          <IonRow
            className={deliveryPriceUnformated > 0 ? 'amount-values' : 'total- amount-values'}
          >
            <IonCol className="paddLR text-start">
              <NormalText className="secondary-font dark-color ">{__('Total to pay')}</NormalText>
            </IonCol>
            <IonCol className="righted paddLR">
              <NormalText className="secondary-font dark-color ">
                {basketInstance._getTotal(true)}
              </NormalText>

            </IonCol>
          </IonRow>
        </IonGrid >
        {
          basketInstance.service_percentage === 0 &&
            orderType === 'Table' &&
            getConfig().general.hasServiceCharge &&
            basketInstance.getServiceChargeValue() === null ? (
            <div key="service_charge" className="basket-item-wrapper">
              <IonButton
                expand="block"
                fill="clear"
                color="primary"
                className="link underlined basket-item-service-charge-row"
                onClick={() => this.handleServiceChargeModal(true)}
              >
                {__('Add Service Charge')}
              </IonButton>
            </div>
          ) : null
        }
        < Modal
          cssClass="service-charge-modal"
          isOpen={serviceChargeModalOpen}
          onDidDismiss={() => this.handleServiceChargeModal(false)
          }
        >
          <ServiceCharge
            handleServiceChargeModal={this.handleServiceChargeModal}
            serviceChargeModalOpen={serviceChargeModalOpen}
          />
        </Modal >
        <IonAlert
          isOpen={this.state.removeItemAlertOpen}
          onDidDismiss={() => this.setState({ removeItemAlertOpen: false, itemToRemove: null })}
          message={__('Are you sure you want to remove this item?')}
          cssClass="no-table-alert two-button-alert"
          color="secondary"
          buttons={[
            {
              text: __('Yes'),
              cssClass: 'secondary',

              handler: () => { this.setState({ removeItemAlertOpen: false }); handleOrderItemClick(this.state.itemToRemove.basketItem, this.state.itemToRemove.basketItemIndex, this.state.itemToRemove.val) },
            },
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'two-button-gray',
            },

          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
    checkOrderLoading: store.common.checkOrderLoading

  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
