import React from 'react';
import { Title, NormalText, StrongText, Subtitle } from '../common';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import Modal from '../../components/modal';
import { IonIcon } from '@ionic/react';
import { pencil } from 'ionicons/icons';

const { getRestauranName, getOrderDate, getOrderTime, getTableNumber } = Basket;

const findSelectedSlot = (dayInWeek, time) => {
  const restaurant = Basket.getRestaurant();
  const selectedDay = restaurant.delivery_times_json.slots[dayInWeek];
  const selectedSlot = selectedDay.find(
    (day) =>
      moment(day.start_time, 'HH:mm').format('HH:mm') === moment(time, 'HH:mm a').format('HH:mm'),
  );
  return (
    moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
    ' - ' +
    moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
  );
};

const ContentHeader = ({
  __,
  deliveryOption,
  orderType,
  deliveryAddress,
  pickUpPoint,
  tableNumberHandler,
  orderSummary = false,
}) => {
  let dateName = moment(getOrderDate(), 'dddd Do MMM').format('ll');
  let dayInWeek = moment(getOrderDate(), 'dddd Do MMM').format('dddd');
  const deliveryLabel =
    deliveryOption &&
    (deliveryOption.label === 'Scheduled Delivery' || deliveryOption.label === 'Order for Delivery')
      ? 'Delivery Order'
      : deliveryOption.label;
  const locationCode = pickUpPoint && pickUpPoint.location_code ? pickUpPoint.location_code : '';
  const styledText = { flex: 1 };
  if (!orderType || orderType === '') {
    return null;
  }
  if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        <NormalText className="auto-grid">
          <span>
            {__('Ordering for')} {__('drop-off')} {__('at')}{' '}
            <strong>
              {getRestauranName()} {`(#${locationCode})`},{' '}
            </strong>
          </span>{' '}
          <span>
            {dateName} {__('at')} {getOrderTime()}
            <br />
          </span>
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'delivery') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryLabel)}</Title>
        <NormalText className=" thiner-text auto-grid">
          <span>
            {__('Ordering for')} {__('delivery')} {__('to')}{' '}
            <strong>{deliveryAddress.addressLine1}, </strong>
            {dateName}
          </span>{' '}
          {/* {deliveryAddress.place } {deliveryAddress.postalCode }  */}{' '}
          <span>
            {__('at')} {findSelectedSlot(dayInWeek, getOrderTime())}
            <br />
          </span>
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        {orderSummary ? (
          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Collection from')}</StrongText>
              <NormalText>{getRestauranName()}</NormalText>
            </div>
            <div
              onClick={() => {
                tableNumberHandler();
              }}
            >
              <StrongText> {getTableNumber()} </StrongText>
            </div>
          </div>
        ) : (
          <>
            <Subtitle>
              {__('Ordering for')} {getTableNumber()}
            </Subtitle>
            <NormalText>
              {__('from')} {getRestauranName()}
            </NormalText>
          </>
        )}

        {/* <NormalText >

        <span> {__('Ordering at')} {getRestauranName()}</span>  <b onClick={()=>{tableNumberHandler()}} style={{cursor:'pointer'}}>{__('for')}{' '}{getTableNumber() + '.'}</b>{' '}
         <span>{__('Your order will be brought to you as soon as possible.')}</span> 
        </NormalText> */}
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery') {
    const date = dateName + __(' at ') + getOrderTime('h:mm a');
    const dateRes = dateName !== 'Invalid date' ? date : 'as soon as possible.';
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        <NormalText /*className="flex-col-wrapper flex-row-holder"*/ className="">
          <span>
            {__('Ordering at')} {getRestauranName()} {__('for delivery')} {__('to')}{' '}
            <strong>
              {deliveryAddress.addressLine1}, {deliveryAddress.addressLine2},{' '}
            </strong>{' '}
            {deliveryAddress.place}, {deliveryAddress.postalCode}
          </span>
          <span>
            {' '}
            {dateRes}
            <br />
          </span>
          {/* { __('Ordering for')} { getOrderDate()} { __('at')} { getOrderTime()}<br /> */}
          {/* { ' ' + __('from')} { getRestauranName()} */}
          {/* <span style={{ marginBottom: 5 }} className="flex-col-wrapper"><strong>{ __('Collection Location') }</strong> <span className="righted thiner-text">{ getRestauranName() }</span></span>
					<span className="flex-col-wrapper"><strong>{ __('Collection Time') }</strong> <span style={ styledText } className="righted thiner-text">{ charterDeliveryDateAndTime !== 'Invalid date' ? `${getOrderDate('D MMMM YYYY')} ${__('at')}  ${getOrderTime('h:mm a')}` : `${Basket.collection_time}`}</span></span> */}
        </NormalText>
      </>
    );
  } else {
    const date = dateName + ' ' + __('at') + ' ' + getOrderTime('h:mm a');
    const dateRes = dateName !== 'Invalid date' ? date : 'as soon as possible';
    return (
      <>
        <Title className="order-intro-title">{__(orderType)}</Title>

        {orderSummary ? (
          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Collection from')}</StrongText>
              <NormalText>{getRestauranName()}</NormalText>
            </div>
            <div>
              <StrongText> {getOrderTime('h:mm a')}</StrongText>
            </div>
          </div>
        ) : (
          <>
            <NormalText className=' block dark-color lined-row' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{getRestauranName()}</span>
            <span style={{cursor:'pointer'}} onClick={()=>forwardTo('/click-and-collect',{ selectedRestaurantId: Basket.getRestaurant()?.id })}><IonIcon icon={pencil}/></span>
          </NormalText>
            <NormalText style={{display:'flex',justifyContent:'space-between'}} className=' dark-color'>
              <span>{__('Collection time')} </span>
              <span>{getOrderTime('h:mm a')}</span>
              
            </NormalText>
          </>
        )}

        {/* <NormalText className="flex-col-wrapper flex-row-holder ">

                  {__('Ordering for collection from')} {getRestauranName()}{' '}{'on'}{' '}{date}

        </NormalText> */}
      </>
    );
  }
};

export default ContentHeader;
